import requestHelpers from './request-helpers'

async function getAffiliates () {
  const request = {
    method: 'GET',
    url: '/affiliates?page_size=1000'
  }
  const { data } = await requestHelpers.makeRequest(request)

  let affiliates
  if (data) {
    affiliates = data.map((affiliate) => {
      let { name: affiliateName } = affiliate
      const { affiliate_id: affiliateID } = affiliate

      if (!affiliate.is_active) {
        affiliateName += ' (inactive)'
      }

      return {
        value: affiliateID,
        display: `${affiliateID} - ${affiliateName}`
      }
    })
  }
  return affiliates
}

export const api = {
  getAffiliates
}

export default api
