import React from 'react'
import './LoggedOut.scss'

const { REACT_APP_HOST } = process.env

const LoggedOut = () => (
  <div className="vp-loggedout__overlay">
    <div className="vp-loggedout__container">
      <div className="vp-loggedout__header">
        <img
          className="vp-loggedout__logo"
          alt="Vivial Logo - Logged Out"
          src="https://vivialplatform.net/img/Vivial_logo_rgb.png"
        />
        <p className="text-center vp-loggedout__title">Vivial Platform</p>
      </div>
      <div className="vp-loggedout__body">
        You have logged out of Platform Admin.
        {' '}
        <a className="vp-loggedout__link" href={REACT_APP_HOST}>
          Click here
        </a>
        {' '}
        to login again.
      </div>
    </div>
  </div>
)

export default LoggedOut
