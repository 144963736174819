/* eslint jsx-a11y/label-has-for: off */
import React from 'react'
import PropTypes from 'prop-types'

import snakeToTitleCase from 'helpers/utils'
import Button from '../../../common/Button'
import { Form, Input, Select } from '../../../common/Form'
import Loading from '../../../common/Loading'
import LinkButton from '../../../common/Link'
import formHelpers from '../../../../helpers/form-helpers'
import requestHelpers from '../../../../helpers/request-helpers'
import permissionHelper from '../../../../helpers/permissions-helpers'
import { createUser } from '../../shared/user-helpers'
import UserPlatformRoles from '../../shared/UserPlatformRoles'
import affiliatesHelper from '../../../../helpers/affiliates-request-helper'
import './UserCreateForm.scss'

const CLIENT_AFFILIATE_TOOLTIP = `Optional: use to override \
partner for users related to multiple accounts`
export { CLIENT_AFFILIATE_TOOLTIP }

class UserCreateForm extends React.Component {
  static getUserTypes () {
    if (permissionHelper.hasPerm({ roles: ['admin', 'user_admin'] })) {
      return [
        { value: 'CLIENT', display: 'CLIENT' },
        { value: 'INTERNAL', display: 'INTERNAL' },
        { value: 'AFFILIATE', display: 'PARTNER' }
      ]
    }
    return [{ value: 'CLIENT', display: 'CLIENT' }]
  }

  static canSendEmail (userType) {
    return userType === 'CLIENT'
  }

  constructor (props) {
    super(props)
    this.state = {
      submitting: false,
      submitDisabled: true,
      showRoles: false,
      showAffiliates: true,
      requiredAffiliate: false,
      sendEmail: true,
      emailText: 'Send welcome email',
      formErrors: {},
      affiliateId: '',
      affiliates: []
    }
    this.getInitialFormValues = this.getInitialFormValues.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.createUser = this.createUser.bind(this)
    this.attachToAccount = this.attachToAccount.bind(this)
    this.toggleSendEmail = this.toggleSendEmail.bind(this)
    this.getUserPlatformRoles = this.getUserPlatformRoles.bind(this)
    this.form = this.getInitialFormValues()
  }

  componentDidMount () {
    this._mounted = true
    affiliatesHelper.getAffiliates().then((affiliates) => {
      affiliates.unshift({
        value: '',
        display: '-- None --'
      })

      this._mounted &&
        this.setState({
          affiliates
        })
    })
  }

  componentWillUnmount () {
    this._mounted = false
  }

  getInitialFormValues () {
    const { baseData } = this.props
    if (baseData) {
      return {
        'user[email]': baseData.email,
        'user[type]': UserCreateForm.getUserTypes()[0].value
      }
    }
    return {
      'user[type]': UserCreateForm.getUserTypes()[0].value
    }
  }

  getUserPlatformRoles (roles) {
    return this.state.showRoles && (
      <UserPlatformRoles id="vp-UserCreateForm__platform-roles">
        {roles.map(role => (
          <label
            htmlFor={role}
            key={role}
            className="vp-UserCreateForm__PlatformRoles-card-label"
          >
            <input
              name={role}
              id={role}
              type="checkbox"
              onChange={this.handleCheckboxChange}
            />
            {snakeToTitleCase(role)}
          </label>
        ))}
      </UserPlatformRoles>
    )
  }

  handleInputChange ({ target }) {
    const obj = formHelpers.formatInputChange(target)
    this.form = { ...this.form, ...obj }

    if (target.name === 'user[affiliate_id]') {
      this.setState({ affiliateId: target.value })
    }
    // if hiding the 'send email' checkbox
    // set to false in state as well
    if (!UserCreateForm.canSendEmail(this.form['user[type]']) && this.state.sendEmail) {
      this.toggleSendEmail()
    }

    if (!this.state.submitting && this.state.submitDisabled) {
      this.setState({ submitDisabled: false })
    }
  }

  handleSelectChange ({ target }) {
    const { affiliates } = this.state
    // if changing types and had already selected AFFILIATE,
    // need to remove affiliate_id becuase the key can only be present
    // for that type of user
    if (target.name === 'user[type]') {
      delete this.form['user[affiliate_id]']
    }

    switch (target.value) {
      case 'INTERNAL':
        if (affiliates.length > 0) {
          affiliates[0].disabled = false
        }
        this.setState({
          showRoles: true,
          roles: [],
          emailText: 'send reset password email',
          showAffiliates: false,
          requiredAffiliate: false,
          affiliates
        })

        break
      case 'AFFILIATE': {
        // make sure the default value
        // for affiliate_id is selected if rendered
        const value = affiliates[1] ? affiliates[1].value : ''
        if (affiliates.length > 0) {
          affiliates[0].disabled = true
        }
        this.handleInputChange({
          target: {
            name: 'user[affiliate_id]',
            value
          }
        })
        this.setState({
          showRoles: true,
          roles: [],
          emailText: 'send reset password email',
          showAffiliates: true,
          requiredAffiliate: true,
          affiliates
        })

        break
      }
      default:
        if (affiliates.length > 0) {
          affiliates[0].disabled = false
        }
        this.setState({
          showRoles: false,
          showAffiliates: true,
          emailText: 'send welcome email',
          roles: undefined,
          requiredAffiliate: false,
          affiliates
        })
    }
    this.handleInputChange({ target })
  }

  handleCheckboxChange ({ target }) {
    const prevState = this.state.roles
    const selectedRoles = [...prevState]
    const currentRole = target.name

    if (target.checked) {
      selectedRoles.push(currentRole)
    } else {
      selectedRoles.splice(selectedRoles.indexOf(currentRole), 1)
    }

    this.setState({ roles: selectedRoles })
  }

  toggleSendEmail () {
    this.setState(prevState => ({ sendEmail: !prevState.sendEmail }))
  }

  async createUser (body) {
    let response

    try {
      response = createUser(body)
    } catch (error) {
      this.setState({ error })
    }

    return response
  }

  async attachToAccount (userId) {
    let response
    try {
      response = await requestHelpers.makeRequest({
        method: 'POST',
        url: `/accounts/${this.props.baseData.accountId}/users`,
        body: {
          user_id: userId
        },
        toJson: false
      })
    } catch (error) {
      this.setState({ error })
    }

    return response
  }

  async handleSubmit (event) {
    event.preventDefault()
    const { user } = formHelpers.formatFormParams(this.form)

    this.setState({ submitting: true, submitDisabled: true })

    user.platform_roles = this.state.roles

    let response
    try {
      response = await this.createUser(user)
    } catch (error) {
      this.setState({ error })
    }

    if (response.status === 400) {
      const formErrors = formHelpers.handleValidationErrors(response)
      this.setState({ formErrors })
    } else {
      if (this.props.baseData) {
        await this.attachToAccount(response.id)
      }

      this.props.handleNewUser(response.id, this.state.sendEmail)
    }

    this.setState({ submitting: false })
  }

  render () {
    const {
      error, affiliates, requiredAffiliate, formErrors, affiliateId
    } = this.state
    if (error) throw error

    const { baseData } = this.props
    // business rule: only 'admin' users can create other 'admin' users
    const roles = permissionHelper.hasPerm({ roles: ['admin'] })
      ? this.props.roles
      : this.props.roles.filter(role => role !== 'admin')

    return (
      <React.Fragment>
        {this.state.submitting && <Loading />}

        <Form id="vp-UserCreateForm" cssClass="vp-UserCreateForm" onSubmit={this.handleSubmit}>
          <Input
            type="text"
            id="user_first_name"
            name="user[first_name]"
            labelText="First Name*"
            onChange={this.handleInputChange}
            formError={formErrors['user.first_name']}
            required
          />
          <Input
            type="text"
            id="user_last_name"
            name="user[last_name]"
            labelText="Last Name*"
            onChange={this.handleInputChange}
            formError={formErrors['user.last_name']}
            required
          />
          <Input
            type="text"
            id="user_email"
            name="user[email]"
            labelText="Email*"
            onChange={this.handleInputChange}
            formError={formErrors['user.email']}
            defaultValue={baseData ? baseData.email : ''}
            disabled={baseData && baseData.email}
            required
          />
          <Select
            id="user_type"
            name="user[type]"
            labelText="Type*"
            options={UserCreateForm.getUserTypes()}
            defaultValue={UserCreateForm.getUserTypes()[0]}
            onChange={this.handleSelectChange}
            formError={formErrors['user.type']}
            disabled={baseData && baseData.email}
            required
          />

          {permissionHelper.isAdmin() && this.state.showAffiliates && (
            <>
              <Select
                id="user_affiliate_id"
                name="user[affiliate_id]"
                labelText={`${requiredAffiliate ? 'Partner*' : 'Preferred Partner'}`}
                options={affiliates}
                value={affiliateId}
                tooltip={!requiredAffiliate ? CLIENT_AFFILIATE_TOOLTIP : null}
                onChange={this.handleInputChange}
                formError={formErrors['user.affiliates']}
                required={requiredAffiliate}
              />
            </>
          )}

          {this.getUserPlatformRoles(roles)}

          {UserCreateForm.canSendEmail(this.form['user[type]']) && (
            <label
              htmlFor="user_send_email"
              id="vp-UserCreateForm__sendemail-label"
              className="vp-UserCreateForm__sendemail-label"
            >
              <input
                id="user_send_email"
                type="checkbox"
                onChange={this.toggleSendEmail}
                defaultChecked
              />
              {this.state.emailText}
            </label>
          )}

          <Button type="submit" disabled={this.state.submitDisabled}>
            Create
          </Button>

          {this.props.baseData && (
            <LinkButton
              to={`/accounts/${this.props.baseData.accountId}`}
              href={`/accounts/${this.props.baseData.accountId}`}
            >
              Cancel
            </LinkButton>
          )}
        </Form>
      </React.Fragment>
    )
  }
}

UserCreateForm.defaultProps = {
  baseData: undefined
}

UserCreateForm.propTypes = {
  baseData: PropTypes.shape({
    email: PropTypes.string,
    accountId: PropTypes.string
  }),
  roles: PropTypes.arrayOf(PropTypes.string),
  handleNewUser: PropTypes.func.isRequired
}

UserCreateForm.defaultProps = {
  roles: []
}

export default UserCreateForm
