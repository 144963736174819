import BrowserAuthentication from '@vivialplatform/browser-authentication-lib'

const {
  REACT_APP_API_URL,
  REACT_APP_HOST,
  REACT_APP_AUTH0_DOMAIN,
  REACT_APP_AUTH0_CLIENT_ID,
  REACT_APP_ENV
} = process.env

const AUTH0_CONFIG = {
  auth0Domain: REACT_APP_AUTH0_DOMAIN,
  clientId: REACT_APP_AUTH0_CLIENT_ID,
  host: REACT_APP_HOST,
  loginCallbackUrl: `${REACT_APP_HOST}/callback?env=${REACT_APP_ENV}`,
  logoutUrl: `${REACT_APP_HOST}/logged-out`,
  tokenExchangeUrl: `${REACT_APP_API_URL}/token`,
  currentUserUrl: `${REACT_APP_API_URL}/current-user`
}

let authLib

export default function getInstance () {
  if (!authLib) {
    authLib = BrowserAuthentication
    authLib.init(AUTH0_CONFIG)
  }
  return authLib
}
