import requestHelpers from '../../../helpers/request-helpers'

function getClient (id) {
  const request = {
    method: 'GET',
    url: `/clients/${id}`
  }
  return requestHelpers.makeRequest(request)
}

function getClientDerivedConfig (id) {
  const request = {
    method: 'GET',
    url: `/clients/${id}/derived-config`
  }
  return requestHelpers.makeRequest(request)
}

export default getClient
export {
  getClient,
  getClientDerivedConfig
}
