import React from 'react'
import PropType from 'prop-types'
import { Redirect } from 'react-router'

import PROP_TYPES from '../../../prop-types'
import Page from '../../common/Page'
import Button from '../../common/Button'
import Modal from '../../common/Modal'
import { Form, Select } from '../../common/Form'
import withUserGet from '../shared/with-user-get'
import Loading from '../../common/Loading'
import formHelpers from '../../../helpers/form-helpers'
import requestHelpers from '../../../helpers/request-helpers'
import affiliatesHelper from '../../../helpers/affiliates-request-helper'

const USER_TYPES = [
  { value: 'CLIENT', display: 'CLIENT' },
  { value: 'INTERNAL', display: 'INTERNAL' },
  { value: 'AFFILIATE', display: 'PARTNER' }
]

export class UserChangeType extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      submitDisabled: true,
      submitting: false,
      showModal: false,
      formErrors: [],
      affiliates: []
    }

    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleTypeSelectChange = this.handleTypeSelectChange.bind(this)
    this.handleModalYes = this.handleModalYes.bind(this)
    this.toggleModal = this.toggleModal.bind(this)
    this.handleModalNo = this.handleModalNo.bind(this)
  }

  componentDidMount () {
    this._mounted = true
    affiliatesHelper.getAffiliates().then((affiliates) => {
      this._mounted &&
        this.setState({
          affiliates
        })
    })
  }

  handleTypeSelectChange ({ target }) {
    const obj = formHelpers.formatInputChange(target)
    this.form = { ...this.form, ...obj }
    const { affiliates } = this.state
    if (target.value === 'AFFILIATE') {
      // set the default value to be the first option
      // in case the user submits wihtout changing the affiliate
      this.form['user[affiliate_id]'] = affiliates[0].value
      this.setState({ showAffiliates: true })
    } else {
      delete this.form['user[affiliate_id]']
      this.setState({ showAffiliates: false })
    }

    if (!this.state.submitting && this.state.submitDisabled) {
      this.setState({ submitDisabled: false })
    }
  }

  handleSelectChange ({ target }) {
    const obj = formHelpers.formatInputChange(target)
    this.form = { ...this.form, ...obj }

    if (!this.state.submitting && this.state.submitDisabled) {
      this.setState({ submitDisabled: false })
    }
  }

  async handleModalYes (event) {
    event.preventDefault()
    this.setState({ submitting: true })

    const { user } = this.props.data
    this.form = { ...this.form, 'user[version]': user.version }
    const { user: form } = formHelpers.formatFormParams(this.form)

    let response
    try {
      response = await requestHelpers.makeRequest({
        method: 'PATCH',
        url: `/users/${user.id}/change-type`,
        body: form,
        toJson: false
      })
    } catch (error) {
      this.setState({ error })
    }

    if (response.status === 204) {
      this.setState({
        location: {
          pathname: `/users/${user.id}`,
          state: {
            notice: { message: "Successfully changed user's type.", type: 'success' }
          }
        }
      })
    } else {
      let json
      try {
        json = await response.json()
      } catch (error) {
        this.setState({ error })
      }

      const formErrors = formHelpers.handleValidationErrors(json)
      this.setState({ formErrors })
    }

    this.setState({ submitting: false })
  }

  handleModalNo () {
    this.setState({
      location: {
        pathname: `/users/${this.props.data.user.id}`
      }
    })
  }

  toggleModal () {
    this.setState(prevState => ({ showModal: !prevState.showModal }))
  }

  render () {
    const { error, location, showAffiliates } = this.state
    if (error) throw error

    if (location) {
      return <Redirect push to={location} />
    }
    const { user } = this.props.data
    if (!user) return <Loading />

    return (
      <Page title={`Change User Type - ${user.first_name} ${user.last_name}`} showBack>
        <Modal isOpen={this.state.showModal} title="Confirm" onRequestClose={this.handleModalClose}>
          <p>Are you sure you want to change the type of this user?</p>
          <p>
            <small>
              <strong>Note:</strong>
              {' '}
it will remove any existing roles or client attachments
            </small>
          </p>
          <Button onClick={this.handleModalYes}>Confirm</Button>
          <Button btnStyle="clear" onClick={this.handleModalNo}>
            Cancel
          </Button>
        </Modal>
        {this.state.submitting && <Loading />}
        <Form onSubmit={e => e.preventDefault()}>
          <Select
            id="user_type"
            name="user[type]"
            labelText="Type"
            options={USER_TYPES}
            defaultValue={user.type}
            onChange={this.handleTypeSelectChange}
            formError={this.state.formErrors['data.type']}
            required
          />
          {showAffiliates && (
            <Select
              id="user_affiliate_id"
              name="user[affiliate_id]"
              labelText="Partner*"
              options={this.state.affiliates}
              onChange={this.handleSelectChange}
              formError={this.state.formErrors['user.affiliates']}
              required
            />
          )}
          <Button type="submit" disabled={this.state.submitDisabled} onClick={this.toggleModal}>
            Save
          </Button>
        </Form>
      </Page>
    )
  }
}

UserChangeType.propTypes = {
  data: PropType.shape({
    user: PROP_TYPES.USER
  }).isRequired
}

export default withUserGet(UserChangeType)
