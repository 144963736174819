import React, { Component } from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'

import Page from '../../common/Page'
import Button from '../../common/Button'
import InputSearch from '../../common/InputSearch'
import requestHelper from '../../../helpers/request-helpers'
import Modal from '../../common/Modal'
import Label from '../../common/Form/Label'
import { Form, FormError } from '../../common/Form'
import PROP_TYPES from '../../../prop-types'
import formHelpers from '../../../helpers/form-helpers'
import Table from '../../common/Table'
import Loading from '../../common/Loading'

import './UserAddAccount.scss'

const INPUT_ID = 'search-users'
export class UserAddAccount extends Component {
  static userIsAttachedToAccount (user, userId) {
    if (user.users && user.users.length) {
      return user.users.some(user1 => user1.userId === userId)
    }

    return false
  }

  constructor (props) {
    super(props)
    this.state = {
      value: '',
      isLoading: true,
      location: {
        pathname: undefined,
        state: {
          userId: '',
          search: '',
          userName: '',
          userEmail: ''
        }
      },
      formError: undefined,
      notice: undefined,
      showModal: false,
      error: undefined,
      account: '',
      welcomeEmail: false,
      redirect: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleModalNo = this.handleModalNo.bind(this)
    this.handleModal = this.handleModal.bind(this)
    this.handleState = this.handleState.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handledAccountSelect = this.handledAccountSelect.bind(this)
  }

  componentDidMount () {
    this.handleState()
  }

  handleChange ({ target }) {
    this.setState({ value: target.value })
  }

  async handleState () {
    let initialState
    try {
      initialState = await requestHelper.makeRequest({
        method: 'GET',
        url: `/users/${this.props.match.params.id}`
      })
    } catch (error) {
      this.setState({ error, isLoading: false })
    }

    this.setState(prevState => ({
      isLoading: false,
      location: {
        state: {
          userId: this.props.match.params.id,
          search: prevState.value,
          userName: `${initialState.user.first_name} ${initialState.user.last_name}`,
          userEmail: initialState.user.email
        }
      }
    }))
  }

  async handleSearchResults ({ data: [user] }) {
    if (!user) {
      this.setState(prevState => ({
        location: {
          state: {
            userId: this.props.match.params.id,
            search: prevState.value,
            userName: prevState.location.state.userName,
            userEmail: prevState.location.state.userEmail
          }
        }
      }))
      return
    }

    const { id: userId } = this.props.match.params

    if (UserAddAccount.userIsAttachedToAccount(user, userId)) {
      this.setState({
        notice: {
          message: 'User already attached to account',
          type: 'error'
        },
        showModal: false
      })
    }
  }

  async handleSearch (e) {
    e.preventDefault()
    this.setState({ notice: undefined, formError: undefined })
    const search = encodeURIComponent(this.state.value)
    let results
    try {
      results = await requestHelper.makeRequest({
        method: 'GET',
        url: `/accounts?search=${search}`
      })
    } catch (error) {
      this.setState({ error })
    }

    if (results.status === 400) {
      const formError = formHelpers.handleValidationErrors(results)
      this.setState({ formError })
      return
    }
    try {
      await this.handleSearchResults(results)
    } catch (error) {
      this.setState({ error })
    }
    this.setState({
      results
    })
  }

  async handleModal (accountId) {
    let results
    try {
      results = await requestHelper.makeRequest({
        method: 'GET',
        url: `/accounts/${accountId}`
      })
    } catch (error) {
      this.setState({ error })
    }

    if (results.status === 400) {
      const formError = formHelpers.handleValidationErrors(results)
      this.setState({ formError })
      return
    }
    this.setState({
      showModal: true,
      error: false,
      account: results.account
    })
  }

  async handledAccountSelect (accountId) {
    const { id } = this.props.match.params
    const userId = id
    let response

    try {
      response = await requestHelper.makeRequest({
        method: 'POST',
        url: `/accounts/${accountId}/users`,
        body: {
          user_id: userId
        },
        toJson: false
      })
    } catch (error) {
      if (error.status === 409) {
        this.setState({
          formError: {
            email: 'User already attached to account'
          },
          showModal: false
        })
        return
      }
      this.setState({ error })
    }
    if (this.state.welcomeEmail) {
      let emailResponse
      try {
        emailResponse = await requestHelper.makeRequest({
          method: 'POST',
          url: `/users/${userId}/reset-password?send_account_welcome_email=true`,
          toJson: false
        })
      } catch (error) {
        if (emailResponse.status === 400) {
          this.setState({
            formError: {
              email: 'Welcome email not sent, please check your connection'
            },
            showModal: false
          })
          return
        }
        this.setState({ error })
      }
    }
    if (response.status === 201) {
      const notice = { notice: { message: 'Successfully added user to account.', type: 'success' } }
      const location = {
        pathname: `/users/${userId}`,
        state: notice
      }
      this.setState({ location, redirect: true })
    }
  }

  handleModalNo (e) {
    this.setState({ showModal: false })
  }

  handleCheckboxChange (event) {
    this.setState({ welcomeEmail: event.target.checked })
  }

  render () {
    if (this.state.isLoading) {
      return <Loading />
    }

    if (this.state.error) throw this.state.error
    const {
      location, account, formError, redirect
    } = this.state

    if (redirect) {
      return <Redirect push to={location} />
    }
    const title = `Add Account ${location ? `- ${location.state.userName}` : ''}`
    const HEADERS = [
      {
        name: 'Account Name',
        accessor: 'name',
        settings: {
          type: 'linkAddAccount',
          function: this.handleModal,
          accessor: 'id'
        }
      },
      { name: 'Phone', accessor: 'phone' },
      { name: 'Account Number', accessor: 'account_number' },
      { name: 'Enterprise Customer ID', accessor: 'enterprise_customer_id' }
    ]

    return (
      <Page title={title} notice={this.state.notice} showBack>
        {this.state.showModal && (
          <Modal
            isOpen={this.state.showModal}
            title="Confirm"
            onRequestClose={this.handleModalClose}
            ariaHideApp={false}
          >
            <div id="vp-AccountAddUser" className="vp-AccountAddUser">
              {location.state && (
                <div className="vp-AddUser__details">
                  <p>{`Attach this account to ${location.state.userName}?`}</p>
                  <div className="vp-AddUser__details-container">
                    <div className="vp-AddUser__details-content">
                      <p className="vp-AddUser__details-content-label">Account Name</p>
                      <p>{account.sf_account.name}</p>
                    </div>
                    <div className="vp-AddUser__details-content">
                      <p className="vp-AddUser__details-content-label">Account Number</p>
                      <p>{account.sf_account.account_number}</p>
                    </div>
                    <div className="vp-AddUser__details-content">
                      <p className="vp-AddUser__details-content-label">
                        <Label htmlFor="welcomeEmail" text="Send welcome email?">
                          {' '}
                          <input
                            name="welcomeEmail"
                            id="welcomeEmail"
                            type="checkbox"
                            onChange={this.handleCheckboxChange}
                          />
                        </Label>
                      </p>
                      <p />
                    </div>
                  </div>
                  <div className="vp-AddUser__actions">
                    <Button onClick={() => this.handledAccountSelect(account.id)}>Yes</Button>
                    <Button btnStyle="clear" onClick={this.handleModalNo}>
                      No
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </Modal>
        )}
        <Form id="AddAccount__Search" onSubmit={this.handleSearch}>
          <InputSearch
            name="search"
            htmlId={INPUT_ID}
            placeholder="Search by account name, enterprise customer ID, or account number"
            onChange={this.handleChange}
          />
          {this.state.results && (
            <Table headers={HEADERS} rows={this.state.results.data} rowKey="id" key="id" />
          )}
        </Form>
        <FormError message={formError && formError.email} />
      </Page>
    )
  }
}

UserAddAccount.propTypes = {
  match: PROP_TYPES.ID_IN_PATH.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      userName: PropTypes.string.isRequired
    })
  }).isRequired
}

export default UserAddAccount
