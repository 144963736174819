import getAuthInstance from '../config/auth'
import HttpError from './errors'
import mockFetch from './mock-fetch'

const auth = getAuthInstance()
const { REACT_APP_API_URL, NODE_ENV } = process.env

async function makeRequest ({
  method, url, body, toJson = true
} = {}) {
  const request = { method }
  const fullUrl = `${REACT_APP_API_URL}${url}`
  if (body) request.body = JSON.stringify(body)

  let response

  if (NODE_ENV !== 'prod' && /MOCK/.test(fullUrl)) {
    response = await mockFetch(fullUrl, request)
  } else {
    response = await api._makeRequestWithRetry(fullUrl, request)
  }

  try {
    return await api._handleResponse(response, toJson)
  } catch (err) {
    if (err.status !== 401) throw err
  }

  // response status was 401, reauth and retry
  try {
    await auth.checkSession()
  } catch (err) {
    if (err.response && err.response.data && err.response.data.status === 403) {
      throw new HttpError(403)
    }
    return auth.login()
  }

  let retryResponse
  try {
    retryResponse = await api._makeRequestWithRetry(fullUrl, request)
  } catch (error) {
    this.setState({ error })
  }

  // this could throw a 401 up which means we reauthenticated successfully,
  // remade the request, and still received a 401. HOW DID THIS HAPPEN!?
  try {
    return await api._handleResponse(retryResponse, toJson)
  } catch (err) {
    throw err
  }
}

async function _handleResponse (response, toJson) {
  if (response.ok || response.status === 400) {
    if (toJson) {
      try {
        return await response.json()
      } catch (err) {
        throw err
      }
    }
    return response
  }
  throw new HttpError(response.status, response.statusText)
}

function _getAuthHeader () {
  return {
    Authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  }
}

function _makeRequest (url, request) {
  request.headers = api._getAuthHeader()
  return window.fetch(url, request)
}

// Make the request twice in case of 502 and 504
async function _makeRequestWithRetry (url, request) {
  const response = await api._makeRequest(url, request)
  if ((response.status === 502 || response.status === 504) && request.method !== 'POST') {
    return api._makeRequest(url, request)
  }
  return response
}

export const api = {
  makeRequest,
  _getAuthHeader,
  _makeRequest,
  _makeRequestWithRetry,
  _handleResponse
}

export default api
