import React from 'react'
import PropTypes from 'prop-types'

import PROP_TYPES from '../../../prop-types'
import Page from '../../common/Page'
import Loading from '../../common/Loading'
import AccountEditForm from './AccountEditForm'
import withAccountGet from '../shared/with-account-get'

export const AccountEdit = ({ data }) => {
  const { account, affiliates } = data

  if (!account) return <Loading />

  return (
    <Page title="Edit account" showBack>
      <AccountEditForm account={account} affiliates={affiliates} />
    </Page>
  )
}

AccountEdit.propTypes = {
  data: PropTypes.shape({
    account: PROP_TYPES.ACCOUNT,
    error: PROP_TYPES.ERROR,
    affiliates: PropTypes.arrayOf(PROP_TYPES.AFFILIATE)
  }).isRequired
}

export default withAccountGet(AccountEdit, true, false, false)
