import React from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import { defaultColors } from '../../../helpers/theme-helpers'

import './Modal.scss'

/**
 * This is needed to disable this functionality for testing purposes.
 * Otherwise, the #root div is required in all tests...
 */
const { REACT_APP_MODAL_DISABLE_ARIA } = process.env
if (!REACT_APP_MODAL_DISABLE_ARIA) {
  ReactModal.setAppElement('#root')
}

const styles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  content: {
    background: defaultColors.white,
    padding: '0',
    top: '20%',
    right: 'auto',
    bottom: 'auto',
    left: '50%',
    borderRadius: 0,
    transform: 'translate(-50%, -20%)'
  }
}

const Modal = ({ title, children, ...rest }) => (
  <ReactModal {...rest} style={styles}>
    <div className="vp-Modal">
      <div className="vp-Modal__title">{title}</div>
      <div className="vp-Modal__body">{children}</div>
    </div>
  </ReactModal>
)

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired
}

export default Modal
