import React from 'react'

import Page from 'components/common/Page'
import Search from 'components/common/Search'
import permissionHelper from 'helpers/permissions-helpers'
import HttpError from '../../../helpers/errors'

import 'components/client/ClientSearch/ClientSearch.scss'

const {
  REACT_APP_CLIENT_ADMIN_URL,
  REACT_APP_DASHBOARD_URL
} = process.env

function getHeaders () {
  const headers = [
    {
      name: 'Client Name',
      accessor: 'name',
      settings: {
        type: 'link',
        basePath: '/clients',
        accessor: 'id'
      }
    },
    { name: 'Client ID', accessor: 'id' },
    {
      name: 'Account ID',
      accessor: 'account_id',
      settings: {
        type: 'link',
        basePath: '/accounts',
        accessor: 'account_id'
      }
    },
    {
      name: 'Status',
      accessor: 'is_active',
      settings: {
        type: 'boolean'
      }
    }
  ]

  if (permissionHelper.canSeeCladminLink()) {
    headers.push(
      {
        name: 'Client Admin',
        settings: {
          type: 'linkIcon',
          basePath: `${REACT_APP_CLIENT_ADMIN_URL}`,
          accessor: 'id',
          icon: 'external-link',
          tooltip: 'View on Client Admin'
        }
      }
    )
  }

  if (permissionHelper.canSeeDashboardLink()) {
    headers.push(
      {
        name: 'Marketing Platform',
        settings: {
          type: 'linkIcon',
          basePath: `${REACT_APP_DASHBOARD_URL}/goto`,
          accessor: 'id',
          icon: 'external-link',
          tooltip: 'View on Marketing Platform'
        }
      }
    )
  }

  return headers
}

const ClientSearch = () => {
  if (!permissionHelper.hasPerm({ policies: ['client:read'] })) {
    throw new HttpError(403)
  }

  return (
    <Page title="Clients">
      <div className="vp-ClientSearch">
        <Search
          searchKey="search"
          apiUrl="/clients"
          placeholder="Search by client name, client ID, or account ID"
          headers={getHeaders()}
          rowKey="id"
          inputId="search"
        />
      </div>
    </Page>
  )
}

export default ClientSearch
