/* eslint jsx-a11y/label-has-for: off */
import React from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'

import snakeToTitleCase from 'helpers/utils'
import Button from '../../common/Button'
import Page from '../../common/Page'
import { Form, Label, FormError } from '../../common/Form'
import PROP_TYPES from '../../../prop-types'
import Loading from '../../common/Loading'
import rolesHelper from '../../../helpers/roles-helper'
import permHelper from '../../../helpers/permissions-helpers'
import requestHelper from '../../../helpers/request-helpers'
import formHelpers from '../../../helpers/form-helpers'
import withUserGet from '../shared/with-user-get'
import HttpError from '../../../helpers/errors'
import UserPlatformRoles from '../shared/UserPlatformRoles'

import './UserEditPlatformRoles.scss'

const PERMISSIONS_UPDATE_PERM = { policies: ['user#permissions:update'] }
export class UserEditPlatformRoles extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      loading: true,
      submitting: false
    }

    this.handleRolesChanged = this.handleRolesChanged.bind(this)
    this.handlePoliciesChanged = this.handlePoliciesChanged.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.getUserPlatformRoles = this.getUserPlatformRoles.bind(this)
  }

  componentDidMount () {
    this._mounted = true
    rolesHelper.getAllRoles().then(
      roles => this._mounted &&
        this.setState({
          roles,
          loading: false
        })
    )
  }

  componentWillUnmount () {
    this._mounted = false
  }

  getUserPlatformRoles () {
    let { roles } = this.state
    if (roles && !permHelper.hasPerm({ roles: ['admin'] })) {
      // business rule: only 'admin' users can create other 'admin' users
      roles = roles.filter(role => role !== 'admin')
    }

    return (
      <UserPlatformRoles id="vp-UserEditPlatformRolesForm__roles">
        {roles &&
          roles.length &&
          roles.map((role) => {
            const checked = this.userRoles.roles.includes(role)
              ? { defaultChecked: true }
              : {}
            return (
              <React.Fragment key={role}>
                <input
                  {...checked}
                  id={role}
                  key={role}
                  name={role}
                  type="checkbox"
                  onChange={this.handleRolesChanged}
                />
                <label htmlFor={role}>
                  {snakeToTitleCase(role)}
                </label>
              </React.Fragment>
            )
          })}
        <FormError message={this.state.formErrors ? this.state.formErrors.roles : undefined} />
      </UserPlatformRoles>
    )
  }

  handleRolesChanged ({ target }) {
    const { name } = target
    const { selectedRoles = this.userRoles.roles } = this.state

    if (target.checked) {
      selectedRoles.push(name)
    } else {
      selectedRoles.splice(selectedRoles.indexOf(name), 1)
    }

    this.setState({ selectedRoles })
  }

  handlePoliciesChanged ({ target }) {
    if (!target.value) {
      this.setState({ policies: [] })
    } else {
      this.setState({
        policies: target.value.split(',')
      })
    }
  }

  async handleSubmit (event) {
    event.preventDefault()
    this.setState({ submitting: true })
    const { roles } = this.userRoles
    const { selectedRoles = roles, policies } = this.state
    const { id } = this.props.match.params

    const body = {
      roles: selectedRoles,
      policies
    }

    let response
    try {
      response = await requestHelper.makeRequest({
        url: `/users/${id}/permissions/PLATFORM`,
        method: 'PATCH',
        toJson: false,
        body
      })
    } catch (error) {
      this.setState({ error })
    }

    if (response.status === 400) {
      const formErrors = formHelpers.handleValidationErrors(response)
      this.setState({ formErrors })
    } else {
      this.setState({
        location: {
          pathname: `/users/${id}`,
          state: {
            notice: {
              message: 'Policies and roles updated successfully',
              type: 'success'
            }
          }
        },
        loading: false
      })
    }

    this.setState({ submitting: false })
  }

  render () {
    const {
      loading, error, submitting, formErrors, location
    } = this.state

    const { user } = this.props.data

    if (error) throw error

    if (location) {
      return <Redirect push to={this.state.location} />
    }

    if (loading || !user) return <Loading />
    if (user.type === 'CLIENT' || !permHelper.hasPerm(PERMISSIONS_UPDATE_PERM)) {
      throw new HttpError(404)
    }

    this.userRoles = rolesHelper.getApplicationRolesAndPolicies(user.permissions)

    return (
      <Page title={`Edit Platform Roles - ${user.first_name} ${user.last_name}`} showBack>
        <Form
          id="vp-UserEditPlatformRolesForm"
          cssClass="vp-UserEditPlatformRolesForm"
          onSubmit={(e) => {
            e.preventDefault()
          }}
        >
          {submitting && <Loading />}

          {this.getUserPlatformRoles()}

          <Label htmlFor="overrides" text="">
            <textarea
              defaultValue={this.userRoles.policies}
              onChange={this.handlePoliciesChanged}
              placeholder="Comma separated policy overrides. i.e policy-1, policy-2, policy-3"
            />
            <FormError message={formErrors ? formErrors.policies : undefined} />
          </Label>
          <Button type="submit" onClick={this.handleSubmit}>
            Save
          </Button>
        </Form>
      </Page>
    )
  }
}

UserEditPlatformRoles.propTypes = {
  match: PROP_TYPES.ID_IN_PATH.isRequired,
  data: PropTypes.shape({
    user: PROP_TYPES.USER
  }).isRequired
}

export default withUserGet(UserEditPlatformRoles)
