import React from 'react'
import PropTypes from 'prop-types'
import PROP_TYPES from '../../../../prop-types'
import Table from '../../../common/Table'
import Card from '../../../common/Card'
import { defaultColors } from '../../../../helpers/theme-helpers'

const getTableHeaders = (detachActionHandler, editPermissions, history) => {
  const actions = []
  if (detachActionHandler) {
    actions.push({
      text: 'Detach',
      clickHandler: detachActionHandler,
      props: {
        btnStyle: 'clear',
        color: defaultColors.alert
      }
    })
  }

  if (editPermissions) {
    actions.push({
      text: 'Permissions',
      href: editPermissions,
      clickHandler: history ? history.push : null,
      accessor: 'id',
      props: {
        btnStyle: 'clear',
        color: defaultColors.darkGray
      }
    })
  }

  return [
    {
      name: 'Account Number',
      accessor: 'account_number',
      settings: {
        type: 'link',
        basePath: '/accounts',
        accessor: 'id'
      }
    },
    {
      name: 'Name',
      accessor: 'name'
    },
    {
      name: 'Actions',
      accessor: 'id',
      settings: {
        type: 'actions',
        actions
      }
    }
  ]
}

const AccountsCard = ({
  detachActionHandler, accounts, canAddAccount, rootPath, editPermissions, history
}) => {
  const addAccountPath = `${rootPath}/accounts/add`

  let addLink

  if (canAddAccount) {
    addLink = {
      to: addAccountPath,
      href: addAccountPath,
      text: 'Add',
      icon: 'plus',
      id: 'vp-AddAccount'
    }
  }
  const CLIENTS_HEADERS = getTableHeaders(detachActionHandler, editPermissions, history)

  return (
    <Card title="Accounts" titleLink={addLink}>
      {accounts && accounts.length ? (
        <Table rowKey="id" headers={CLIENTS_HEADERS} rows={accounts} />
      ) : (
        <div className="vp-UserView__details-container">
          <div className="vp-UserView__details-content">
            <p>No accounts</p>
          </div>
        </div>
      )}
    </Card>
  )
}

AccountsCard.defaultProps = {
  accounts: [],
  detachActionHandler: undefined,
  editPermissions: '',
  history: undefined
}

AccountsCard.propTypes = {
  accounts: PropTypes.arrayOf(PROP_TYPES.ACCOUNT),
  canAddAccount: PropTypes.bool.isRequired,
  rootPath: PropTypes.string.isRequired,
  detachActionHandler: PropTypes.func,
  editPermissions: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default AccountsCard
