import requestHelpers from '../../../helpers/request-helpers'

function getLocation (id) {
  const request = {
    method: 'GET',
    url: `/locations/${id}`
  }
  return requestHelpers.makeRequest(request)
}

export default getLocation
