import React from 'react'
import propTypes from 'prop-types'

import './Form.scss'

const Form = ({ children, cssClass, ...rest }) => (
  <form {...rest} className={`vp-Form ${cssClass}`}>
    {children}
  </form>
)

Form.propTypes = {
  children: propTypes.oneOfType([propTypes.arrayOf(propTypes.node), propTypes.node]).isRequired,
  cssClass: propTypes.string
}

Form.defaultProps = {
  cssClass: ''
}

export default Form
