import requestHelpers from '../../../helpers/request-helpers'

const { REACT_APP_AUDIT_LOG_PAGE_SIZE = 50 } = process.env

function getUser (id) {
  const request = {
    method: 'GET',
    url: `/users/${id}`
  }
  return requestHelpers.makeRequest(request)
}

function getUserAuditLogs (id, pageSize = REACT_APP_AUDIT_LOG_PAGE_SIZE) {
  const request = {
    method: 'GET',
    url: `/users/${id}/audit?page_size=${pageSize}`
  }
  return requestHelpers.makeRequest(request)
}

function getUserAccountPermissions (id, accountId) {
  const request = {
    method: 'GET',
    url: `/users/${id}/account-permissions/${accountId}`
  }
  return requestHelpers.makeRequest(request)
}

const updateUserAccountPermissions = (userId, accountId, permissions) => {
  const request = {
    method: 'PUT',
    body: { permissions },
    url: `/users/${userId}/account-permissions/${accountId}`,
    toJson: false
  }
  return requestHelpers.makeRequest(request, false)
}

const createUser = (user) => {
  const request = {
    method: 'POST',
    url: `/users`,
    body: user
  }
  return requestHelpers.makeRequest(request)
}

export {
  getUserAuditLogs,
  getUserAccountPermissions,
  getUser,
  updateUserAccountPermissions,
  createUser
}
