import React from 'react'
import PropTypes from 'prop-types'

function collapsible (WrappedComponent) {
  class Collapsible extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        collapsed: props.initiateCollapsed,
        hasBeenExpanded: !props.initiateCollapsed
      }
      this.toggleCollapse = this.toggleCollapse.bind(this)
      this.performOnFirstExpand = this.performOnFirstExpand.bind(this)
    }

    componentDidMount () {
      if (!this.props.initiateCollapsed) {
        this.performOnFirstExpand()
      }
    }

    toggleCollapse () {
      if (!this.state.hasBeenExpanded) {
        this.performOnFirstExpand()
      }

      this.setState(({ collapsed }) => ({ collapsed: !collapsed }))
    }

    performOnFirstExpand () {
      if (this.props.onFirstExpand !== undefined) {
        this.props.onFirstExpand()
      }
      this.setState(() => ({ hasBeenExpanded: true }))
    }

    render () {
      const clonedProps = { ...this.props }
      delete clonedProps.initiateCollapsed
      delete clonedProps.onFirstExpand

      const { getCollapseButton, children, ...rest } = clonedProps
      return (
        <WrappedComponent
          {...rest}
          collapseButton={getCollapseButton(this.toggleCollapse, this.state.collapsed)}
          collapsed={this.state.collapsed}
        >
          {children}
        </WrappedComponent>
      )
    }
  }
  Collapsible.defaultProps = {
    children: undefined,
    initiateCollapsed: false,
    getCollapseButton: undefined,
    onFirstExpand: undefined
  }
  Collapsible.displayName = `Collapsible(${WrappedComponent.displayName || WrappedComponent.name})`
  Collapsible.WrappedComponent = WrappedComponent
  Collapsible.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    getCollapseButton: PropTypes.func,
    onFirstExpand: PropTypes.func,
    initiateCollapsed: PropTypes.bool
  }

  return Collapsible
}

export default collapsible
