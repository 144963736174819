import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'

import getInstance from '../../../config/auth'
import HttpError from '../../../helpers/errors'
import Loading from '../../common/Loading'

const auth = getInstance()

class Callback extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      error: undefined
    }
    this.login = this.login.bind(this)
  }

  async login () {
    let authResponse
    try {
      authResponse = await auth.handleLogin()
    } catch (err) {
      if (err.message && err.message === 'Service temporarily unavailable.') {
        const error = new HttpError(500, err.message)
        error.displayErrorMessage = true
        window.history.replaceState('', '', window.location.pathname)
        this.setState({ error })
      } else if (err.response && err.response.data && err.response.data.status === 403) {
        window.history.replaceState('', '', window.location.pathname)
        this.setState({ error: new HttpError(403) })
      } else {
        auth.login()
      }
      return
    }

    const { pathname, search } = authResponse.params
    let targetPath
    // stop /callback loop if the user hit "back" to auth0 login
    if (pathname === '/callback') {
      targetPath = ''
    } else {
      targetPath = `${pathname}${search ? `?${search}` : ''}`
    }
    this.props.history.replace(targetPath)
  }

  render () {
    if (this.state.error) throw this.state.error
    this.login()
    return <Loading full />
  }
}

Callback.propTypes = {
  history: PropTypes.shape({ replace: PropTypes.func }).isRequired
}

export default withRouter(Callback)
