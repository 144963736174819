import React, { Component } from 'react'
import PropTypes from 'prop-types'
import './AppLayout.scss'
import Header from '../Header'
import SidebarMenu from '../SidebarMenu'
import Menu from '../Menu'
import vivialLogo from '../../../img/Vivial_logo_rgb_rev.png'
import permissionHelper from '../../../helpers/permissions-helpers'

const propTypes = {
  /**
   * MenuItem props for instances to be displayed in the sidebar menu.
   */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      /** font awesome icon name */
      iconName: PropTypes.string,
      /** MenuItem label */
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  /** Filename of logo image. Default is vivial-logo.png */
  logoImage: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element])
    .isRequired
}

const defaultProps = {
  logoImage: vivialLogo
}

class AppLayout extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sidebarCollapsed: true
    }
    this.toggleSidebar = this.toggleSidebar.bind(this)
    this.handleMenuItemClick = this.handleMenuItemClick.bind(this)
    this.generateMenuItems = this.generateMenuItems.bind(this)
  }

  toggleSidebar (e) {
    if (e.path && e.path[0].className.includes('menu-hamburger')) {
      return
    }
    this.setState(prevState => ({ sidebarCollapsed: !prevState.sidebarCollapsed }))
  }

  generateMenuItems () {
    let { menuItems } = this.props

    if (!permissionHelper.hasPerm({ policies: ['user:read'] })) {
      menuItems = menuItems.filter(menuItem => menuItem.label !== 'Users')
    }

    if (!permissionHelper.hasPerm({ policies: ['account:read'] })) {
      menuItems = menuItems.filter(menuItem => menuItem.label !== 'Accounts')
    }

    if (!permissionHelper.hasPerm({ policies: ['client:read'] })) {
      menuItems = menuItems.filter(menuItem => menuItem.label !== 'Clients')
    }

    if (!permissionHelper.hasPerm({ policies: ['product:read'] })) {
      menuItems = menuItems.filter(menuItem => menuItem.label !== 'Products')
    }

    return menuItems
  }

  handleMenuItemClick () {
    this.setState({ sidebarCollapsed: true })
  }

  render () {
    const { logoImage, children } = this.props

    return (
      <div className="vp-AppLayout vivial">
        <Header logoImage={logoImage} toggleSidebar={this.toggleSidebar} />
        <div className="vp-AppLayout__container">
          <SidebarMenu toggleSidebar={this.toggleSidebar} collapsed={this.state.sidebarCollapsed}>
            <Menu
              menuItems={this.generateMenuItems()}
              handleMenuItemClick={this.handleMenuItemClick}
            />
          </SidebarMenu>
          {children}
        </div>
      </div>
    )
  }
}

AppLayout.propTypes = propTypes
AppLayout.defaultProps = defaultProps

export default AppLayout
