/**
 * Take params from forms like { "resource[property]": value } and convert to:
 * { resource: { property: value } }
 *
 * @param {Object} params
 * @returns {Object}
 */
function formatFormParams (params) {
  const result = {}
  Object.keys(params).forEach((paramKey) => {
    const split = paramKey.split('[')
    const rootKey = split[0]
    const splitKey = split[1]
    const key = splitKey.substr(0, splitKey.length - 1) // remove ending ]
    if (!result[rootKey]) {
      result[rootKey] = {}
    }
    result[rootKey][key] = params[paramKey]
  })
  return result
}

/**
 * Creates a new form errors object from json in the format:
 * { 'resource.attribute': 'error message' }
 *
 * @param {Object} errors - validation errors
 * @returns {Object} formatted error object
 */
function handleValidationErrors (errors) {
  const formErrors = {}
  errors.errors.forEach((error) => {
    const path = error.path.join('.')
    const { message } = error
    formErrors[path] = message
  })
  return formErrors
}

/**
 * Creates a new form state object for input changes.
 *
 * @param {Object} target from input change event
 * @returns {Object} { name: value }
 */
function formatInputChange (target) {
  const { name, value } = target
  const obj = {}
  obj[name] = value
  return obj
}

export default {
  formatFormParams,
  handleValidationErrors,
  formatInputChange
}
