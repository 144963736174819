import React from 'react'

import Card from '../Card'

import './PasswordRequirements.scss'

const PasswordRequirements = () => (
  <Card id="vp-PasswordRequirements" title="Password Requirements">
    <p>
      At least 8 characters in length
      <br />
      Should contain:
    </p>
    <ul>
      <li>Lower case letters (a-z)</li>
      <li>Upper case letters (A-Z)</li>
      <li>Numbers (i.e. 0-9)</li>
    </ul>
  </Card>
)

export default PasswordRequirements
