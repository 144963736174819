import React from 'react'
import PropTypes from 'prop-types'

const GlobalContext = React.createContext()

const GlobalContextProvider = props => (
  <GlobalContext.Provider
    value={{
      globalState: props.globalState,
      setGlobalState: props.setGlobalState
    }}
  >
    {props.children}
  </GlobalContext.Provider>
)

const GlobalContextConsumer = GlobalContext.Consumer

const GLOBAL_PROP_TYPES = {
  setGlobalState: PropTypes.func,
  globalState: PropTypes.shape({
    '/user': PropTypes.shape({
      search: PropTypes.string
    }),
    '/account': PropTypes.shape({
      search: PropTypes.string
    })
  })
}

const GLOBAL_PROP_DEFAULTS = {
  setGlobalState: null,
  globalState: null
}

GlobalContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  ...GLOBAL_PROP_TYPES
}

export {
  GlobalContextProvider, GlobalContextConsumer, GLOBAL_PROP_TYPES, GLOBAL_PROP_DEFAULTS
}
