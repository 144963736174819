import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import spinner from './spinner.svg'

import './Loading.scss'

class Loading extends PureComponent {
  state = {
    showSpinner: false
  }

  componentDidMount () {
    this._mounted = true
    setTimeout(() => {
      if (this._mounted) {
        this.setState({ showSpinner: true })
      }
    }, this.props.delay)
  }

  componentWillUnmount () {
    this._mounted = false
  }

  render () {
    const { showSpinner } = this.state
    const { full } = this.props

    if (!showSpinner) {
      return null
    }

    return (
      <img
        className={`vp-Loading ${full ? '--full' : ''}`}
        src={spinner}
        alt="loading"
        width="100px"
        height="100px"
      />
    )
  }
}

Loading.propTypes = {
  full: PropTypes.bool,
  delay: PropTypes.number
}

Loading.defaultProps = {
  full: false,
  delay: 250
}

export default Loading
