import React from 'react'
import propTypes from 'prop-types'

import FormError from '../../FormError'
import Label from '../../Label'

function withLabelAndFormError (Wrapped) {
  const WithLabelAndFormError = ({
    id, labelText, formError, tooltip, ...rest
  }) => (
    <Label htmlFor={id} formError={formError} text={labelText} tooltip={tooltip}>
      <Wrapped id={id} {...{ formError, ...rest }} />
      <FormError message={formError} />
    </Label>
  )

  WithLabelAndFormError.displayName = `WithLabelAndFormError(${Wrapped.displayName ||
    Wrapped.name})`
  WithLabelAndFormError.defaultProps = { formError: undefined, tooltip: undefined }
  WithLabelAndFormError.propTypes = {
    id: propTypes.string.isRequired,
    labelText: propTypes.string.isRequired,
    formError: propTypes.string,
    tooltip: propTypes.string
  }

  return WithLabelAndFormError
}

export default withLabelAndFormError
