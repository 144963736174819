import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@vivialplatform/ui-shared-lib'
import { HeaderMenu } from './HeaderMenu'
import theme from '../../../helpers/theme-helpers'

import './Header.scss'

const { REACT_APP_ENV } = process.env

const style = theme.components.Header

const NO_DISPLAY_ENVS = ['live', 'localplatform', 'localhost']

const Header = ({ logoImage, toggleSidebar }) => (
  <header className="vp-Header" style={style}>
    <button
      type="submit"
      className="vp-Header__menu-button"
      onClick={toggleSidebar}
      aria-label="Toggle menu"
    >
      <span className="vp-Header__icon">
        <Icon icon={['fas', 'bars']} />
      </span>
    </button>
    <img className="vp-Header__logo" src={logoImage} alt="logo" />
    {!NO_DISPLAY_ENVS.includes(REACT_APP_ENV) && <p id="vp-Header__environment">{REACT_APP_ENV}</p>}
    <div className="vp-Header__menu-container">
      <HeaderMenu />
    </div>
  </header>
)

Header.propTypes = {
  logoImage: PropTypes.string.isRequired,
  toggleSidebar: PropTypes.func
}

Header.defaultProps = {
  toggleSidebar: undefined
}

export default Header
