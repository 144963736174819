import React from 'react'
import PropTypes from 'prop-types'

const Anchor = ({ children, ...rest }) => <a {...rest}>{children}</a>

Anchor.defaultProps = { children: undefined }
Anchor.propTypes = {
  children: PropTypes.node
}

export default Anchor
