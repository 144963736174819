import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { Icon } from '@vivialplatform/ui-shared-lib'

import hoverable from '../../shared/Hoverable'
import theme, { smartScale, defaultColors } from '../../../../helpers/theme-helpers'
import './MenuItem.scss'

const scaled = smartScale(theme.colors.primary)

const styles = {
  base: {
    color: defaultColors.white,
    backgroundColor: theme.colors.primary
  },
  hover: {
    color: defaultColors.white,
    backgroundColor: scaled
  }
}

const HoverableNavLink = hoverable(NavLink)

const isActiveLink = path => (match, location) => {
  if (match) return true
  const split = path.split('/')
  if (split.length > 1 && location.pathname.startsWith(`/${split[1]}/`)) {
    return true
  }
  return false
}

const MenuItem = ({
  iconName, label, path, handleMenuItemClick
}) => (
  <li>
    <HoverableNavLink
      to={path}
      href={path}
      isActive={isActiveLink(path)}
      activeStyle={styles.hover}
      style={styles}
      onClick={handleMenuItemClick}
      id={`vp-${label}__MenuLink`}
    >
      {iconName && (
        <span className="vp-MenuItem__icon">
          <Icon icon={['fas', `${iconName}`]} />
        </span>
      )}
      <span>{label}</span>
    </HoverableNavLink>
  </li>
)
MenuItem.propTypes = {
  /** font awesome icon name */
  iconName: PropTypes.string.isRequired,
  /** label for menu item */
  label: PropTypes.string.isRequired,
  /** path to route target for menu item */
  path: PropTypes.string.isRequired,
  handleMenuItemClick: PropTypes.func.isRequired
}

export default MenuItem
