/* eslint jsx-a11y/label-has-for: off */
import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'

import PROP_TYPES from '../../../prop-types'
import withUserGet from '../shared/with-user-get'
import Page from '../../common/Page'
import PasswordRequirements from '../../common/PasswordRequirements'
import { Form, Input } from '../../common/Form'
import Button from '../../common/Button'
import Modal from '../../common/Modal'
import Loading from '../../common/Loading'
import requestHelper from '../../../helpers/request-helpers'
import formHelpers from '../../../helpers/form-helpers'

import './UserPasswordReset.scss'

const OPTION_RESET_PASSWORD = 'reset-password'
const OPTION_CHANGE_PASSWORD = 'change-password'

class UserPasswordReset extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showModal: false,
      redirectToUser: false,
      form: {
        selectedOption: OPTION_RESET_PASSWORD
      },
      submitting: false,
      submitDisabled: false,
      formErrors: {},
      error: undefined
    }
    this.form = {}

    this.handleOptionChange = this.handleOptionChange.bind(this)

    this.toggleResetPasswordModal = this.toggleResetPasswordModal.bind(this)
    this.handleResetPasswordModalYes = this.handleResetPasswordModalYes.bind(this)
    this.handleResetPasswordModalClose = this.handleResetPasswordModalClose.bind(this)
    this.handleResetPassword = this.handleResetPassword.bind(this)

    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleChangePassword = this.handleChangePassword.bind(this)
    this.handleFormSubmit = this.handleFormSubmit.bind(this)
  }

  toggleResetPasswordModal () {
    this.setState(prevState => ({ showModal: !prevState.showModal }))
  }

  async handleFormSubmit (event) {
    event.preventDefault()
    if (this.state.form.selectedOption === OPTION_RESET_PASSWORD) {
      try {
        await this.handleResetPassword()
      } catch (error) {
        this.setState({ error })
      }
    } else {
      try {
        await this.handleChangePassword()
      } catch (error) {
        this.setState({ error })
      }
    }
  }

  handleResetPassword () {
    this.toggleResetPasswordModal()
  }

  async handleChangePassword () {
    const { data: body } = formHelpers.formatFormParams(this.form)
    this.setState({ submitting: true, submitDisabled: true })
    let response
    try {
      response = await requestHelper.makeRequest({
        method: 'POST',
        url: `/users/${this.props.match.params.id}/change-password`,
        body,
        toJson: false
      })
    } catch (error) {
      this.setState({ error })
      return
    }

    if (response.status === 204) {
      this.setState({ redirectToUser: true, submitting: false })
      return
    }

    let json
    try {
      json = await response.json()
    } catch (error) {
      this.setState({ error })
      return
    }

    const formErrors = formHelpers.handleValidationErrors(json)
    this.setState({ formErrors, submitting: false, submitDisabled: false })
  }

  async handleResetPasswordModalYes () {
    this.setState({ submitting: true, submitDisabled: true })
    this.toggleResetPasswordModal()
    try {
      await requestHelper.makeRequest({
        method: 'POST',
        url: `/users/${this.props.match.params.id}/reset-password`,
        toJson: false
      })
    } catch (error) {
      this.setState({ error })
      return
    }

    this.setState({ redirectToUser: true, submitting: false })
  }

  handleResetPasswordModalClose () {
    this.toggleResetPasswordModal()
  }

  handleOptionChange (event) {
    const prevState = this.state

    const form = {
      ...prevState.form,
      selectedOption: event.target.value
    }
    this.setState({ form, submitDisabled: false })
  }

  handleInputChange ({ target }) {
    const obj = formHelpers.formatInputChange(target)
    this.form = { ...this.form, ...obj }

    if (!this.state.submitting && this.state.submitDisabled) {
      this.setState({ submitDisabled: false })
    }
  }

  render () {
    if (this.state.error) {
      throw this.state.error
    }
    const { user } = this.props.data

    if (!user) return <Loading />

    const userDisplayName = `${user.first_name} ${user.last_name}`
    const { selectedOption } = this.state.form

    if (this.state.redirectToUser) {
      const redirectMessage =
        selectedOption === OPTION_RESET_PASSWORD
          ? 'Successfully sent password reset email.'
          : "Successfully changed the user's password."

      const notice = {
        message: redirectMessage,
        type: 'success'
      }

      const newLocation = {
        pathname: `/users/${this.props.match.params.id}`,
        state: {
          notice
        }
      }

      return <Redirect push to={newLocation} />
    }

    const submitButtonText =
      selectedOption === OPTION_RESET_PASSWORD ? 'Send Reset Email' : 'Submit'
    return (
      <Page title={`Reset Password - ${userDisplayName}`} showBack>
        {this.state.submitting && <Loading />}
        <Modal
          isOpen={this.state.showModal}
          title="Password Reset Email"
          onRequestClose={this.handleResetPasswordModalClose}
        >
          <p>
Are you sure that you want to send a password reset email for&nbsp;
            {user.email}
?
          </p>
          <Button onClick={this.handleResetPasswordModalYes}>Yes</Button>
          <Button btnStyle="clear" onClick={this.handleResetPasswordModalClose}>
            No
          </Button>
        </Modal>
        <Form onSubmit={this.handleFormSubmit} disabled={this.state.submitDisabled}>
          <div className="vp-UserPasswordReset__reset-method">
            <fieldset className="fieldset">
              <legend>I would like to</legend>
              <div className="vp-UserPasswordReset__input-group">
                <label htmlFor="send">
                  <input
                    type="radio"
                    name="reset-method"
                    value={OPTION_RESET_PASSWORD}
                    checked={selectedOption === OPTION_RESET_PASSWORD}
                    id="send"
                    onChange={this.handleOptionChange}
                  />
                  Send a reset email
                </label>
              </div>
              <div className="vp-UserPasswordReset__input-group">
                <label htmlFor="manual">
                  <input
                    type="radio"
                    name="reset-method"
                    value={OPTION_CHANGE_PASSWORD}
                    checked={selectedOption === OPTION_CHANGE_PASSWORD}
                    id="manual"
                    onChange={this.handleOptionChange}
                  />
                  Change password manually
                </label>
              </div>
            </fieldset>
          </div>
          {selectedOption === OPTION_CHANGE_PASSWORD && (
            <React.Fragment>
              <PasswordRequirements />
              <Input
                type="password"
                id="data_password"
                name="data[password]"
                labelText="Password*"
                required
                formError={this.state.formErrors['data.password']}
                onChange={this.handleInputChange}
              />
              <Input
                type="password"
                id="data_confirm_password"
                name="data[confirm_password]"
                labelText="Password Confirmation*"
                required
                formError={this.state.formErrors['data.confirm_password']}
                onChange={this.handleInputChange}
              />
            </React.Fragment>
          )}
          <div className="vp-UserPasswordReset__submit">
            <Button type="submit" disabled={this.state.submitDisabled}>
              {submitButtonText}
            </Button>
          </div>
        </Form>
      </Page>
    )
  }
}

UserPasswordReset.propTypes = {
  data: PropTypes.shape({
    user: PROP_TYPES.USER
  }).isRequired,
  match: PROP_TYPES.ID_IN_PATH.isRequired
}

export default withUserGet(UserPasswordReset)
