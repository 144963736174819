import theme, { defaultColors, darken } from '../../../../helpers/theme-helpers'

const transition = 'background-color 0.25s ease-out, color 0.25s ease-out'
export const defaultDarkenedStyles = {
  base: {
    color: defaultColors.white,
    backgroundColor: theme.colors.primary,
    transition
  },
  hover: {
    color: defaultColors.white,
    backgroundColor: darken(theme.colors.primary),
    transition
  }
}

export const defaultDarkenedHollowStyles = {
  base: {
    ...defaultDarkenedStyles,
    color: theme.colors.primary,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.colors.primary}`
  },
  hover: {
    ...defaultDarkenedStyles,
    color: darken(theme.colors.primary, 20),
    backgroundColor: 'transparent',
    border: `1px solid ${darken(theme.colors.primary, 20)}`
  }
}

export const defaultDarkenedClearStyles = {
  base: {
    ...defaultDarkenedHollowStyles.base,
    backgroundColor: 'transparent',
    border: 'none'
  },
  hover: {
    ...defaultDarkenedHollowStyles.hover,
    backgroundColor: 'transparent',
    border: 'none'
  }
}

const defaultStyles = {
  default: defaultDarkenedStyles,
  hollow: defaultDarkenedHollowStyles,
  clear: defaultDarkenedClearStyles
}

/**
 * Get the style object to be passed to a hoverable() wrapped component for
 * This should only be used for components where the hover darkens the color,
 * for example, <Button>, <ButtonLink>
 * @param {Object} style backgroundColor and/or color to use for styling
 */
export const getHoverableButtonStyle = ({ color, bgColor, btnStyle = 'default' } = {}) => {
  if (!color && !bgColor) return defaultStyles[btnStyle]
  let fullStyle
  if (btnStyle === 'default') {
    fullStyle = {
      base: {
        color: color || defaultStyles.default.base.color,
        backgroundColor: bgColor || defaultStyles.default.base.backgroundColor
      },
      hover: {
        color: color || defaultStyles.default.hover.color,
        backgroundColor: bgColor ? darken(bgColor) : defaultStyles.default.hover.backgroundColor
      }
    }
  } else if (btnStyle === 'hollow') {
    fullStyle = {
      base: {
        color: color || defaultStyles.hollow.base.color,
        border: `1px solid ${color || defaultStyles.hollow.base.color}`
      },
      hover: {
        color: color ? darken(color, 25) : defaultStyles.hollow.hover.color,
        border: `1px solid ${color ? darken(color, 25) : defaultStyles.hollow.hover.color}`
      }
    }
  } else if (btnStyle === 'clear') {
    fullStyle = {
      base: {
        color: color || defaultStyles.clear.base.color
      },
      hover: {
        color: color ? darken(color) : defaultStyles.clear.hover.color
      }
    }
  }
  return { ...defaultStyles[btnStyle], ...fullStyle }
}
