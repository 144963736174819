/* eslint jsx-a11y/label-has-for: off */
import React from 'react'
import PropTypes from 'prop-types'
import productHelpers from 'components/product/shared/product-helpers'
import { getTodaysDate } from 'helpers/utils'
import Button from '../../../common/Button'
import { Form, Input, Select } from '../../../common/Form'
import Loading from '../../../common/Loading'
import LinkButton from '../../../common/Link'
import formHelpers from '../../../../helpers/form-helpers'
import { createSubscription } from '../../shared/subscription-helpers'
import './SubscriptionCreateForm.scss'

const { getAllProducts } = productHelpers

function mapBaseDataToForm (baseData) {
  const form = {
    'subscription[account_id]': baseData.account_id || '',
    'subscription[vendor_id]': baseData.vendor_id || '',
    'subscription[parent_oli_id]': baseData.parent_oli_id || ''
  }
  return form
}
class SubscriptionCreateForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      submitting: false,
      submitDisabled: true,
      formErrors: {},
      form: {
        'subscription[quantity]': '1'
      },
      productOptions: []
    }
    this.products = []
    this.getInitialFormValues = this.getInitialFormValues.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.enableSubmit = this.enableSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleToday = this.handleToday.bind(this)
    this.mapFormToSubscription = this.mapFormToSubscription.bind(this)
  }

  async componentDidMount () {
    this._mounted = true
    if (this._mounted) {
      const products = await getAllProducts({ is_active: true })
      this.products = products
      const productOptions = products.map(product => ({
        value: product.id,
        display: `${product.name} - ${product.udac}`
      }))
      productOptions.unshift({
        value: '',
        display: '-- Select --'
      })
      const newFormValues = this.getInitialFormValues()
      this.setState(prevState => ({
        productOptions,
        form: {
          ...prevState.form,
          ...newFormValues,
          'subscription[product_id]': productOptions.length ? productOptions[0].value : ''
        },
        submitDisabled: false
      }))
    }
  }

  componentWillUnmount () {
    this._mounted = false
  }

  getInitialFormValues () {
    const { baseData } = this.props
    const form = baseData === undefined ? undefined : mapBaseDataToForm(baseData)
    return form
  }

  handleInputChange ({ target }) {
    const obj = formHelpers.formatInputChange(target)
    this.setState(prevState => ({ form: { ...prevState.form, ...obj } }))

    this.enableSubmit()
  }

  enableSubmit () {
    if (!this.state.submitting && this.state.submitDisabled) {
      this.setState({ submitDisabled: false })
    }
  }

  handleSelectChange ({ target }) {
    this.handleInputChange({ target })
  }

  mapFormToSubscription () {
    const { subscription } = formHelpers.formatFormParams(this.state.form)

    Object.keys(subscription).forEach(key => (subscription[key] === '') && delete subscription[key])

    return subscription
  }

  async handleSubmit (event) {
    event.preventDefault()
    this.setState({ submitting: true, submitDisabled: true })
    const subscription = this.mapFormToSubscription()

    let response
    try {
      response = await createSubscription(subscription)
    } catch (error) {
      this.setState({ error })
    }

    if (response.status === 400) {
      const formErrors = formHelpers.handleValidationErrors(response)
      this.setState({ formErrors, submitting: false })
    } else {
      this.props.handleNewSubscription(response.id)
    }
  }

  findProduct (productId) {
    const product = this.products.find(p => p.id === productId)

    return product
  }

  handleToday () {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        'subscription[ended_at]': getTodaysDate()
      }
    }))
    this.enableSubmit()
  }

  render () {
    const { error, formErrors, productOptions } = this.state
    if (error) throw error

    const { baseData } = this.props
    const selectedProduct = this.findProduct(
      this.state.form[`subscription[product_id]`]
    )
    const vendorIdType = selectedProduct ? selectedProduct.vendor_id_type : undefined

    return (
      <React.Fragment>
        {this.state.submitting && <Loading />}
        <Form
          id="vp-SubscriptionCreateForm"
          cssClass="vp-SubscriptionCreateForm"
          onSubmit={this.handleSubmit}
        >
          <Input
            key="account_id"
            type="text"
            labelText="Account ID"
            id="subscription_account_id"
            name="subscription[account_id]"
            value={this.state.form[`subscription[account_id]`] || ''}
            onChange={this.handleInputChange}
            formError={formErrors[`subscription.account_id`]}
            disabled={Boolean(baseData && baseData.account_id)}
            required
          />

          <Select
            key="product_id"
            options={productOptions}
            labelText="Product"
            id="subscription_product_id"
            name="subscription[product_id]"
            value={this.state.form[`subscription[product_id]`]}
            onChange={this.handleSelectChange}
            formError={formErrors[`subscription.product_id`]}
            required
          />

          <Input
            key="vendor_id"
            type="text"
            labelText={vendorIdType ? `Vendor ID (${vendorIdType})` : 'Vendor ID'}
            id="subscription_vendor_id"
            name="subscription[vendor_id]"
            value={this.state.form[`subscription[vendor_id]`] || ''}
            onChange={this.handleInputChange}
            formError={formErrors[`subscription.vendor_id`]}
            disabled={Boolean(baseData && baseData.vendor_id)}
          />

          <Input
            key="name"
            type="text"
            labelText="Name"
            id="subscription_name"
            name="subscription[name]"
            value={this.state.form[`subscription[name]`] || ''}
            onChange={this.handleInputChange}
            formError={formErrors[`subscription.name`]}
            tooltip="Name of business, web site, or other description of the subscription."
            maxLength="255"
          />

          <Input
            key="quantity"
            type="number"
            labelText="Quantity"
            id="subscription_quantity"
            name="subscription[quantity]"
            value={this.state.form[`subscription[quantity]`] || ''}
            onChange={this.handleInputChange}
            formError={formErrors[`subscription.quantity`]}
            min="1"
          />

          <div className="vp-CancelDateContainer">
            <Input
              key="ended_at"
              type="date"
              labelText="Cancel date"
              id="subscription_ended_at"
              name="subscription[ended_at]"
              value={this.state.form[`subscription[ended_at]`] || ''}
              onChange={this.handleInputChange}
              formError={formErrors[`subscription.ended_at`]}
              max={getTodaysDate()}
            />

            <Button btnStyle="hollow" onClick={this.handleToday}>
              Today
            </Button>
          </div>

          <Button type="submit" disabled={this.state.submitDisabled}>
            Create
          </Button>

          {this.props.baseData && (
            <LinkButton
              to={this.props.from}
              href={this.props.from}
            >
              Cancel
            </LinkButton>
          )}
        </Form>
      </React.Fragment>
    )
  }
}

SubscriptionCreateForm.propTypes = {
  baseData: PropTypes.shape({
    account_id: PropTypes.string,
    vendor_id: PropTypes.string,
    parent_oli_id: PropTypes.string
  }),
  handleNewSubscription: PropTypes.func.isRequired,
  from: PropTypes.string
}

SubscriptionCreateForm.defaultProps = {
  baseData: undefined,
  from: undefined
}

export default SubscriptionCreateForm
