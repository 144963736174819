import React from 'react'
import PropTypes from 'prop-types'

import HttpError from 'helpers/errors'
import permissionHelper from 'helpers/permissions-helpers'
import PROP_TYPES from '../../../prop-types'
import Page from '../../common/Page'
import withSubscriptionGet from '../shared/with-subscription-get'
import SubscriptionEditForm from './SubscriptionEditForm'
import Loading from '../../common/Loading'

export const SubscriptionEdit = ({ data }) => {
  const { subscription } = data
  if (!subscription) return <Loading />

  let error
  if (!permissionHelper.canEditSubscriptions()) {
    error = new HttpError(404)
  }

  if (error) throw error

  return (
    <Page title={`Edit ${subscription.product_name} - ${subscription.oli_number}`} showBack>
      <SubscriptionEditForm subscription={subscription} />
    </Page>
  )
}

SubscriptionEdit.propTypes = {
  data: PropTypes.shape({ subscription: PROP_TYPES.SUBSCRIPTION }).isRequired
}

export default withSubscriptionGet(SubscriptionEdit)
