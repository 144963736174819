import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Page from 'components/common/Page'
import PROP_TYPES from '../../../prop-types'
import userAddPermissionGet from '../shared/user-add-permission-get'
import { Form, Select } from '../../common/Form'
import Button from '../../common/Button'
import { userHaveDefaultAccess } from '../UserAccountPermissions'

const defaultRoles = {
  client_manager: true,
  location_manager: false
}

const ROLES_TOOLTIP = 'Multiple options can be selected.'

const sameClientAndLocation = (newPermission, basePermission) => (
  newPermission.client_id !== basePermission.client_id ||
    newPermission.location_id !== basePermission.location_id
)

const selectedRoles = Object.keys(defaultRoles).filter(k => defaultRoles[k])
const composeRoles = (rolesObject) => {
  if (Object.keys(rolesObject).length < 1) {
    return null
  }
  return Object.keys(rolesObject).filter(k => rolesObject[k])
}
const composePermission = (client, rolesObject) => ({
  client_id: client.toString(),
  location_id: '-1',
  roles: composeRoles(rolesObject)
})

export const UserAddPermission = ({
  permissions, clients, locations, getLocations, history
}) => {
  const [client, setClient] = useState('-1')
  const [roles, setRoles] = useState(defaultRoles)

  const clientUpdate = ({ target }) => {
    const { value } = target
    setClient(value)
    getLocations(target.value)
  }

  const rolesUpdate = ({ target }) => {
    const selected = Array.from(target.selectedOptions)
    const newRoles = selected.reduce((acc, cur) => ({ [cur.value]: true, ...acc }), {})
    setRoles(newRoles)
  }

  const save = (e) => {
    e.preventDefault()
    let newPermissions = permissions
    const permission = composePermission(client, roles)
    if (userHaveDefaultAccess(newPermissions)) {
      newPermissions = []
    }
    newPermissions = newPermissions.filter(p => sameClientAndLocation(p, permission))
    newPermissions.push(permission)
    const pathname = history.location.pathname.replace('add', 'edit')
    history.replace(pathname, { permissions: newPermissions, updated: true })
    history.goBack()
  }

  return (
    <Page title="Add New Permission" showBack>
      <Form onSubmit={save}>
        <Select
          id="select__client"
          options={clients}
          name="client_id"
          labelText="Client"
          defaultValue={client}
          onChange={clientUpdate}
        />
        <Select
          id="select__role"
          options={[
            { display: 'Client Manager', value: 'client_manager' }
          ]}
          name="roles"
          labelText="Role"
          defaultValue={selectedRoles}
          onChange={rolesUpdate}
          tooltip={ROLES_TOOLTIP}
          multiple
        />

        <Button type="submit">
          Save
        </Button>
      </Form>
    </Page>
  )
}

const propTypes = {
  permissions: PROP_TYPES.ACCOUNT_PERMISSIONS,
  clients: PROP_TYPES.SELECT_OPTIONS_ARRAY,
  locations: PROP_TYPES.SELECT_OPTIONS_ARRAY,
  getLocations: PropTypes.func.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func,
    goBack: PropTypes.func,
    location: PropTypes.shape({
      pathname: PropTypes.string
    })
  })
}

UserAddPermission.propTypes = propTypes
UserAddPermission.defaultProps = {
  permissions: undefined,
  clients: undefined,
  locations: undefined,
  history: undefined
}

export default userAddPermissionGet(UserAddPermission)
