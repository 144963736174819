import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@vivialplatform/ui-shared-lib'

import Link from '../Link'
import Button from '../Button'
import ExternalLink from '../ExternalLink'

import './Card.scss'

const Card = ({
  title, titleLink, children, collapseButton, externalLink, collapsed, ...rest
}) => (
  <div {...rest} className="vp-Card">
    <div className="vp-Card__title">
      {title}
      {titleLink && (
        <Link id={titleLink.id} to={titleLink.to} href={titleLink.href}>
          <span>{titleLink.text}</span>
          {titleLink && (
            <span className="vp-Card__icon">
              <Icon icon={['fal', `${titleLink.icon}`]} />
            </span>
          )}
        </Link>
      )}
      {externalLink && (
        <ExternalLink
          id={externalLink.id}
          href={externalLink.href}
          className="vp-Card__external-link"
        >
          <span>{externalLink.text}</span>
          {externalLink.icon && (
            <span className="vp-Card__icon">
              <Icon icon={['fal', `${externalLink.icon}`]} />
            </span>
          )}
        </ExternalLink>
      )}
      {collapseButton && (
        <Button
          key={collapseButton.text}
          onClick={() => collapseButton.clickHandler()}
          {...collapseButton.props}
        >
          {collapseButton.text}
          {collapseButton.icon && (
            <span className="vp-Card__icon">
              <Icon icon={['fal', `${collapseButton.icon}`]} />
            </span>
          )}
        </Button>
      )}
    </div>
    <div className="vp-Card__body" style={collapsed ? { maxHeight: 0, padding: 0 } : undefined}>
      {children}
    </div>
  </div>
)

Card.defaultProps = {
  titleLink: undefined,
  collapseButton: undefined,
  collapsed: false,
  externalLink: undefined
}

Card.propTypes = {
  title: PropTypes.string.isRequired,
  titleLink: PropTypes.shape({
    to: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        pathname: PropTypes.string,
        state: PropTypes.object
      })
    ]),
    href: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string
  }),
  collapseButton: PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.string,
    props: PropTypes.object,
    clickHandler: PropTypes.func
  }),
  collapsed: PropTypes.bool,
  externalLink: PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string
  }),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)])
    .isRequired
}

export default Card
