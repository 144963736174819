import React from 'react'
import PropTypes from 'prop-types'
import Card from 'components/common/Card'
import Address from 'components/common/Address'
import DataRow from 'components/common/DataRow'
import { defaultColors } from '../../../../helpers/theme-helpers'
import 'components/location/LocationView/LocationContactCard/LocationContactCard.scss'

const LABEL_COLOR = {
  color: defaultColors.darkGray
}

const LocationContactCard = ({ object, title }) => {
  const getLatLon = () => {
    if (object.latitude && object.longitude) {
      return (
        <p className="vp-LocationContactCard__details-content-lat-lon">
          {object.latitude}
,
          {object.longitude}
        </p>
      )
    }

    return <p className="vp-LocationContactCard__details-content-lat-lon">—</p>
  }

  return (
    <div id="vp-LocationContactCard">
      <Card title={title}>
        <div className="vp-LocationContactCard__details">
          <div className="vp-LocationContactCard__details-container">
            <div className="vp-LocationContactCard__contact-content">
              <DataRow property="phone" title="Phone" value={object.phone} />

              <DataRow property="email" title="Email" value={object.email} />

              <DataRow property="website_url" title="Website" value={object.website_url} />

              <DataRow property="hours_notes" title="Hours Notes" value={object.hours_notes} />
            </div>

            <div className="vp-LocationContactCard__contact-content">
              <p className="vp-LocationContactCard__details-content-label" style={LABEL_COLOR}>
              Address
              </p>
              <Address
                street1={object.address_street1}
                street2={object.address_street2}
                city={object.address_city}
                state={object.address_state}
                postalCode={object.address_postal_code}
                country={object.address_country}
              />

              <p className="vp-LocationContactCard__details-content-label" style={LABEL_COLOR}>
              Service Address
              </p>
              <Address
                street1={object.service_area_business_street1}
                city={object.service_area_business_city}
                state={object.service_area_business_state}
                postalCode={object.service_area_business_postal_code}
              />

              <p className="vp-LocationContactCard__details-content-label" style={LABEL_COLOR}>
              Latitude & Longitude
              </p>
              {getLatLon()}
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

LocationContactCard.defaultProps = {
  object: {
    service_area_business_zip: undefined,
    service_area_business_city: undefined,
    service_area_business_street: undefined,
    address_street2: undefined,
    hours_notes: undefined
  }
}
LocationContactCard.propTypes = {
  object: PropTypes.shape({
    longitude: PropTypes.number,
    latitude: PropTypes.number,
    service_area_business_zip: PropTypes.string,
    service_area_business_city: PropTypes.string,
    service_area_business_street: PropTypes.string,
    address_country: PropTypes.string.isRequired,
    address_postal_code: PropTypes.string.isRequired,
    address_state: PropTypes.string.isRequired,
    address_street2: PropTypes.string,
    address_street1: PropTypes.string.isRequired,
    hours_notes: PropTypes.string,
    hours: PropTypes.array.isRequired,
    website_url: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    phone: PropTypes.string.isRequired,
    address_city: PropTypes.string,
    service_area_business_street1: PropTypes.string,
    service_area_business_state: PropTypes.string,
    service_area_business_postal_code: PropTypes.string
  }),
  title: PropTypes.string.isRequired
}

export default LocationContactCard
