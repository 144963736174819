import { stringify } from 'query-string'
import requestHelpers from '../../../helpers/request-helpers'

const MAX_PRODUCTS_PAGE_SIZE = 1000

function getProduct (id) {
  const request = {
    method: 'GET',
    url: `/products/${id}`
  }
  return requestHelpers.makeRequest(request)
}

function getProducts (params, pageSize = 10) {
  const searchQuery = stringify(params)
  const request = {
    method: 'GET',
    url: `/products?${searchQuery}&page_size=${pageSize}`
  }
  return requestHelpers.makeRequest(request)
}

/**
 * Used to sort products by name
 */
function compareProducts (productA, productB) {
  const nameA = productA.name.toUpperCase()
  const nameB = productB.name.toUpperCase()

  if (nameA > nameB) return 1
  if (nameA < nameB) return -1
  return 0
}

async function getAllProducts (params) {
  const products = []
  let pageNumber = 1
  let newParams

  while (pageNumber) {
    newParams = { ...params, page_number: pageNumber }
    // eslint-disable-next-line no-await-in-loop
    const { data, next_page: nextPage } = await API.getProducts(newParams, MAX_PRODUCTS_PAGE_SIZE)
    products.push(...data)
    pageNumber = nextPage
  }

  return products.sort(compareProducts)
}

function getProductAppConfig (productId, pageSize = 50) {
  const request = {
    method: 'GET',
    url: `/products/${productId}/application-configs?page_size=${pageSize}`
  }
  return requestHelpers.makeRequest(request)
}

const API = {
  getProduct,
  getProducts,
  getAllProducts,
  getProductAppConfig,
  compareProducts
}

export default API
