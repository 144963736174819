import React from 'react'
import PropTypes from 'prop-types'

import Card from 'components/common/Card'
import Page from 'components/common/Page'
import Table from 'components/common/Table'
import Loading from 'components/common/Loading'
import withClientGet from 'components/client/shared/with-client-get'
import ClientDetailsCard from 'components/client/ClientView/ClientDetailsCard'
import permissionHelper from 'helpers/permissions-helpers'
import ClientConfigCard from './ClientConfigCard'
import SubscriptionCard from '../../account/AccountView/SubscriptionCard/index'
import 'components/client/ClientView/ClientView.scss'

const {
  REACT_APP_CLIENT_ADMIN_URL,
  REACT_APP_DASHBOARD_URL
} = process.env

const CLIENT_EXCLUDED_KEYS = ['locations', 'social_media']

function getLocationsHeaders () {
  const columns = [
    {
      name: 'ID',
      accessor: 'id',
      settings: {
        type: 'link',
        basePath: '/locations'
      }
    },
    {
      name: 'Name',
      accessor: 'name'
    },
    {
      name: 'Street',
      accessor: 'address_street1'
    },
    {
      name: 'City',
      accessor: 'address_city'
    },
    {
      name: 'State',
      accessor: 'address_state'
    }
  ]

  if (permissionHelper.canSeeCladminLink()) {
    columns.push(
      {
        name: 'Client Admin',
        settings: {
          type: 'linkIconLocation',
          basePath: `${REACT_APP_CLIENT_ADMIN_URL}`,
          locationsBasePath: 'locations',
          clientAccessor: 'client_id',
          accessor: 'id',
          icon: 'external-link',
          tooltip: 'View on Client Admin'
        }
      }
    )
  }

  if (permissionHelper.canSeeDashboardLink()) {
    columns.push(
      {
        name: 'Marketing Platform',
        settings: {
          type: 'linkIcon',
          basePath: `${REACT_APP_DASHBOARD_URL}/goto`,
          accessor: 'id',
          icon: 'external-link',
          tooltip: 'View on Marketing Platform'
        }
      }
    )
  }
  return columns
}

function cladminView (data) {
  return permissionHelper.canSeeCladminLink() ? {
    href: `${REACT_APP_CLIENT_ADMIN_URL}/${data.id}`,
    text: 'Client Admin',
    id: 'vp-CladminView'
  } : null
}

function dashboardView (data) {
  return permissionHelper.canSeeDashboardLink() ? {
    href: `${REACT_APP_DASHBOARD_URL}/goto/${data.id}`,
    text: 'Marketing Platform',
    id: 'vp-DashboardView'
  } : null
}

function getHeaderLinks (data) {
  return [cladminView(data), dashboardView(data)].filter(link => link)
}

function getClientDetails (data) {
  return (
    <ClientDetailsCard object={data} title="Client Details" excludedRows={CLIENT_EXCLUDED_KEYS} />
  )
}

function getClientLocations (data) {
  return (
    <Card title="Locations">
      {data.locations && data.locations.length > 0 ? (
        <Table rowKey="id" headers={getLocationsHeaders()} rows={data.locations} />
      ) : (
        <div className="vp-ClientView__details-container">
          <div className="vp-ClientView__details-content">
            <p>No locations</p>
          </div>
        </div>
      )}
    </Card>
  )
}

export const ClientView = ({
  data,
  loadMoreSubscriptions,
  loadDerivedConfig,
  location
}) => {
  const client = data

  if (!client.name) {
    return <Loading />
  }

  const createSubscriptionLinkParams = {
    account_id: client.account_id,
    vendor_id: client.id
  }

  return (
    <Page title={`${client.name}`} externalLinks={getHeaderLinks(client)}>
      <div id="vp-ClientView" className="vp-ClientView">
        <div id="vp-ClientView__ClientCard" className="vp-ClientView__details">
          {getClientDetails(client)}
        </div>

        <div id="vp-ClientView__ClientLocations" className="vp-ClientView__details">
          {getClientLocations(client)}
        </div>

        <div id="vp-ClientView__ClientLocations" className="vp-ClientView__details">
          <SubscriptionCard
            subscriptions={data.subscriptions.data}
            loadMoreSubscriptions={loadMoreSubscriptions}
            createSubscriptionLinkParams={createSubscriptionLinkParams}
            from={location.pathname}
            accountName={client.account_name}
          />
        </div>

        {permissionHelper.isAdmin() && (
          <div id="vp-ClientView__ClientConfigCard" className="vp-ClientView__details">
            <ClientConfigCard
              derivedConfig={client.derivedConfig}
              loadDerivedConfig={loadDerivedConfig}
            />
          </div>
        )}
      </div>
    </Page>
  )
}

const propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.integer,
    account_id: PropTypes.integer,
    keywords: PropTypes.array,
    subscriptions: PropTypes.object,
    derivedConfig: PropTypes.object
  }).isRequired,
  loadMoreSubscriptions: PropTypes.func,
  loadDerivedConfig: PropTypes.func,
  location: PropTypes.shape({
    pathname: PropTypes.string
  })
}

ClientView.propTypes = propTypes
ClientView.defaultProps = {
  loadMoreSubscriptions: undefined,
  loadDerivedConfig: undefined,
  location: {}
}

export default withClientGet(ClientView)
