import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Pagination from '../../../common/Pagination'
import Table from '../../../common/Table'
import Loading from '../../../common/Loading'
import './SearchTable.scss'

const HEADERS = [
  {
    name: 'ID',
    accessor: 'id',
    settings: {
      type: 'link',
      basePath: '/users'
    }
  },
  {
    name: 'Name',
    settings: {
      type: 'name',
      firstName: 'first_name',
      lastName: 'last_name'
    }
  },
  { name: 'Email', accessor: 'email' },
  {
    name: 'Type',
    accessor: 'type',
    settings: {
      type: 'affiliate'
    }
  },
  {
    name: 'Status',
    accessor: 'is_active',
    settings: {
      type: 'boolean'
    }
  }
]

const position = 'above'

class UserSearchTable extends Component {
  handlePagination = (newPage) => {
    this.props.inputChange('pageNumber')({ target: { value: newPage } })
  }

  getPagination = (value) => {
    const {
      userResults: { next_page: nextPage, previous_page: previousPage }
    } = this.props

    if (!nextPage && !previousPage) {
      return null
    }

    return (
      <Pagination
        nextPage={nextPage}
        previousPage={previousPage}
        onPageChange={this.handlePagination}
        position={value}
      />
    )
  }

  render () {
    const {
      searching,
      userResults: { data }
    } = this.props
    if (searching) {
      return (
        <div id="vp-UserSearchTable">
          <Loading />
        </div>
      )
    }
    if (!data || !data.length) {
      return null
    }
    return (
      <div id="vp-UserSearchTable" className="vp-UserSearchTable">
        {this.getPagination(position)}
        <Table rowKey="id" headers={HEADERS} rows={data} />
        {this.getPagination()}
      </div>
    )
  }
}

UserSearchTable.propTypes = {
  searching: PropTypes.bool.isRequired,
  inputChange: PropTypes.func.isRequired,
  userResults: PropTypes.shape({
    page_number: PropTypes.number,
    data: PropTypes.array,
    next_page: PropTypes.number,
    previous_page: PropTypes.number,
    position: PropTypes.string
  }).isRequired
}

export default UserSearchTable
