import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@vivialplatform/ui-shared-lib'

import Card from 'components/common/Card'
import Page from 'components/common/Page'
import Table from 'components/common/Table'
import ExternalLink from 'components/common/ExternalLink'
import Address from 'components/common/Address'
import Loading from 'components/common/Loading'
import Collapsible from 'components/common/Collapsible'
import Link from 'components/common/Link'
import permissionHelper from 'helpers/permissions-helpers'
import PROP_TYPES from '../../../prop-types'
import permHelper from '../../../helpers/permissions-helpers'
import { defaultColors } from '../../../helpers/theme-helpers'
import withAccountGet from '../shared/with-account-get'
import SubscriptionCard from './SubscriptionCard/index'
import 'components/account/AccountView/AccountView.scss'

const {
  REACT_APP_SALESFORCE_HOST,
  REACT_APP_DASHBOARD360_LOGIN_URL,
  REACT_APP_CLIENT_ADMIN_URL,
  REACT_APP_DASHBOARD_URL
} = process.env

const getUsersHeader = (accountId, history) => {
  const headers = [
    {
      name: 'ID',
      accessor: 'id',
      settings: {
        type: 'link',
        basePath: '/users'
      }
    },
    {
      name: 'First Name',
      accessor: 'first_name'
    },
    {
      name: 'Last Name',
      accessor: 'last_name'
    },
    {
      name: 'Email',
      accessor: 'email'
    }
  ]

  headers.push({
    name: 'Actions',
    accessor: 'id',
    settings: {
      type: 'actions',
      actions: [
        {
          text: 'Permissions',
          href: `/users/{id}/account-permissions/${accountId}`,
          clickHandler: history ? history.push : null,
          props: {
            btnStyle: 'clear',
            color: defaultColors.darkGray
          }
        }
      ]
    }
  })
  return headers
}

const getClientsHeaders = () => {
  const clientsHeaders = [
    {
      name: 'Client Name',
      accessor: 'name',
      settings: {
        type: 'link',
        basePath: '/clients',
        accessor: 'id'
      }
    },
    { name: 'Client ID', accessor: 'id' },
    { name: 'Account ID', accessor: 'account_id' },
    {
      name: 'Status',
      accessor: 'is_active',
      settings: {
        type: 'boolean'
      }
    }
  ]

  if (permissionHelper.canSeeCladminLink()) {
    clientsHeaders.push({
      name: 'Client Admin',
      settings: {
        type: 'linkIcon',
        basePath: `${REACT_APP_CLIENT_ADMIN_URL}`,
        accessor: 'id',
        icon: 'external-link',
        tooltip: 'View on Client Admin'
      }
    })
  }

  if (permissionHelper.canSeeDashboardLink()) {
    clientsHeaders.push({
      name: 'Marketing Platform',
      settings: {
        type: 'linkIcon',
        basePath: `${REACT_APP_DASHBOARD_URL}/goto`,
        accessor: 'id',
        icon: 'external-link',
        tooltip: 'View on Marketing Platform'
      }
    })
  }

  return clientsHeaders
}

const SF_CONTACTS_HEADERS = [
  {
    name: 'Name',
    accessor: 'name'
  },
  {
    name: 'Email',
    accessor: 'email'
  },
  {
    name: 'Phone',
    accessor: 'phone'
  },
  {
    name: 'Primary',
    accessor: 'is_primary_contact',
    settings: {
      type: 'boolean'
    }
  }
]

const CHILDREN_HEADERS = [
  {
    name: 'Account Number',
    accessor: 'child_account_number',
    settings: {
      type: 'linkAddAccount',
      function: () => {},
      accessor: 'child_account_id'
    }
  },
  {
    name: 'Account Name',
    accessor: 'child_account_name'
  }
]

const ACCOUNT_UPDATE_PERMS = { policies: ['account:update'] }
const USER_ADD_PERMS = { policies: ['account#user:create'] }
const CLIENT_ADD_PERMS = { policies: ['client:create'] }

const LABEL_COLOR = {
  color: defaultColors.darkGray
}

const SH_TOOLTIP = 'The flag indicates this account (company) deals with sensitive data'

function getDetailsDisplay (detail) {
  return detail || '—'
}

export const AccountView = ({
  data, location, history, loadMore
}) => {
  CHILDREN_HEADERS[0].settings.function = id => history.push(`/accounts/${id}`)

  const {
    account, users, contacts, children, clients, subscriptions
  } = data
  if (!account) {
    return <Loading />
  }

  const {
    sf_id: sfId,
    name,
    phone,
    address_street: street1,
    address_city: city,
    address_state: state,
    address_postal_code: postalCode,
    address_country: countryCode,
    website,
    owner_name: ownerName,
    talus_account_id: talusAccountId,
    account_number: accountNumber,
    enterprise_customer_id: enterpriseCustomerId,
    account_manager_name: accountManagerName,
    social_reputation_manager_name: socialReputationManagerName,
    is_sensitive_heading: isSensitiveHeading
  } = account.sf_account

  const address = {
    street1,
    city,
    state,
    postalCode,
    countryCode
  }

  const actionButtons = []

  if (talusAccountId) {
    actionButtons.push({
      href: `${REACT_APP_DASHBOARD360_LOGIN_URL}?account_id=${account.id}`,
      text: 'Vivial360',
      icon: '',
      id: 'dashboard360-account'
    })
  }

  const USERS_HEADERS = getUsersHeader(account.id, history)
  const isSensitiveHeadingIcon = isSensitiveHeading ? 'check-square' : 'square'
  const userAddPath = `/accounts/${account.id}/users/new`

  const clientAddPath = `${REACT_APP_CLIENT_ADMIN_URL}/create/${account.id}`

  const editPath = `/accounts/${account.id}/edit`
  const notice = location && location.state ? location.state.notice : undefined
  let titleLink
  let userAddTitleLink
  let clientAddExternalLink
  if (permHelper.hasPerm(ACCOUNT_UPDATE_PERMS)) {
    titleLink = {
      to: editPath,
      href: editPath,
      text: '',
      icon: 'pencil',
      id: 'vp-EditAccount'
    }
  }

  if (permHelper.hasPerm(USER_ADD_PERMS)) {
    userAddTitleLink = {
      to: { pathname: userAddPath, state: { accountName: account.sf_account.name } },
      href: userAddPath,
      text: 'Attach',
      icon: 'plus',
      id: 'vp-AttachUser'
    }
  }

  if (permHelper.hasPerm(CLIENT_ADD_PERMS)) {
    clientAddExternalLink = {
      href: clientAddPath,
      text: 'Create',
      icon: 'plus',
      id: 'vp-AttachClient'
    }
  }

  return (
    <Page title={`${account.sf_account.name}`} notice={notice} externalLinks={actionButtons}>
      <div id="vp-AccountView" className="vp-AccountView">
        <div id="vp-AccountView__sfAccountDetails" className="vp-AccountView__details">
          <Card title="Account Details">
            <div className="vp-AccountView__details-container">
              <div className="vp-AccountView__details-content">
                <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                  Account Name
                </p>
                <p>{getDetailsDisplay(name)}</p>
                <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                  Phone
                </p>
                <p>{getDetailsDisplay(phone)}</p>
                <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                  Address
                </p>
                <Address {...address} />
                <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                  Website
                </p>
                <p>{getDetailsDisplay(website)}</p>
                <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                  Owner (Sales Rep)
                </p>
                <p>{getDetailsDisplay(ownerName)}</p>
                {account.parent_account_id && (
                  <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                    Parent Account
                  </p>
                )}
                {account.parent_account_id && (
                  <Link
                    id="parentAccount"
                    className="parentAccount"
                    to={`/accounts/${account.parent_account_id}`}
                    href={`/accounts/${account.parent_account_id}`}
                  >
                    <span>{account.parent_account_name}</span>
                  </Link>
                )}
              </div>
              <div className="vp-AccountView__details-content">
                <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                  Partner ID
                  {titleLink && (
                    <>
                      <Link id={titleLink.id} to={titleLink.to} href={titleLink.id}>
                        <span className="vp-AccountView__icon">
                          <Icon icon={['fal', `${titleLink.icon}`]} />
                        </span>
                      </Link>
                    </>
                  )}
                </p>
                <p>{getDetailsDisplay(account.affiliate_id)}</p>
                <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                  Account Number
                </p>
                <p>{getDetailsDisplay(accountNumber)}</p>
                <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                  Enterprise Customer ID
                </p>
                <p>{getDetailsDisplay(enterpriseCustomerId)}</p>
                <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                  Account Coordinator
                </p>
                <p>{getDetailsDisplay(accountManagerName)}</p>
                <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                  Social Media Manager
                </p>
                <p>{getDetailsDisplay(socialReputationManagerName)}</p>
                <p
                  className="vp-AccountView__details-content-label sensitive-heading"
                  style={LABEL_COLOR}
                >
                  <span className="vp-AccountView__icon">
                    <Icon icon={['fal', `${isSensitiveHeadingIcon}`]} />
                  </span>
                  Sensitive Heading
                  <span title={SH_TOOLTIP} className="vp-AccountView__icon">
                    <Icon icon={['fal', 'info-circle']} />
                  </span>
                </p>
                <Collapsible buttonText="Other Ids">
                  <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                    Account Id
                  </p>
                  <p>{account.id}</p>
                  <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                    Talus Account Id
                  </p>
                  <p>{getDetailsDisplay(talusAccountId)}</p>
                  <p className="vp-AccountView__details-content-label" style={LABEL_COLOR}>
                    Salesforce Id
                  </p>
                  <ExternalLink
                    target="_blank"
                    rel="noopener noreferrer"
                    href={`${REACT_APP_SALESFORCE_HOST}${sfId}`}
                  >
                    {getDetailsDisplay(sfId)}
                  </ExternalLink>
                </Collapsible>
              </div>
            </div>
          </Card>
        </div>

        <div id="vp-AccountView__subscriptions" className="vp-AccountView__details">
          <SubscriptionCard
            subscriptions={subscriptions}
            loadMoreSubscriptions={loadMore}
            createSubscriptionLinkParams={{ account_id: account.id }}
            from={location.pathname}
            accountName={account.sf_account.name}
          />
        </div>

        {children && children.length > 0 && (
          <div id="vp-AccountView__ChildAccount" className="vp-AccountView__details">
            <Card title="Child Accounts">
              <Table rowKey="child_account_id" headers={CHILDREN_HEADERS} rows={children} />
            </Card>
          </div>
        )}

        <div id="vp-AccountView__users" className="vp-AccountView__details">
          <Card title="Users" titleLink={userAddTitleLink}>
            {users && users.length > 0 ? (
              <Table rowKey="id" headers={USERS_HEADERS} rows={users} />
            ) : (
              <div className="vp-AccountView__details-container">
                <div className="vp-AccountView__details-content">
                  <p>No users</p>
                </div>
              </div>
            )}
          </Card>
        </div>

        <div id="vp-AccountView__clients" className="vp-AccountView__details">
          <Card title="Clients" externalLink={clientAddExternalLink}>
            {clients && clients.length > 0 ? (
              <Table rowKey="id" headers={getClientsHeaders()} rows={clients} />
            ) : (
              <div className="vp-AccountView__details-container">
                <div className="vp-AccountView__details-content">
                  <p>No clients</p>
                </div>
              </div>
            )}
          </Card>
        </div>

        <div id="vp-AccountView__sfContacts" className="vp-AccountView__details">
          <Card title="Salesforce Contacts">
            {contacts && contacts.length > 0 ? (
              <Table rowKey="name" headers={SF_CONTACTS_HEADERS} rows={contacts} />
            ) : (
              <div className="vp-AccountView__details-container">
                <div className="vp-AccountView__details-content">
                  <p>No contacts</p>
                </div>
              </div>
            )}
          </Card>
        </div>
      </div>
    </Page>
  )
}

const propTypes = {
  data: PropTypes.shape({
    users: PropTypes.arrayOf(PROP_TYPES.USER),
    contacts: PropTypes.arrayOf(PROP_TYPES.CONTACT),
    account: PROP_TYPES.ACCOUNT,
    children: PropTypes.arrayOf(PropTypes.object),
    clients: PropTypes.arrayOf(PropTypes.object),
    subscriptions: PropTypes.arrayOf(PropTypes.object)
  }).isRequired,
  location: PROP_TYPES.STATE_WITH_NOTICE,
  history: PropTypes.shape({
    push: PropTypes.func
  }),
  loadMore: PropTypes.func
}

AccountView.propTypes = propTypes
AccountView.defaultProps = { location: undefined, history: undefined, loadMore: undefined }

export default withAccountGet(AccountView)
