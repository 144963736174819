import requestHelpers from './request-helpers'

const getClientLocations = (clientId) => {
  const request = {
    method: 'GET',
    url: `/clients/${clientId}/locations`
  }
  return requestHelpers.makeRequest(request)
}

export default getClientLocations
