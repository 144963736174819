import React from 'react'

import Page from '../../common/Page'
import Search from '../../common/Search'
import permHelper from '../../../helpers/permissions-helpers'
import HttpError from '../../../helpers/errors'
import './ProductSearch.scss'

const HEADERS = [
  { name: 'UDAC', accessor: 'udac' },
  {
    name: 'Product Name',
    accessor: 'name',
    settings: {
      type: 'link',
      basePath: '/products',
      accessor: 'id'
    }
  }
]

const ProductSearch = () => {
  if (!permHelper.hasPerm({ policies: ['product:read'] })) {
    throw new HttpError(403)
  }

  return (
    <Page title="Products">
      <div className="vp-ProductSearch">
        <Search
          searchKey="search"
          apiUrl="/products"
          placeholder="Search by product name, ID, or UDAC"
          headers={HEADERS}
          rowKey="id"
          inputId="search"
        />
      </div>
    </Page>
  )
}

export default ProductSearch
