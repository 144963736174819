import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import hoverable from '../shared/Hoverable'
import { getHoverableButtonStyle } from '../shared/Hoverable/helpers'

import './LinkButton.scss'

const HoverableLink = hoverable(Link)

const LinkButton = ({
  children, color, bgColor, btnStyle, ...rest
}) => (
  <HoverableLink
    {...rest}
    style={getHoverableButtonStyle({ color, bgColor, btnStyle })}
    className="vp-LinkButton"
  >
    {children}
  </HoverableLink>
)
LinkButton.defaultProps = { color: undefined, bgColor: undefined, btnStyle: 'default' }
LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  btnStyle: PropTypes.oneOf(['default', 'hollow', 'clear'])
}

export default LinkButton
