import React from 'react'
import PropTypes from 'prop-types'
import PROP_TYPES from '../../../../prop-types'
import Table from '../../../common/Table'
import Card from '../../../common/Card'
import collapsible from '../../../common/shared/Collapsible'

const AUDITS_HEADERS = [
  {
    name: 'Date',
    accessor: 'created_at',
    settings: {
      type: 'datetime'
    }
  },
  {
    name: 'Event',
    accessor: 'event_name'
  },
  {
    name: 'From',
    accessor: 'performed_by'
  },
  {
    name: 'IP',
    accessor: 'ip'
  }
]

const getTitleCollapseButton = (collapseHandlerAction, collapsed) => ({
  icon: collapsed ? 'chevron-down' : 'chevron-up',
  clickHandler: collapseHandlerAction,
  props: {
    btnStyle: 'clear'
  }
})

const CollapsibleCard = collapsible(Card)

const AuditsCard = ({ auditLogs, loadMoreAuditLogs }) => (
  <CollapsibleCard title="Audit Logs" getCollapseButton={getTitleCollapseButton} initiateCollapsed>
    {auditLogs && auditLogs.length ? (
      <Table
        rowKey="id"
        headers={AUDITS_HEADERS}
        rows={auditLogs}
        rowContent="details"
        loadMoreRows={loadMoreAuditLogs}
      />
    ) : (
      <div className="vp-UserView__details-container">
        <div className="vp-UserView__details-content">
          <p>No audit logs</p>
        </div>
      </div>
    )}
  </CollapsibleCard>
)

AuditsCard.defaultProps = {
  auditLogs: [],
  loadMoreAuditLogs: undefined
}

AuditsCard.propTypes = {
  auditLogs: PropTypes.arrayOf(PROP_TYPES.AUDIT_LOG),
  loadMoreAuditLogs: PropTypes.func
}

export default AuditsCard
