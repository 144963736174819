import React from 'react'
import getClientLocations from 'helpers/component-request'
import PROP_TYPES from '../../../prop-types'
import { getClient, getClientDerivedConfig } from './client-helpers'
import { getAccount, getSubscriptions } from '../../account/shared/account-helpers'

const subscriptionsPageSize = 100

function withClientGet (WrappedComponent) {
  class WithClientGet extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        data: {},
        error: undefined,
        moreSubscriptions: false
      }
      this.loadMoreSubscriptions = this.loadMoreSubscriptions.bind(this)
      this.refresh = this.refresh.bind(this)
    }

    async componentDidMount () {
      this._mounted = true
      await this.getClient(this.props.match.params.id)
    }

    componentWillUnmount () {
      // Note: necessary so setState() isn't called in componentDidMount() fetch promise chain
      // if component becomes unmounted
      this._mounted = false
    }

    getClient = async (clientId) => {
      try {
        const clientDataRequests = [
          getClientLocations(clientId),
          getClient(clientId)
        ]
        const [locations, client] = await Promise.all(clientDataRequests)
        const accountId = client.account_id

        const additionalDataRequest = [
          getAccount(accountId, false, false),
          getSubscriptions({
            client_id: clientId, account_id: accountId
          }, subscriptionsPageSize)
        ]
        const [{ account }, subscriptions] = await Promise.all(additionalDataRequest)

        const data = {
          ...client,
          locations,
          account_name: account.sf_account.name,
          subscriptions
        }

        const moreSubscriptions =
          subscriptions.next_page && subscriptions.page_size + Number(subscriptionsPageSize) <= 1000

        this._mounted && this.setState({ data, moreSubscriptions })
      } catch (error) {
        this._mounted && this.setState({ error })
      }
    }

    loadMoreSubscriptions = async () => {
      const { id: clientId, account_id: accountId } = this.state.data
      const newPageSize = this.state.data.subscriptions.data.length + Number(subscriptionsPageSize)
      if (newPageSize < 1000) {
        try {
          const subscription = await getSubscriptions({
            client_id: clientId, account_id: accountId
          }, newPageSize)
          const data = {
            ...this.state.data,
            subscriptions: subscription
          }
          if (this._mounted) {
            this.setState({ data, moreSubscriptions: !!subscription.next_page })
          }
        } catch (error) {
          if (this._mounted) {
            this.setState({ error })
          }
        }
      }
    }

    loadDerivedConfig = async () => {
      const { id } = this.state.data

      try {
        const derivedConfig = await getClientDerivedConfig(id)

        const data = {
          ...this.state.data,
          derivedConfig
        }
        if (this._mounted) {
          this.setState({ data })
        }
      } catch (error) {
        if (this._mounted) {
          this.setState({ error })
        }
      }
    }

    async refresh () {
      try {
        await getClient(this.props.match.params.id).then(
          data => this._mounted && this.setState({ data }),
          error => this._mounted && this.setState({ error })
        )
      } catch (error) {
        this.setState({ error })
      }
    }

    render () {
      if (this.state.error) throw this.state.error

      return (
        <WrappedComponent
          data={this.state.data}
          refresh={this.refresh}
          loadMoreSubscriptions={
            this.state.moreSubscriptions ? this.loadMoreSubscriptions : undefined
          }
          loadDerivedConfig={this.loadDerivedConfig}
          {...this.props}
        />
      )
    }
  }
  WithClientGet.displayName = `WithClientGet(${getDisplayName(WrappedComponent)})`
  WithClientGet.propTypes = {
    match: PROP_TYPES.ID_IN_PATH.isRequired
  }

  return WithClientGet
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withClientGet
