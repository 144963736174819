import React from 'react'
import PropTypes from 'prop-types'

import theme, { darken } from '../../../helpers/theme-helpers'
import hoverable from '../shared/Hoverable'
import Anchor from './Anchor'
import './ExternalLink.scss'

const HoverableAnchor = hoverable(Anchor)

const transition = 'color 0.25s ease-out'
export const defaultStyle = {
  base: {
    color: theme.colors.primary,
    transition
  },
  hover: {
    color: darken(theme.colors.primary),
    transition
  }
}

const getStyle = (color) => {
  if (!color) return defaultStyle
  return {
    base: { color, transition },
    hover: { color: darken(color), transition }
  }
}

const ExternalLink = ({ children, color, ...rest }) => (
  <HoverableAnchor
    {...rest}
    style={getStyle(color)}
    className="vp-ExternalLink"
    target="_blank"
    rel="noopener noreferrer"
  >
    {children}
  </HoverableAnchor>
)

ExternalLink.defaultProps = { color: undefined, children: undefined }
ExternalLink.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string
}

export default ExternalLink
