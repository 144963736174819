import React from 'react'
import PropTypes from 'prop-types'

import PROP_TYPES from '../../../prop-types'
import Page from '../../common/Page'
import withUserGet from '../shared/with-user-get'
import UserEditForm from './UserEditForm'
import Loading from '../../common/Loading'

export const UserEdit = ({ data }) => {
  const { user } = data
  if (!user) return <Loading />

  return (
    <Page title="Edit User" showBack>
      <UserEditForm user={user} />
    </Page>
  )
}

UserEdit.propTypes = {
  data: PropTypes.shape({ user: PROP_TYPES.USER }).isRequired
}

export default withUserGet(UserEdit)
