import React from 'react'
import PropTypes from 'prop-types'

import Collapsible from 'components/common/Collapsible'
import DataRow from 'components/common/DataRow'
import requestHelper from 'helpers/request-helpers'
import Card from '../../common/Card'
import Button from '../../common/Button'
import Page from '../../common/Page'
import Loading from '../../common/Loading'
import Modal from '../../common/Modal'
import PROP_TYPES from '../../../prop-types'
import withSubscriptionAndChildrenGet from '../shared/with-subscription-and-children-get'
import permissionHelper from '../../../helpers/permissions-helpers'
import { defaultColors } from '../../../helpers/theme-helpers'
import SubscriptionCard from '../../account/AccountView/SubscriptionCard/index'
import { isParentSubscription, isChildSubscription } from '../shared/subscription-helpers'
import './SubscriptionView.scss'

const MODAL_DEACTIVATE = 'deactivate'
const MODAL_REACTIVATE = 'reactivate'
const MODAL_MARK_AS_IGNORED = 'mark-as-ignored'
const MODAL_UNMARK_AS_IGNORED = 'unmark-as-ignored'

export class SubscriptionView extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      openModal: null,
      loading: false
    }

    this.toggleModal = this.toggleModal.bind(this)
    this.toggleReactivateModal = this.toggleModal.bind(this, MODAL_REACTIVATE)
    this.toggleDeactivateModal = this.toggleModal.bind(this, MODAL_DEACTIVATE)
    this.toggleMarkAsIgnoredModal = this.toggleModal.bind(this, MODAL_MARK_AS_IGNORED)
    this.toggleUnmarkAsIgnoredModal = this.toggleModal.bind(this, MODAL_UNMARK_AS_IGNORED)

    this.handleReactivateModalConfirm = this.handleReactivateModalConfirm.bind(this)
    this.handleDeactivateModalConfirm = this.handleDeactivateModalConfirm.bind(this)
    this.handleMarkAsIgnoredModalConfirm = this.handleMarkAsIgnoredModalConfirm.bind(this)
    this.handleUnmarkAsIgnoredModalConfirm = this.handleUnmarkAsIgnoredModalConfirm.bind(this)
  }

  async componentDidMount () {
    this._mounted = true
  }

  componentWillUnmount () {
    // Note: necessary so setState() isn't called in
    // componentDidMount() fetch promise chain if
    // component becomes unmounted
    this._mounted = false
  }

  async handleDeactivateModalConfirm () {
    try {
      await this._handleModalConfirm({
        request: {
          url: `/subscriptions/${this.props.data.subscription.id}/deactivate`
        },
        messageOnSuccess: 'Successfully deactivated subscription.',
        refreshOnSuccess: true
      })
    } catch (error) {
      this.setState({ error })
    }
  }

  async handleReactivateModalConfirm () {
    try {
      await this._handleModalConfirm({
        request: {
          url: `/subscriptions/${this.props.data.subscription.id}/activate`
        },
        messageOnSuccess: 'Successfully reactivated subscription.',
        refreshOnSuccess: true
      })
    } catch (error) {
      this.setState({ error })
    }
  }

  async handleMarkAsIgnoredModalConfirm () {
    try {
      await this._handleModalConfirm({
        request: {
          url: `/subscriptions/${this.props.data.subscription.id}/mark-as-ignored`
        },
        messageOnSuccess: 'Successfully marked subscription as ignored.',
        refreshOnSuccess: true
      })
    } catch (error) {
      this.setState({ error })
    }
  }

  async handleUnmarkAsIgnoredModalConfirm () {
    try {
      await this._handleModalConfirm({
        request: {
          url: `/subscriptions/${this.props.data.subscription.id}/unmark-as-ignored`
        },
        messageOnSuccess: 'Successfully unmarked subscription as ignored.',
        refreshOnSuccess: true
      })
    } catch (error) {
      this.setState({ error })
    }
  }

  async _handleModalConfirm ({ request, refreshOnSuccess, messageOnSuccess }) {
    this.setState({ loading: true })
    this.toggleModal()

    let response
    try {
      response = await requestHelper.makeRequest({
        method: 'POST',
        toJson: false,
        ...request
      })
    } catch (error) {
      this.setState({ error })
      return false
    }

    if (response.status === 204) {
      this.setState({
        loading: false,
        notice: {
          message: messageOnSuccess,
          type: 'success'
        }
      })
      if (refreshOnSuccess) {
        try {
          await this.props.refresh()
        } catch (error) {
          this.setState({ error })
        }
      }
      return true
    }

    let validationErrors
    try {
      validationErrors = await response.json()
    } catch (error) {
      this.setState({ error })
      return false
    }

    const [{ message }] = validationErrors.errors
    this.setState({ loading: false, notice: { message, type: 'error' } })
    return false
  }

  toggleModal (modal) {
    this.setState({
      loading: false,
      openModal: modal
    })
  }

  render () {
    if (this.state.error) throw this.state.error

    const {
      data,
      loadMoreSubscriptions,
      location
    } = this.props

    const { subscription, account_name: accountName, childSubscriptions } = data

    const { loading, openModal } = this.state

    if (!subscription) return <Loading />

    const notice = this.state.notice ||
      (location && location.state ? location.state.notice : undefined)

    const rootPath = `/subscriptions/${subscription.id}`

    const editSubscriptionPath = `${rootPath}/edit`

    let editLink
    const actionButtons = []

    if (permissionHelper.canEditSubscriptions()) {
      actionButtons.push(
        (subscription.is_ignored) ? (
          <Button
            key="unmark-as-ignored"
            btnStyle="hollow"
            onClick={this.toggleUnmarkAsIgnoredModal}
            disabled={loading}
          >
            Unmark as Ignored
          </Button>
        ) : (
          <Button
            key="mark-as-ignored"
            btnStyle="hollow"
            color={defaultColors.alert}
            onClick={this.toggleMarkAsIgnoredModal}
            disabled={loading}
          >
            Mark as Ignored
          </Button>
        )
      )

      if (subscription.is_manual) {
        editLink = {
          to: editSubscriptionPath,
          href: editSubscriptionPath,
          text: 'Edit',
          icon: 'edit',
          id: 'vp-EditSubscription'
        }
        actionButtons.push(
          (subscription.ended_at === null) ? (
            <Button
              key="deactivate-subscription"
              btnStyle="hollow"
              color={defaultColors.alert}
              onClick={this.toggleDeactivateModal}
              disabled={loading}
            >
              Deactivate
            </Button>
          ) : (
            <Button
              key="activate-subscription"
              btnStyle="hollow"
              onClick={this.toggleReactivateModal}
              disabled={loading}
            >
              Reactivate
            </Button>
          )
        )
      }
    }

    const createSubscriptionLinkParams = {
      account_id: subscription.account_id,
      vendor_id: subscription.vendor_id,
      parent_oli_id: subscription.oli_id
    }

    let title = `${subscription.product_name} - ${subscription.oli_number}`

    if (subscription.ended_at !== null) {
      title += ' - [Canceled]'
    }

    return (
      <Page title={title} notice={notice}>
        {loading && <Loading />}
        <Modal
          isOpen={openModal === MODAL_DEACTIVATE}
          title="Deactivate"
          onRequestClose={this.toggleModal}
        >
          <p>Are you sure you want to deactivate this subscription?</p>
          <Button onClick={this.handleDeactivateModalConfirm}>Confirm</Button>
          <Button btnStyle="clear" onClick={this.toggleModal}>Cancel</Button>
        </Modal>
        <Modal
          isOpen={openModal === MODAL_REACTIVATE}
          title="Reactivate"
          onRequestClose={this.toggleModal}
        >
          <p>Are you sure you want to reactivate this subscription?</p>
          <Button onClick={this.handleReactivateModalConfirm}>Confirm</Button>
          <Button btnStyle="clear" onClick={this.toggleModal}>Cancel</Button>
        </Modal>
        <Modal
          isOpen={openModal === MODAL_MARK_AS_IGNORED}
          title="Mark subscription as ignored"
          onRequestClose={this.toggleModal}
        >
          <p>Are you sure you want to mark this subscription as ignored?</p>
          <Button onClick={this.handleMarkAsIgnoredModalConfirm}>Confirm</Button>
          <Button btnStyle="clear" onClick={this.toggleModal}>Cancel</Button>
        </Modal>
        <Modal
          isOpen={openModal === MODAL_UNMARK_AS_IGNORED}
          title="Unmark subscription as ignored"
          onRequestClose={this.toggleModal}
        >
          <p>Are you sure you want to unmark this subscription as ignored?</p>
          <Button onClick={this.handleUnmarkAsIgnoredModalConfirm}>Confirm</Button>
          <Button btnStyle="clear" onClick={this.toggleModal}>Cancel</Button>
        </Modal>
        <div id="vp-SubscriptionView" className="vp-SubscriptionView">
          {actionButtons.length > 0 && (
            <div id="vp-SubscriptionView__actions" className="vp-SubscriptionView__actions">
              <div
                id="vp-SubscriptionView__actions-buttons"
                className="vp-SubscriptionView__actions-buttons"
              >
                {actionButtons}
              </div>
            </div>
          )}
          <div
            id="vp-SubscriptionView__SubscriptionDetails"
            className="vp-SubscriptionView__details"
          >
            <Card title="Subscription Details" titleLink={editLink}>
              <div className="vp-SubscriptionView__details-container">
                <div className="vp-SubscriptionView__details-content">
                  <DataRow property="name" title="Name" value={subscription.name} />
                  <DataRow
                    property="oli_number"
                    title="OLI Number"
                    value={subscription.oli_number}
                  />
                  <DataRow property="yes/no" title="Manual" value={subscription.is_manual} />
                  <DataRow property="quantity" title="Quantity" value={subscription.quantity} />
                  <DataRow property="vendor_id" title="Vendor ID" value={subscription.vendor_id} />
                  <DataRow property="yes/no" title="Ignored" value={subscription.is_ignored} />
                  {isChildSubscription(subscription) && (
                    <DataRow
                      property="parent_subscription"
                      title="Parent Subscription"
                      value={subscription.parent_product_name}
                      accessor={subscription.parent_id}
                    />
                  )}
                  <Collapsible buttonText="Details">
                    <DataRow
                      property="json"
                      title="Details"
                      value={subscription.details}
                    />
                  </Collapsible>
                </div>
                <div className="vp-SubscriptionView__details-content">
                  <DataRow
                    property="product_name"
                    title="Product Name"
                    value={subscription.product_name}
                    accessor={subscription.product_id}
                  />
                  <DataRow
                    property="product_udac"
                    title="Product UDAC"
                    value={subscription.product_udac}
                  />
                  <DataRow
                    property="account_name"
                    title="Account Name"
                    value={accountName}
                    accessor={subscription.account_id}
                  />
                  <DataRow property="yes/no" title="Add-On" value={subscription.is_addon} />
                  <DataRow
                    property="created_at"
                    title="Created On"
                    value={subscription.created_at}
                  />
                  <DataRow
                    property="updated_at"
                    title="Last Modified"
                    value={subscription.updated_at}
                  />
                  <DataRow property="ended_at" title="Cancel Date" value={subscription.ended_at} />
                  <Collapsible buttonText="Other IDs">
                    <DataRow property="id" title="Subscription ID" value={subscription.id} />
                    <DataRow
                      property="parent_oli_id"
                      title="Parent OLI ID"
                      value={subscription.parent_oli_id}
                    />
                    <DataRow property="oli_id" title="OLI ID" value={subscription.oli_id} />
                    <DataRow
                      property="product_id"
                      title="Product ID"
                      value={subscription.product_id}
                    />
                  </Collapsible>
                </div>
              </div>
            </Card>
          </div>
          {isParentSubscription(subscription) && (
            <div
              id="vp-SubscriptionView__ChildSubscriptions"
              className="vp-SubscriptionView__details"
            >
              <SubscriptionCard
                title="Child Subscriptions"
                subscriptions={childSubscriptions}
                loadMoreSubscriptions={loadMoreSubscriptions}
                createSubscriptionLinkParams={createSubscriptionLinkParams}
                from={location.pathname}
                accountName={accountName}
                containsChildSubscriptionsOnly
              />
            </div>
          )}
        </div>
      </Page>
    )
  }
}

SubscriptionView.propTypes = {
  data: PropTypes.shape({
    subscription: PROP_TYPES.SUBSCRIPTION,
    account_name: PropTypes.string,
    childSubscriptions: PropTypes.arrayOf(PROP_TYPES.SUBSCRIPTION)
  }).isRequired,
  location: PROP_TYPES.STATE_WITH_NOTICE,
  loadMoreSubscriptions: PropTypes.func,
  refresh: PropTypes.func
}

SubscriptionView.defaultProps = {
  location: undefined,
  loadMoreSubscriptions: undefined,
  refresh: undefined
}

export default withSubscriptionAndChildrenGet(SubscriptionView)
