import React from 'react'
import PropTypes from 'prop-types'
import Card from 'components/common/Card'
import collapsible from 'components/common/shared/Collapsible'
import './ClientConfigCard.scss'

function renderMessages (messages = []) {
  return (
    <div className="vp-ClientConfigCard-content">
      <span className="vp-ClientConfigCard-content__title">Messages</span>
      <ul>
        {messages.map(([level, messagesList]) => (
          <li key={level}>
            <p>
              <span className="vp-ClientConfigCard-content__label">
                {`${level}:`}
              </span>
            </p>
            <ul>
              {messagesList.map(message => <li key={message}>{message}</li>)}
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}
function renderModules (modules = []) {
  return (
    <div className="vp-ClientConfigCard-content">
      <span className="vp-ClientConfigCard-content__title">Modules</span>
      <ul>
        {modules.map(module => <li key={module}>{module}</li>)}
      </ul>
    </div>
  )
}

function renderApplications (applications = []) {
  return (
    <div className="vp-ClientConfigCard-content">
      <span className="vp-ClientConfigCard-content__title">Applications</span>
      <ul>
        {applications.map(([appName, app]) => (
          <li key={appName}>
            <p>
              <span className="vp-ClientConfigCard-content__label">{`${appName}:`}</span>
            </p>
            <ul>
              <li>
                <span className="vp-ClientConfigCard-content__label">Modules:</span>
                <ul>
                  {app.modules && app.modules.map(module => <li key={module}>{module}</li>)}
                </ul>
              </li>
              <li>
                <span className="vp-ClientConfigCard-content__label">Config:</span>
                <ul>
                  {app.config && Object.entries(app.config).map(([key, value]) => (
                    <li key={key}>{`${key}: ${value}`}</li>
                  ))}
                </ul>
              </li>
              <li>
                <span className="vp-ClientConfigCard-content__label">OLI Numbers:</span>
                <ul>
                  {app.oli_numbers && app.oli_numbers.map(oliNumber => (
                    <li key={oliNumber}>{oliNumber}</li>)
                  )}
                </ul>
              </li>
            </ul>
          </li>
        ))}
      </ul>
    </div>
  )
}

const getTitleCollapseButton = (collapseHandlerAction, collapsed) => ({
  icon: collapsed ? 'chevron-down' : 'chevron-up',
  clickHandler: collapseHandlerAction,
  props: {
    btnStyle: 'clear'
  }
})

const CollapsibleCard = collapsible(Card)

function ClientConfigCard ({ derivedConfig, loadDerivedConfig }) {
  return (
    <CollapsibleCard
      title="Client Config"
      getCollapseButton={getTitleCollapseButton}
      onFirstExpand={loadDerivedConfig}
      initiateCollapsed
    >
      <div className="vp-ClientConfigCard">
        <div className="vp-ClientConfigCard-container">
          {derivedConfig ? (
            <>
              <div className="vp-ClientConfigCard-content">
                <p>
                  <span className="vp-ClientConfigCard-content__label">
                    Is active subscription:
                    {' '}
                  </span>

                  {derivedConfig.is_active_subscription ? 'Yes' : 'No'}
                </p>
                <p>
                  <span className="vp-ClientConfigCard-content__label">Is active client: </span>

                  {derivedConfig.is_active_client ? 'Yes' : 'No'}
                </p>
              </div>

              {renderMessages(Object.entries(derivedConfig.messages || {}))}
              {renderModules(derivedConfig.modules)}
              {renderApplications(Object.entries(derivedConfig.applications || {}))}
            </>
          ) : (
            <p>No config found for this client.</p>
          )}
        </div>
      </div>
    </CollapsibleCard>
  )
}

ClientConfigCard.propTypes = {
  derivedConfig: PropTypes.shape({
    is_active_subscription: PropTypes.bool,
    is_active_client: PropTypes.bool,
    messages: PropTypes.object,
    applications: PropTypes.object,
    modules: PropTypes.array
  }),
  loadDerivedConfig: PropTypes.func
}

ClientConfigCard.defaultProps = {
  derivedConfig: undefined,
  loadDerivedConfig: undefined
}

export default ClientConfigCard
