import React from 'react'
import './Avatar.scss'

import theme from '../../../helpers/theme-helpers'

export const getInitials = () => {
  try {
    // Always try catch around json parse
    const user = window.localStorage.getItem('current_user')
    const { first_name: firstName, last_name: lastName } = JSON.parse(user)
    const firstNameInitial = firstName.charAt(0)
    const lastNameInitial = lastName.charAt(0)

    return `${firstNameInitial}${lastNameInitial}`
  } catch (error) {
    return ''
  }
}

export const Avatar = () => (
  <div className="vp-Avatar" style={theme.components.Avatar}>
    <span className="vp-Avatar__initials">{getInitials()}</span>
  </div>
)
