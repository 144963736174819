import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'
import Page from 'components/common/Page'
import Loading from 'components/common/Loading'
import permissionHelper from 'helpers/permissions-helpers'
import HttpError from 'helpers/errors'
import SubscriptionCreateForm from './SubscriptionCreateForm'

class SubscriptionCreate extends React.Component {
  constructor (props) {
    super(props)

    let error
    if (!permissionHelper.canCreateSubscriptions()) {
      error = new HttpError(404)
    }

    this.state = {
      loading: false,
      error
    }

    if (this.props.location && this.props.location.state) {
      const { from, accountName, ...baseData } = this.props.location.state
      this.baseData = baseData
      this.from = from
      this.accountName = accountName
    }
    this.handleNewSubscription = this.handleNewSubscription.bind(this)
    this.redirectToNextScreen = this.redirectToNextScreen.bind(this)
  }

  redirectToNextScreen () {
    this.setState(prevState => ({
      location: {
        pathname: `/subscriptions/${prevState.newSubscriptionId}`,
        state: {
          notice: {
            message: 'Successfully created subscription.',
            type: 'success'
          }
        }
      },
      loading: false
    }))
  }

  async handleNewSubscription (id) {
    this.setState({ newSubscriptionId: id, loading: true })

    this.redirectToNextScreen()
  }

  render () {
    if (this.state.error) throw this.state.error

    if (this.state.location) {
      return <Redirect push to={this.state.location} />
    }

    return (
      <Page title={`Create new subscription - ${this.accountName}`}>
        {this.state.loading && <Loading />}
        <SubscriptionCreateForm
          baseData={this.baseData}
          handleNewSubscription={this.handleNewSubscription}
          from={this.from}
        />
      </Page>
    )
  }
}

SubscriptionCreate.defaultProps = {
  location: {}
}
SubscriptionCreate.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      account_id: PropTypes.string,
      vendor_id: PropTypes.string,
      parent_oli_id: PropTypes.string,
      from: PropTypes.string,
      accountName: PropTypes.string
    }),
    pathname: PropTypes.string,
    search: PropTypes.string
  })
}

export default SubscriptionCreate
