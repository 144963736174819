import rolesHelper from './roles-helper'

function _getPermissions () {
  const currentUser = window.localStorage.getItem('current_user')
  const parsed = JSON.parse(currentUser)
  return { roles: parsed.roles, policies: parsed.policies }
}

/**
 * Returns true if the current user can update user
 *
 * platform admin should be able to update any users
 * platform user admin can only update non-platform admin users
 *
 * @param {*} user - user to be updated
 * @param {Array} policies - policies of the current user
 * @returns {boolean}
 */
function _canUpdateUser (user, policies) {
  if (isAdmin()) {
    return true
  }

  const userRoles = rolesHelper.getApplicationRolesAndPolicies(user.permissions).roles
  if (api.hasPerm({ roles: ['user_admin'] }) && !userRoles.includes('admin')) {
    return true
  }

  return user.type === 'CLIENT' && policies && api.hasPerm({ policies })
}

function isAdmin () {
  return api.hasPerm({ roles: ['admin'] })
}

function hasPerm ({ roles = [], policies = [] } = {}) {
  const perms = api._getPermissions()
  if (roles.length > 0 && policies.length > 0) {
    return api._hasPerm(roles, perms.roles) && api._hasPerm(policies, perms.policies)
  }

  if (roles.length > 0) {
    return api._hasPerm(roles, perms.roles)
  }

  if (policies.length > 0) {
    return api._hasPerm(policies, perms.policies)
  }

  return false
}

function canResetUserPassword (user) {
  return user.is_active && _canUpdateUser(user, ['user:reset-password'])
}

function canAddAccountUser () {
  return api.hasPerm({ policies: ['account#user:create'] })
}

function canSendUserWelcomeEmail (user) {
  const isClient = user.type === 'CLIENT'
  return isClient && user.is_active && _canUpdateUser(user, ['user:reset-password'])
}

function canActivateUser (user) {
  return _canUpdateUser(user, ['user:activate'])
}

function canChangeUserType (user) {
  // TODO reinvestigate with VP-693
  return user.is_active && isAdmin()
}

function canEditUser (user) {
  return user.is_active && _canUpdateUser(user, ['user:update'])
}

function canEditUserPlatformRoles (user) {
  return user.is_active && _canUpdateUser(user, ['user#permissions:update'])
}

function canCheckUserLockStatus (user) {
  return user.is_active && api.hasPerm({ policies: ['user#blocks:read'] })
}

function canUnlockUser (user) {
  return user.is_active && _canUpdateUser(user, ['user#blocks:update'])
}

function canDisplayAuditlogs () {
  return api.hasPerm({ policies: ['user#audit:read'] })
}

function canDetachAccountUser () {
  return api.hasPerm({ policies: ['account#user:delete'] })
}

function canReadUserAccountPermissions () {
  return api.hasPerm({ policies: ['user#account-permissions:read'] })
}

function canUpdateUserAccountPermissions () {
  return api.hasPerm({ policies: ['user#account-permissions:update'] })
}

function canSeeCladminLink () {
  return api.hasPerm({ policies: ['client:app-links'] })
}

function canSeeDashboardLink () {
  return api.hasPerm({ policies: ['client:app-links'] })
}

function canCreateSubscriptions () {
  return api.hasPerm({ policies: ['subscription:create-manual'] })
}

function canEditSubscriptions () {
  return api.hasPerm({ policies: ['subscription:update'] })
}

/**
 * Returns whether any perm in perms exists in current.
 * @param {Array} perms - perms to check
 * @param {Array} current - perms to check against
 * @returns {boolean}
 */
const _hasPerm = (perms, current) => perms.some(perm => current.includes(perm))

const api = {
  hasPerm,
  canAddAccountUser,
  canResetUserPassword,
  canSendUserWelcomeEmail,
  canActivateUser,
  canChangeUserType,
  canEditUser,
  canEditUserPlatformRoles,
  canCheckUserLockStatus,
  canUnlockUser,
  canDisplayAuditlogs,
  canDetachAccountUser,
  canReadUserAccountPermissions,
  canUpdateUserAccountPermissions,
  canSeeCladminLink,
  canSeeDashboardLink,
  canCreateSubscriptions,
  canEditSubscriptions,
  isAdmin,
  _getPermissions,
  _hasPerm,
  _canUpdateUser
}

export default api
