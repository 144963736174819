import ProductSearch from 'components/product/ProductSearch'
import ProductView from '../components/product/ProductView'
import UserEditPlatformRoles from '../components/user/UserEditPlatformRoles'
import UserChangeType from '../components/user/UserChangeType'
import UserPasswordReset from '../components/user/UserPasswordReset'
import UserCreate from '../components/user/UserCreate'
import UserEdit from '../components/user/UserEdit'
import UserAddAccount from '../components/user/UserAddAccount'
import UserSearch from '../components/user/UserSearch'
import UserView from '../components/user/UserView'
import UserAccountPermissions from '../components/user/UserAccountPermissions'
import UserEditPermissions from '../components/user/UserEditPermissions'
import UserAddPermission from '../components/user/UserAddPermission'

import ClientSearch from '../components/client/ClientSearch'
import ClientView from '../components/client/ClientView'

import AccountSearch from '../components/account/AccountSearch'
import AccountEdit from '../components/account/AccountEdit'
import AccountAddUser from '../components/account/AccountAddUser'
import AccountView from '../components/account/AccountView'
import AccountSfAccount from '../components/account/AccountSfAccount'

import LocationView from '../components/location/LocationView'

import SubscriptionView from '../components/subscription/SubscriptionView'
import SubscriptionCreate from '../components/subscription/SubscriptionCreate'
import SubscriptionEdit from '../components/subscription/SubscriptionEdit'

import ProfileChangePassword from '../components/profile/ProfileChangePassword'

const userRoutesConfig = {
  menu: {
    iconName: 'user',
    label: 'Users',
    path: '/users'
  },
  routes: [
    {
      path: '/users/new',
      component: UserCreate
    },
    {
      path: '/users/:id/accounts/add',
      component: UserAddAccount
    },
    {
      path: '/users/:id/platform-roles/edit',
      component: UserEditPlatformRoles
    },
    {
      path: '/users/:id/change-type',
      component: UserChangeType
    },
    {
      path: '/users/:id/password-reset',
      component: UserPasswordReset
    },
    {
      path: '/users/:id/edit',
      component: UserEdit
    },
    {
      path: '/users/:id',
      component: UserView
    },
    {
      path: '/users',
      component: UserSearch
    },
    {
      path: '/users/:id/account-permissions/:account_id',
      component: UserAccountPermissions
    },
    {
      path: '/users/:id/account-permissions/:account_id/edit',
      component: UserEditPermissions
    },
    {
      path: '/users/:id/account-permissions/:account_id/add',
      component: UserAddPermission
    }
  ]
}

const accountRoutesConfig = {
  menu: {
    iconName: 'building',
    label: 'Accounts',
    path: '/accounts'
  },
  routes: [
    {
      path: '/accounts/:id/users/new',
      component: AccountAddUser
    },
    {
      path: '/accounts/:id/edit',
      component: AccountEdit
    },
    {
      path: '/accounts/:id',
      component: AccountView
    },
    {
      path: '/accounts',
      component: AccountSearch
    },
    {
      path: '/sf-account/:sf_id',
      component: AccountSfAccount
    }
  ]
}

const clientsRoutesConfig = {
  menu: {
    iconName: 'users',
    label: 'Clients',
    path: '/clients'
  },
  routes: [
    {
      path: '/clients',
      component: ClientSearch
    },
    {
      path: '/clients/:id',
      component: ClientView
    }
  ]
}

const locationRoutesConfig = {
  routes: [
    {
      path: '/locations/:id',
      component: LocationView
    }
  ]
}

const profileRoutesConfig = {
  routes: [
    {
      path: '/profile/change-password',
      component: ProfileChangePassword
    }
  ]
}

const productRoutesConfig = {
  menu: {
    iconName: 'file',
    label: 'Products',
    path: '/products'
  },
  routes: [
    {
      path: '/products',
      component: ProductSearch
    },
    {
      path: '/products/:id',
      component: ProductView
    }
  ]
}

const subscriptionRoutesConfig = {
  routes: [
    {
      path: '/subscriptions/:id/edit',
      component: SubscriptionEdit
    },
    {
      path: '/subscriptions/create',
      component: SubscriptionCreate
    },
    {
      path: '/subscriptions/:id',
      component: SubscriptionView
    }
  ]
}

const ROUTES_CONFIG = [
  userRoutesConfig,
  accountRoutesConfig,
  profileRoutesConfig,
  locationRoutesConfig,
  subscriptionRoutesConfig,
  clientsRoutesConfig,
  productRoutesConfig,
  locationRoutesConfig
]
export default ROUTES_CONFIG
