import React from 'react'
import PropTypes from 'prop-types'

import Card from '../../common/Card'
import Page from '../../common/Page'
import AppConfigCard from './AppConfigCard/index'
import PROP_TYPES from '../../../prop-types'
import Loading from '../../common/Loading'
import withProductAndAppConfigGet from '../shared/with-product-and-app-config-get'
import { defaultColors } from '../../../helpers/theme-helpers'
import './ProductView.scss'

const CHILDREN_HEADERS = [
  {
    name: 'Product Number',
    accessor: 'child_product_number',
    settings: {
      type: 'linkAddProduct',
      function: () => {},
      accessor: 'child_product_id'
    }
  },
  {
    name: 'Product Name',
    accessor: 'child_product_name'
  }
]

const LABEL_COLOR = {
  color: defaultColors.darkGray
}

function getDetailsDisplay (detail) {
  return detail || '—'
}

export const ProductView = ({
  data, location, history, loadMoreAppConfigs
}) => {
  CHILDREN_HEADERS[0].settings.function = id => history.push(`/products/${id}`)
  const {
    product,
    appConfig
  } = data
  if (!product) {
    return <Loading />
  }

  const notice = location && location.state ? location.state.notice : undefined
  return (
    <Page title={`${product.name}`} notice={notice}>
      <div id="vp-ProductView" className="vp-ProductView">
        <div id="vp-ProductView__ProductDetails" className="vp-ProductView__details">
          <Card title="Product Details">
            <div className="vp-ProductView__details-container">
              <div className="vp-ProductView__details-content">
                <p className="vp-ProductView__details-content-label" style={LABEL_COLOR}>
                  Name
                </p>
                <p>{getDetailsDisplay(product.name)}</p>
                <p className="vp-ProductView__details-content-label" style={LABEL_COLOR}>
                  Description
                </p>
                <p>{getDetailsDisplay(product.name)}</p>
                <p className="vp-ProductView__details-content-label" style={LABEL_COLOR}>
                  Talus Package ID
                </p>
                <p>{getDetailsDisplay(product.talus_package_id)}</p>
              </div>
              <div className="vp-ProductView__details-content">
                <p className="vp-ProductView__details-content-label" style={LABEL_COLOR}>
                  ID
                </p>
                <p>{getDetailsDisplay(product.id)}</p>
                <p className="vp-ProductView__details-content-label" style={LABEL_COLOR}>
                  UDAC
                </p>
                <p>{getDetailsDisplay(product.udac)}</p>
                <p className="vp-ProductView__details-content-label" style={LABEL_COLOR}>
                  Talus Product SKU
                </p>
                <p>{getDetailsDisplay(product.talus_product_sku)}</p>
              </div>
            </div>
          </Card>
        </div>
        <div id="vp-ProductView__ProductDetails" className="vp-ProductView__details">
          <AppConfigCard appConfigs={appConfig} loadMoreAppConfigs={loadMoreAppConfigs} />
        </div>
      </div>
    </Page>
  )
}

const propTypes = {
  data: PropTypes.shape({
    product: PROP_TYPES.PRODUCT,
    appConfig: PropTypes.arrayOf(PROP_TYPES.APP_CONFIG)
  }).isRequired,
  location: PROP_TYPES.STATE_WITH_NOTICE,
  loadMoreAppConfigs: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

ProductView.propTypes = propTypes
ProductView.defaultProps = {
  location: undefined, history: undefined, loadMoreAppConfigs: undefined
}

export default withProductAndAppConfigGet(ProductView)
