import React from 'react'
import getClientLocations from 'helpers/component-request'
import { formatLocations } from 'helpers/utils'
import PROP_TYPES from '../../../prop-types'
import { getAccount } from '../../account/shared/account-helpers'

const defaultSelect = [
  { display: 'All', value: '-1' },
  { display: 'None', value: '0' }
]

const mapSelect = (data, key, value) => data.map(e => ({ display: e[value], value: e[key] }))

const userAddPermissionGet = (WrappedComponent) => {
  class UserAddPermissionGet extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        permissions: [],
        clients: [...defaultSelect],
        locations: [...defaultSelect],
        error: null
      }
    }

    async componentDidMount () {
      this._mounted = true
      await this.getAllData()
    }

    componentWillUnmount () {
      this._mounted = false
    }

    async getAllData () {
      const { account_id: accountId } = this.props.match.params
      const { location: { state: { permissions } } } = this.props
      const { clients: currentClients } = this.state
      let clients
      try {
        const { clients: accountClients } = await getAccount(accountId, false, false)
        clients = currentClients.concat(mapSelect(accountClients, 'id', 'name'))
      } catch (error) {
        this.setState({ error })
      }

      this._mounted && this.setState({ permissions, clients })
    }

    getLocations = async (value) => {
      const clientLocations = +value > 0 ? await getClientLocations(value) : []
      const locations = defaultSelect.concat(
        mapSelect(formatLocations(clientLocations), 'id', 'display')
      )
      this.setState({ locations })
    }

    render () {
      const { error, ...data } = this.state
      if (error) throw error
      return (
        <WrappedComponent {...data} {...this.props} getLocations={this.getLocations} />
      )
    }
  }

  UserAddPermissionGet.propTypes = {
    match: PROP_TYPES.USER_ACCOUNT_PERMISSIONS.isRequired,
    location: PROP_TYPES.STATE_WITH_PERMISSIONS.isRequired
  }

  return UserAddPermissionGet
}

export default userAddPermissionGet
