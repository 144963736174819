import React from 'react'

import { cleanHtml } from 'helpers/utils'
import ExternalLink from 'components/common/ExternalLink'
import Link from 'components/common/Link'

function switchRenderHelper (property, value, accessor = undefined) {
  let output

  switch (property) {
    case 'ended_at':
    case 'created_at':
    case 'updated_at':
      output = value ? new Date(value).toLocaleDateString() : value
      break
    case 'active':
      output = value ? 'Active' : 'Inactive'
      break
    case 'deleted_at':
      output = value ? 'Inactive' : 'Active'
      break
    case 'yes/no':
    case 'is_primary':
      output = value ? 'Yes' : 'No'
      break
    case 'website_url':
      if (value) {
        output = (
          <ExternalLink href={value}>
            <span>{value}</span>
          </ExternalLink>
        )
      }
      break
    case 'photo_url':
    case 'logo_url':
      output = <img src={`${value}`} alt={`${property}`} />
      break
    case 'description':
      if (value) {
        output = cleanHtml(value)
      }
      break
    case typeof value === 'boolean' && property:
      output = value.toString()
      break
    case Array.isArray(value) && property:
      output = value.join(', ')
      break
    case 'account_name':
      output = (
        <Link to={`/accounts/${accessor}`} href={`/accounts/${accessor}`}>
          <p>{value}</p>
        </Link>
      )
      break
    case 'product_name':
      output = (
        <Link to={`/products/${accessor}`} href={`/products/${accessor}`}>
          <p>{value}</p>
        </Link>
      )
      break
    case 'parent_subscription':
      output = (
        <Link to={`/subscriptions/${accessor}`} href={`/subscriptions/${accessor}`}>
          <p>{value}</p>
        </Link>
      )
      break
    case 'json':
      output = value ? JSON.stringify(value, null, 2) : value
      break
    default:
      output = value
      break
  }
  return output
}

function wrapWithParagraph (output) {
  if (!output) {
    return <p>—</p>
  }

  return typeof output !== 'object' ? <p>{output}</p> : output
}

export { switchRenderHelper, wrapWithParagraph }
