import React from 'react'
import PropTypes from 'prop-types'

const Address = ({
  street1, street2, city, state, postalCode, country
}) => {
  const shouldShowAdddress = street1 || street2 || city || state || postalCode || country

  return (shouldShowAdddress ? (
    <address>
      <div>{street1 || ''}</div>
      <div>{street2 || ''}</div>
      <div>
        {city ? (
          <span>
            {city}
            {', '}
          </span>
        ) : (
          ''
        )}
        <span>
          {state || ''}
          {' '}
        </span>
        <span>{postalCode || ''}</span>
      </div>
      <div>{country}</div>
    </address>
  ) : '—')
}

Address.defaultProps = {
  street1: undefined,
  street2: undefined,
  city: undefined,
  state: undefined,
  postalCode: undefined,
  country: undefined
}

Address.propTypes = {
  street1: PropTypes.string,
  street2: PropTypes.string,
  city: PropTypes.string,
  state: PropTypes.string,
  postalCode: PropTypes.string,
  country: PropTypes.string
}

export default Address
