let authInstance
let appInstance
let hiddenState
let initSessionCheckDone

const sessionCheck = async (event) => {
  // only run sessionCheck once on init
  // firefox would trigger this twice for some reason
  if (event === 'init') {
    if (initSessionCheckDone) {
      return
    }
    initSessionCheckDone = true
  }

  try {
    const isSsoSessionValid = await authInstance.isSsoSessionValid()
    if (!isSsoSessionValid) {
      window.location.reload()
    }
  } catch (error) {
    appInstance.setState({ error })
  }
}

/**
 * Based on examples from https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API
 */
async function initSessionCheck (auth, app) {
  authInstance = auth
  appInstance = app

  let hiddenKey
  let visibilityEvent
  if (typeof document.hidden !== 'undefined') { // Opera 12.10 and Firefox 18 and later support
    hiddenKey = 'hidden'
    visibilityEvent = 'visibilitychange'
  } else if (typeof document.msHidden !== 'undefined') {
    hiddenKey = 'msHidden'
    visibilityEvent = 'msvisibilitychange'
  } else if (typeof document.webkitHidden !== 'undefined') {
    hiddenKey = 'webkitHidden'
    visibilityEvent = 'webkitvisibilitychange'
  }

  document.addEventListener(visibilityEvent, () => {
    // state did not change
    if (hiddenState === document[hiddenKey]) {
      return
    }

    hiddenState = document[hiddenKey]
    // if visible, do the check
    if (!document[hiddenKey]) {
      sessionCheck(visibilityEvent)
    }
  })

  await sessionCheck('init')
}

export default initSessionCheck
