import requestHelper from './request-helpers'

function getAllRoles () {
  const request = {
    method: 'GET',
    url: `/platform-roles`
  }
  return requestHelper.makeRequest(request)
}

function getApplicationRolesAndPolicies (permissions, applicationName = 'PLATFORM') {
  const defaultObject = { application_id: 'PLATFORM', roles: [], policies: [] }
  if (permissions && permissions.length > 0) {
    const rolesAndPolicies = permissions.filter(app => app.application_id === applicationName)

    if (rolesAndPolicies && rolesAndPolicies.length > 0) {
      const rolesPolicies = Object.assign({}, rolesAndPolicies[0])

      rolesPolicies.roles = rolesPolicies.roles || []
      rolesPolicies.policies = rolesPolicies.policies || []
      return rolesPolicies
    }

    return defaultObject
  }

  return defaultObject
}

const api = {
  getAllRoles,
  getApplicationRolesAndPolicies
}

export default api
