import requestHelpers from '../../../helpers/request-helpers'
import { getSubscriptions } from '../../account/shared/account-helpers'

/**
 * Build request to get subscriptions endpoint
 * @param {Number} accountId
 * @param {Number} subscriptionId
 */
function getSubscription (id) {
  const request = {
    method: 'GET',
    url: `/subscriptions/${id}`
  }
  return requestHelpers.makeRequest(request)
}

/**
 * Build request to patch subscriptions endpoint
 * @param {Number} id
 * @param {Object} body
 */
function patchSubscription (id, body, toJson = false) {
  const request = {
    method: 'PATCH',
    url: `/subscriptions/${id}`,
    body,
    toJson
  }

  return requestHelpers.makeRequest(request)
}

/**
 * Build request to post subscriptions endpoint
 * @param {Object} body
 */
function createSubscription (body) {
  const request = {
    method: 'POST',
    url: `/subscriptions`,
    body
  }

  return requestHelpers.makeRequest(request)
}

function isParentSubscription (subscription) {
  return subscription.parent_oli_id === null
}

function isChildSubscription (subscription) {
  return subscription.parent_oli_id !== null
}

async function getChildSubscriptions (subscription, pageSize) {
  const params = {
    account_id: subscription.account_id,
    parent_oli_id: subscription.oli_id
  }

  const childSubscriptions = await getSubscriptions(params, pageSize)

  return childSubscriptions
}

export default getSubscription
export {
  getSubscription,
  patchSubscription,
  createSubscription,
  isParentSubscription,
  isChildSubscription,
  getChildSubscriptions
}
