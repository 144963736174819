import React from 'react'
import sanitizeHtml from 'sanitize-html'
import moment from 'moment'

const snakeToTitleCase = (string = '') => (
  string.split('_').map(word => word.charAt(0).toUpperCase() +
  word.slice(1)).join(' ')
)

export default snakeToTitleCase

export const getAddress = (location) => {
  const {
    address_street1: street,
    address_city: city,
    address_state: state
  } = location
  let display = street
  display += street && (city || state) ? ', ' : ''
  display += city
  display += city && state ? ', ' : ''
  return display + state
}

export const formatLocations = (locations = []) => locations.map((l) => {
  const fl = { ...l }
  fl.display = getAddress(l)
  return fl
})

export const formatPermissions = (rows = []) => rows.map((r) => {
  const p = { ...r }
  if (p.roles) {
    p.roles = p.roles.map(snakeToTitleCase).join(', ')
  }
  return p
})

export const _transformOembedTag = (tagName, attribs) => {
  const youTubeRegExp = /^.*(youtu\.be\/|youtube.com\/(watch\?v=|embed\/))(.*)/i
  const vimeoRegExp = /^.*(vimeo.com\/(video\/)?)([0-9]*).*/i

  let embededUrl
  let match = attribs.url.match(youTubeRegExp)
  if (match && match[3].length === 11) {
    embededUrl = `https://www.youtube.com/embed/${match[3]}`
  } else {
    match = attribs.url.match(vimeoRegExp)
    if (match && match[3].length === 8) {
      embededUrl = `https://player.vimeo.com/video/${match[3]}`
    }
  }

  if (embededUrl) {
    return {
      tagName: 'iframe',
      attribs: {
        class: 'vp-DataRow__iframe',
        src: embededUrl,
        frameborder: '0',
        allowfullscreen: true
      }
    }
  }

  return { tagName, attribs }
}

export const cleanHtml = (srcHtml, returnHtml = false) => {
  const sanitizer = sanitizeHtml
  const options = {
    allowedTags: sanitizer.defaults.allowedTags.concat([
      'h2',
      'u',
      's',
      'sup',
      'sub',
      'span',
      'figure',
      'oembed',
      'img',
      'i',
      'a'
    ]),
    allowedAttributes: {
      a: [
        'href',
        'name',
        'target',
        'rel',
        'data-smsbody',
        'data-emailbody',
        'data-emailsubject',
        'data-url',
        'data-sms',
        'data-email',
        'data-tel'
      ],
      img: ['src'],
      '*': ['style', 'class'],
      oembed: ['url'],
      iframe: ['width', 'height', 'src', 'frameborder', 'allowfullscreen']
    },
    transformTags: {
      a: sanitizeHtml.simpleTransform('a', { rel: 'noopener' }),
      figure: 'div',
      oembed: _transformOembedTag
    },
    allowedSchemesByTag: {
      a: ['http', 'https', 'tel', 'mailto', 'sms']
    }
  }

  const clean = sanitizer(srcHtml, options)

  // eslint-disable-next-line react/no-danger
  return returnHtml ? clean : <div dangerouslySetInnerHTML={{ __html: clean }} />
}

export function getTodaysDate () {
  return moment().format('YYYY-MM-DD')
}
