import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@vivialplatform/ui-shared-lib'
import Label from '../Form/Label'
import Button from '../Button'

const InputSearch = ({
  htmlId, label, onChange, filter, filterOpen, filterFunc, ...rest
}) => {
  if (label) {
    return (
      <Label htmlFor={htmlId} text={label}>
        <div className="vp-InputSearch input-group">
          <input
            {...rest}
            onChange={onChange}
            id={htmlId}
            className="input-group-field"
            type="search"
          />
          <div className="input-group-button">
            <Button type="submit" aria-label="Search">
              <span className="vp-AccountView__icon" aria-hidden="true">
                <Icon icon={['fas', 'search']} />
              </span>
            </Button>
          </div>
        </div>
      </Label>
    )
  }

  return (
    <div className="vp-InputSearch input-group">
      <input
        {...rest}
        onChange={onChange}
        id={htmlId}
        className="input-group-field"
        type="search"
      />
      <div className="input-group-button">
        <Button type="submit" aria-label="Search">
          <span className="vp-Search__icon" aria-hidden="true">
            <Icon icon={['fas', 'search']} />
          </span>
        </Button>
        {filter === true && (
          <Button
            type="button"
            aria-label="Search-filter"
            onClick={e => filterFunc(e)}

          >
            <span
              data-tooltip
              title="Filter Options"
              className="vp-Search__icon"
              aria-hidden="true"
            >
              <Icon icon={['fas', 'filter']} />
            </span>
          </Button>
        )}
      </div>
    </div>
  )
}

InputSearch.propTypes = {
  htmlId: PropTypes.string.isRequired,
  label: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  filter: PropTypes.bool,
  filterOpen: PropTypes.bool,
  filterFunc: PropTypes.func
}
InputSearch.defaultProps = {
  label: undefined,
  filter: undefined,
  filterFunc: undefined,
  filterOpen: undefined
}
export default InputSearch
