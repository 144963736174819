/* eslint jsx-a11y/label-has-for: off */
import React from 'react'
import PropTypes from 'prop-types'

import snakeToTitleCase from 'helpers/utils'
import PROP_TYPES from '../../../prop-types'
import withUserAndAuditsGet from '../shared/with-user-and-audits-get'
import Card from '../../common/Card'
import Page from '../../common/Page'
import Loading from '../../common/Loading'
import Modal from '../../common/Modal'
import Button from '../../common/Button'
import LinkButton from '../../common/LinkButton'
import { defaultColors } from '../../../helpers/theme-helpers'
import rolesHelper from '../../../helpers/roles-helper'
import permissionHelper from '../../../helpers/permissions-helpers'
import requestHelper from '../../../helpers/request-helpers'
import FormattedDate from '../../common/FormattedDate'
import Collapsible from '../../common/Collapsible'
import UserPlatformRoles from '../shared/UserPlatformRoles'

import AccountsCard from './AccountsCard'
import AuditsCard from './AuditsCard'
import './UserView.scss'

const MODAL_SEND_WELCOME_EMAIL = 'send-welcome-email'
const MODAL_DETACH = 'detach'
const MODAL_DEACTIVATE = 'deactivate'
const MODAL_REACTIVATE = 'reactivate'
const MODAL_UNLOCK_USER = 'lockout_user'

const LABEL_COLOR = {
  color: defaultColors.darkGray
}

export class UserView extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      openModal: null,
      busy: false,
      pladminRoles: []
    }

    this.toggleModal = this.toggleModal.bind(this)
    this.toggleReactivateModal = this.toggleModal.bind(this, MODAL_REACTIVATE)
    this.toggleDetachModal = this.toggleModal.bind(this, MODAL_DETACH)
    this.toggleWelcomeEmailModal = this.toggleModal.bind(this, MODAL_SEND_WELCOME_EMAIL)
    this.toggleDeactivateModal = this.toggleModal.bind(this, MODAL_DEACTIVATE)
    this.toggleUnlockUserModal = this.toggleModal.bind(this, MODAL_UNLOCK_USER)

    this.handleWelcomeEmailModalConfirm = this.handleWelcomeEmailModalConfirm.bind(this)
    this.handleDeactivateModalConfirm = this.handleDeactivateModalConfirm.bind(this)
    this.handleReactivateModalConfirm = this.handleReactivateModalConfirm.bind(this)
    this.checkLockoutStatus = this.checkLockoutStatus.bind(this)
    this._handleModalConfirm = this._handleModalConfirm.bind(this)
    this.handleAccountDetach = this.handleAccountDetach.bind(this)
    this.handleAccountDetachConfirm = this.handleAccountDetachConfirm.bind(this)
    this.handleUnlockUserConfirm = this.handleUnlockUserConfirm.bind(this)
    this.getUserPlatformRoles = this.getUserPlatformRoles.bind(this)
  }

  componentDidMount () {
    this._mounted = true
    rolesHelper
      .getAllRoles()
      .then((res) => {
        if (this._mounted) {
          this.setState({ pladminRoles: res })
        }
      })
      .catch((error) => {
        if (this._mounted) {
          this.setState({ error })
        }
      })
  }

  componentWillUnmount () {
    // Note: necessary so setState() isn't called in componentDidMount() fetch promise chain
    // if component becomes unmounted
    this._mounted = false
  }

  static getEditPlatformRolesLink (user) {
    const editPlatformRolesPath = `/users/${user.id}/platform-roles/edit`
    let editPlatformRolesLink

    if (permissionHelper.canEditUserPlatformRoles(user)) {
      editPlatformRolesLink = {
        to: editPlatformRolesPath,
        href: editPlatformRolesPath,
        text: 'Edit',
        icon: 'edit',
        id: 'vp-EditPlatformRoles'
      }
    }

    return editPlatformRolesLink
  }

  getUserPlatformRoles (userRoles) {
    const { user } = this.props.data
    const { pladminRoles } = this.state

    if (user.type !== 'CLIENT' && pladminRoles && pladminRoles.length) {
      return (
        <UserPlatformRoles
          id="vp-UserView__PlatformRoles"
          cardProps={{
            title: 'Platform Roles',
            titleLink: UserView.getEditPlatformRolesLink(user)
          }}
        >
          {pladminRoles.map((role) => {
            const checked = userRoles.roles.includes(role) ? { checked: true } : {}
            return (
              <label htmlFor="userViewCheckbox" key={role}>
                <input
                  id="userViewCheckbox"
                  key={role}
                  type="checkbox"
                  {...checked}
                  disabled
                />
                {snakeToTitleCase(role)}
              </label>
            )
          })}
        </UserPlatformRoles>
      )
    }

    return null
  }

  async checkLockoutStatus () {
    this.setState({ busy: true })
    let response
    try {
      response = await requestHelper.makeRequest({
        method: 'GET',
        url: `/users/${this.props.data.user.id}/blocks`
      })
    } catch (error) {
      this.setState({ error })
    }

    if (!response.blocked_for || !response.blocked_for.length) {
      this.setState({
        busy: false,
        notice: {
          message: 'User is not locked out',
          type: 'success'
        }
      })
      if (this.pager) {
        this.pager.parentElement.parentElement.scrollIntoView()
      }
    } else {
      this.setState({ blockedIp: response.blocked_for[0].ip })
      this.toggleUnlockUserModal()
    }
  }

  async handleWelcomeEmailModalConfirm () {
    try {
      await this._handleModalConfirm({
        request: {
          url: `/users/${this.props.data.user.id}/reset-password?send_account_welcome_email=true`
        },
        messageOnSuccess: 'Successfully sent welcome email.',
        refreshOnSuccess: false
      })
    } catch (error) {
      this.setState({ error })
    }
  }

  async handleDeactivateModalConfirm () {
    try {
      await this._handleModalConfirm({
        request: {
          url: `/users/${this.props.data.user.id}/deactivate`
        },
        messageOnSuccess: 'Successfully deactivated user.',
        refreshOnSuccess: true
      })
    } catch (error) {
      this.setState({ error })
    }
  }

  async handleReactivateModalConfirm () {
    try {
      await this._handleModalConfirm({
        request: {
          url: `/users/${this.props.data.user.id}/activate`
        },
        messageOnSuccess: 'Successfully reactivated user.',
        refreshOnSuccess: true
      })
    } catch (error) {
      this.setState({ error })
    }
  }

  async _handleModalConfirm ({ request, refreshOnSuccess, messageOnSuccess }) {
    this.setState({ busy: true })
    this.toggleModal()

    let response
    try {
      response = await requestHelper.makeRequest({
        method: 'POST',
        toJson: false,
        ...request
      })
    } catch (error) {
      this.setState({ error })
      return false
    }

    if (response.status === 204) {
      this.setState({
        busy: false,
        notice: {
          message: messageOnSuccess,
          type: 'success'
        }
      })
      if (refreshOnSuccess) {
        try {
          await this.props.refresh()
        } catch (error) {
          this.setState({ error })
        }
      }
      return true
    }

    let validationErrors
    try {
      validationErrors = await response.json()
    } catch (error) {
      this.setState({ error })
      return false
    }

    const [{ message }] = validationErrors.errors
    this.setState({ busy: false, notice: { message, type: 'error' } })
    return false
  }

  async handleAccountDetachConfirm () {
    const { user } = this.props.data
    const { accountToDetach } = this.state
    try {
      await this._handleModalConfirm({
        request: {
          url: `/accounts/${accountToDetach}/users/${user.id}`,
          method: 'DELETE'
        },
        messageOnSuccess: 'Account detached successfully.',
        refreshOnSuccess: true
      })
    } catch (error) {
      this.setState({ error })
    }

    this.setState({ accountToDetach: undefined })
  }

  async handleUnlockUserConfirm () {
    const { user } = this.props.data
    try {
      await this._handleModalConfirm({
        request: { url: `/users/${user.id}/blocks`, method: 'DELETE' },
        messageOnSuccess: 'User unlocked successfully'
      })
    } catch (error) {
      this.setState({ error })
    }
  }

  handleAccountDetach (accountId) {
    this.setState({ accountToDetach: accountId })
    this.toggleDetachModal()
  }

  toggleModal (modal) {
    this.setState({
      busy: false, // in case busy status was set before opening modal
      openModal: modal
    })
  }

  render () {
    if (this.state.error) throw this.state.error
    const { user, accounts, auditLogs } = this.props.data
    const {
      busy, openModal, blockedIp
    } = this.state

    if (!user) return <Loading />

    const userRoles = rolesHelper.getApplicationRolesAndPolicies(user.permissions)
    const rootPath = `/users/${user.id}`
    const permissionsPath = `${rootPath}/account-permissions/{id}`
    const { location } = this.props
    const notice =
      this.state.notice ||
      (location.state && location.state.notice ? location.state.notice : undefined)
    const editUserPath = `${rootPath}/edit`
    let editLink
    if (permissionHelper.canEditUser(user)) {
      editLink = {
        to: editUserPath,
        href: editUserPath,
        text: 'Edit',
        icon: 'edit',
        id: 'vp-EditUser'
      }
    }

    const userAddTitleLink = {
      to: { state: { userName: `${user.first_name} ${user.last_name}` } }
    }
    const resetPasswordPath = `${rootPath}/password-reset`
    const changeTypePath = `${rootPath}/change-type`
    const userDetailsCardTitle = `User Details${!user.is_active ? ' (INACTIVE)' : ''}`
    const userDisplayName = `${user.first_name} ${user.last_name}`

    const actionButtons = []
    if (permissionHelper.canResetUserPassword(user)) {
      actionButtons.push(
        <LinkButton
          key="reset-password"
          to={resetPasswordPath}
          href={resetPasswordPath}
          btnStyle="hollow"
        >
          Reset Password
        </LinkButton>
      )
    }

    if (permissionHelper.canSendUserWelcomeEmail(user)) {
      actionButtons.push(
        <Button
          key="reset-password-email"
          btnStyle="hollow"
          onClick={this.toggleWelcomeEmailModal}
          disabled={busy}
        >
          Send Welcome Email
        </Button>
      )
    }

    if (permissionHelper.canChangeUserType(user)) {
      actionButtons.push(
        <LinkButton
          key="change-user-type"
          to={{ pathname: changeTypePath, state: { userDisplayName } }}
          href={changeTypePath}
          btnStyle="hollow"
        >
          Change Type
        </LinkButton>
      )
    }

    if (permissionHelper.canCheckUserLockStatus(user)) {
      actionButtons.push(
        <Button key="check-lock-status" btnStyle="hollow" onClick={this.checkLockoutStatus}>
          Lockout Status
        </Button>
      )
    }

    if (permissionHelper.canActivateUser(user)) {
      actionButtons.push(
        user.is_active ? (
          <Button
            key="deactivate-user"
            btnStyle="hollow"
            color={defaultColors.alert}
            onClick={this.toggleDeactivateModal}
            disabled={busy}
          >
            Deactivate
          </Button>
        ) : (
          <Button
            key="activate-user"
            btnStyle="hollow"
            onClick={this.toggleReactivateModal}
            disabled={busy}
          >
            Reactivate
          </Button>
        )
      )
    }

    return (
      <Page title={`${user.first_name} ${user.last_name}`} notice={notice}>
        {busy && <Loading />}
        <Modal
          isOpen={openModal === MODAL_DEACTIVATE}
          title="Deactivate"
          onRequestClose={this.toggleModal}
        >
          <p>Are you sure you want to deactivate this user?</p>
          <p>
            <small>
              <strong>Note: </strong>
              Deactivated users cannot login and their information is not editable.
            </small>
          </p>
          <Button onClick={this.handleDeactivateModalConfirm}>Confirm</Button>
          <Button btnStyle="clear" onClick={this.toggleModal}>
            Cancel
          </Button>
        </Modal>
        <Modal
          isOpen={openModal === MODAL_REACTIVATE}
          title="Reactivate"
          onRequestClose={this.toggleModal}
        >
          <p>Are you sure you want to reactivate this user?</p>
          {user.type === 'CLIENT' && (
            <p>
              <small>Note: A welcome email will be sent to the user.</small>
            </p>
          )}
          <Button onClick={this.handleReactivateModalConfirm}>Confirm</Button>
          <Button btnStyle="clear" onClick={this.toggleModal}>
            Cancel
          </Button>
        </Modal>
        <Modal isOpen={openModal === MODAL_DETACH} title="Detach" onRequestClose={this.toggleModal}>
          <p>Are you sure you want to detach?</p>
          <Button onClick={this.handleAccountDetachConfirm}>Confirm</Button>
          <Button btnStyle="clear" onClick={this.toggleModal}>
            Cancel
          </Button>
        </Modal>
        <Modal
          isOpen={openModal === MODAL_SEND_WELCOME_EMAIL}
          title="Welcome Email"
          onRequestClose={this.toggleModal}
        >
          <p>Are you sure you want to send the welcome email?</p>
          <Button onClick={this.handleWelcomeEmailModalConfirm}>Confirm</Button>
          <Button btnStyle="clear" onClick={this.toggleModal}>
            Cancel
          </Button>
        </Modal>
        <Modal
          isOpen={openModal === MODAL_UNLOCK_USER}
          title="Unlock user"
          onRequestClose={this.toggleModal}
        >
          <p>{`The account has been blocked from the ip address ${blockedIp}.`}</p>
          {permissionHelper.canUnlockUser(user) && (
            <>
              <p>Would you like to unlock it?</p>
              <Button onClick={this.handleUnlockUserConfirm}>Ok</Button>
            </>
          )}
          <Button btnStyle="clear" onClick={this.toggleModal}>
            Cancel
          </Button>
        </Modal>
        <div
          ref={(node) => {
            this.pager = node
          }}
          id="vp-UserView"
          className="vp-UserView"
        >
          {actionButtons.length > 0 && (
            <div id="vp-UserView__actions" className="vp-UserView__actions">
              <div
                id="vp-UserView__actions-buttons"
                className="vp-UserView__actions-buttons"
              >
                {actionButtons}
              </div>
            </div>
          )}
          <div className="vp-UserView__details">
            <Card id="vp-UserView__userDetails" title={userDetailsCardTitle} titleLink={editLink}>
              <div className="vp-UserView__details-container">
                <div className="vp-UserView__details-content">
                  <p className="vp-UserView__details-content-label" style={LABEL_COLOR}>
                    Name
                  </p>
                  <p>
                    {user.first_name}
                    {' '}
                    {user.last_name}
                  </p>
                  <p className="vp-UserView__details-content-label" style={LABEL_COLOR}>Email</p>
                  <p>{user.email}</p>
                  <p className="vp-UserView__details-content-label" style={LABEL_COLOR}>Type</p>
                  <p>
                    {user.type === 'AFFILIATE' ? `PARTNER` : user.type}
                  </p>
                  {user.affiliate_id && (
                    <>
                      <p className="vp-UserView__details-content-label" style={LABEL_COLOR}>
                        {user.type === 'CLIENT' ? 'Preferred Partner' : 'Partner' }
                      </p>
                      <p>
                        {user.affiliate_id}
                      </p>
                    </>
                  )}
                </div>
                <div className="vp-UserView__details-content">
                  <p className="vp-UserView__details-content-label" style={LABEL_COLOR}>Created</p>
                  <FormattedDate dateString={user.created_at} />
                  <p className="vp-UserView__details-content-label" style={LABEL_COLOR}>Updated</p>
                  <FormattedDate dateString={user.updated_at} />
                  <Collapsible buttonText="Other IDs">
                    <p className="vp-UserView__details-content-label" style={LABEL_COLOR}>
                      User ID
                    </p>
                    <p>{user.id}</p>
                    <p className="vp-UserView__details-content-label" style={LABEL_COLOR}>
                      Auth0 ID
                    </p>
                    <p>{user.auth0_id}</p>
                  </Collapsible>
                </div>
              </div>
            </Card>
          </div>
          {user.type === 'CLIENT' && (
            <div className="vp-UserView__details">
              <AccountsCard
                detachActionHandler={
                  permissionHelper.canDetachAccountUser() ? this.handleAccountDetach : undefined
                }
                editPermissions={
                  permissionHelper.canReadUserAccountPermissions() ? permissionsPath : ''
                }
                accounts={accounts}
                rootPath={rootPath}
                canAddAccount={permissionHelper.canAddAccountUser()}
                history={this.props.history}
                titleLink={userAddTitleLink}
              />
            </div>
          )}

          {this.getUserPlatformRoles(userRoles)}

          {permissionHelper.canDisplayAuditlogs() && (
            <div className="vp-UserView__details">
              <AuditsCard auditLogs={auditLogs} loadMoreAuditLogs={this.props.loadMoreAuditLogs} />
            </div>
          )}
        </div>
      </Page>
    )
  }
}

UserView.defaultProps = {
  location: {},
  loadMoreAuditLogs: undefined,
  history: undefined
}

UserView.propTypes = {
  data: PropTypes.shape({
    user: PROP_TYPES.USER,
    accounts: PropTypes.arrayOf(PROP_TYPES.ACCOUNT),
    auditLogs: PropTypes.arrayOf(PROP_TYPES.AUDIT_LOG)
  }).isRequired,
  location: PROP_TYPES.STATE_WITH_NOTICE,
  refresh: PropTypes.func.isRequired,
  loadMoreAuditLogs: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func
  })
}

export default withUserAndAuditsGet(UserView)
