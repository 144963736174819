import React from 'react'
import PROP_TYPES from '../../../prop-types'
import { getAccount, getSubscriptions } from './account-helpers'
import affiliatesRequestHelper from '../../../helpers/affiliates-request-helper'

const subscriptionPageSize = 100

function withAccountGet (
  WrappedComponent,
  fetchAffiliates = false,
  includeUsers = true,
  includeContacts = true
) {
  class WithAccountGet extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        data: {},
        error: null,
        moreSubscriptions: false
      }
    }

    async componentDidMount () {
      this._mounted = true
      await this.getAllData()
    }

    async componentDidUpdate (prevProps) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        await this.getAllData()
      }
    }

    componentWillUnmount () {
      // Note: necessary so setState() isn't called in
      // componentDidMount() fetch promise chain if
      // component becomes unmounted
      this._mounted = false
    }

    async getAllData () {
      let result
      const { id } = this.props.match.params
      const requests = [
        getAccount(id, includeUsers, includeContacts),
        getSubscriptions({ account_id: id }, subscriptionPageSize)
      ]

      if (fetchAffiliates) {
        requests.push(affiliatesRequestHelper.getAffiliates())
      }
      try {
        const [accountJson, subscriptions, affiliates] = await Promise.all(requests)
        const data = { ...accountJson }

        if (affiliates) {
          data.affiliates = affiliates
        }

        if (subscriptions) {
          data.subscriptions = subscriptions.data
        }
        const moreSubscriptions =
        subscriptions.next_page && subscriptions.page_size + Number(subscriptionPageSize) <= 1000
        this.setState({ moreSubscriptions })
        result = { data }
      } catch (error) {
        result = { error }
      }
      if (this._mounted) {
        this.setState(result)
      }
    }

    loadMore = async () => {
      const { id } = this.props.match.params
      const newPageSize = this.state.data.subscriptions.length + Number(subscriptionPageSize)
      if (newPageSize < 1000) {
        try {
          const subscription = await getSubscriptions({ account_id: id }, newPageSize)
          const data = {
            ...this.state.data,
            subscriptions: subscription.data
          }
          if (this._mounted) {
            this.setState({ data, moreSubscriptions: !!subscription.next_page })
          }
        } catch (error) {
          if (this._mounted) {
            this.setState({ error })
          }
        }
      }
    }

    render () {
      if (this.state.error) throw this.state.error
      return (
        <WrappedComponent
          data={this.state.data}
          loadMore={this.state.moreSubscriptions ? this.loadMore : undefined}
          {...this.props}
        />
      )
    }
  }

  WithAccountGet.displayName = `WithAccountGet(${getDisplayName(WrappedComponent)})`
  WithAccountGet.propTypes = {
    match: PROP_TYPES.ID_IN_PATH.isRequired
  }

  return WithAccountGet
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withAccountGet
