import React from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'

import requestHelpers from '../../../helpers/request-helpers'
import Loading from '../../common/Loading'

class AccountSfAccount extends React.Component {
  static _getAccountBySfId (sfId) {
    const sfIdEncoded = encodeURIComponent(sfId)
    const request = {
      method: 'GET',
      url: `/sf-account/${sfIdEncoded}`
    }
    return requestHelpers.makeRequest(request)
  }

  constructor (props) {
    super(props)

    this.state = {}
  }

  componentDidMount () {
    this._mounted = true
    AccountSfAccount._getAccountBySfId(this.props.match.params.sf_id).then(
      (account) => {
        this._mounted && this.setState({ account })
      },
      // Note: it's important to handle errors here
      // instead of a catch() block so that we don't swallow
      // exceptions from actual bugs in components.
      (error) => {
        this._mounted && this.setState({ error })
      }
    )
  }

  componentWillUnmount () {
    // Note: necessary so setState() isn't called in componentDidMount() fetch promise chain
    // if component becomes unmounted
    this._mounted = false
  }

  render () {
    const { error, account } = this.state
    if (error) throw error

    if (!account) return <Loading full />

    const newLocation = {
      pathname: `/accounts/${account.id}`
    }

    return <Redirect to={newLocation} />
  }
}

AccountSfAccount.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      sf_id: PropTypes.string
    })
  }).isRequired
}

export default AccountSfAccount
