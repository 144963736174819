import React from 'react'

import './NotFoundError.scss'

const NotFoundError = () => (
  <div id="vp-NotFoundError" className="vp-NotFoundError">
    <div id="vp-NotFoundError__container" className="vp-NotFoundError__container">
      <div id="vp-NotFoundError__content" className="vp-NotFoundError__content">
        <div className="vp-NotFoundError__heading">
          <span>4</span>
          <span>0</span>
          <span>4</span>
        </div>
        <p>Sorry! We can&apos;t find the page you were looking for.</p>
      </div>
    </div>
  </div>
)

export default NotFoundError
