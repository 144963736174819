import React from 'react'
import { Redirect } from 'react-router'

import productHelpers from 'components/product/shared/product-helpers'
import { getTodaysDate } from 'helpers/utils'
import PROP_TYPES from '../../../../prop-types'
import { Form, Input } from '../../../common/Form'
import Button from '../../../common/Button'
import formHelpers from '../../../../helpers/form-helpers'
import Loading from '../../../common/Loading'
import Select from '../../../common/Form/Select'
import { patchSubscription } from '../../shared/subscription-helpers'
import './SubscriptionEditForm.scss'

const { getAllProducts } = productHelpers

const PRODUCTS_PAGE_SIZE = 1000

function mapSubscriptionToForm (subscription) {
  let endedAt = subscription.ended_at

  if (endedAt) {
    [endedAt] = endedAt.split('T')
  }

  const form = {
    'subscription[name]': subscription.name || '',
    'subscription[vendor_id]': subscription.vendor_id || '',
    'subscription[product_id]': subscription.product_id || '',
    'subscription[quantity]': String(subscription.quantity) || '',
    'subscription[ended_at]': endedAt || ''
  }

  return form
}

class SubscriptionEditForm extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      redirectToSubscription: false,
      submitting: false,
      submitDisabled: true,
      loading: true,
      formErrors: {},
      error: undefined,
      form: {},
      productOptions: []
    }
    this.products = []
    this.getInitialFormValues = this.getInitialFormValues.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.enableSubmit = this.enableSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.handleSelectChange = this.handleSelectChange.bind(this)
    this.handleToday = this.handleToday.bind(this)
    this.mapFormToSubscription = this.mapFormToSubscription.bind(this)
  }

  async componentDidMount () {
    this._mounted = true
    const products = await getAllProducts({ is_active: true }, PRODUCTS_PAGE_SIZE)
    this.products = products
    const productOptions = products.map(product => ({
      value: product.id,
      display: `${product.name} - ${product.udac}`
    }))
    const newFormValues = this.getInitialFormValues()
    this.setState(() => ({
      productOptions,
      form: newFormValues,
      loading: false
    }))
  }

  componentWillUnmount () {
    this._mounted = false
  }

  getInitialFormValues () {
    const { subscription } = this.props
    const form = subscription === undefined ? undefined : mapSubscriptionToForm(subscription)
    return form
  }

  handleInputChange ({ target }) {
    const obj = formHelpers.formatInputChange(target)
    this.setState(prevState => ({ form: { ...prevState.form, ...obj } }))
    this.enableSubmit()
  }

  enableSubmit () {
    if (!this.state.submitting && this.state.submitDisabled) {
      this.setState({ submitDisabled: false })
    }
  }

  mapFormToSubscription () {
    const { subscription } = formHelpers.formatFormParams(this.state.form)

    Object.keys(subscription).forEach(key => (subscription[key] === '') && delete subscription[key])

    return subscription
  }

  handleSelectChange ({ target }) {
    this.handleInputChange({ target })
  }

  async handleSubmit (event) {
    event.preventDefault()
    this.setState({ submitting: true, submitDisabled: true, loading: true })
    const subscription = this.mapFormToSubscription()

    let response
    try {
      response = await patchSubscription(this.props.subscription.id, subscription)
    } catch (error) {
      this.setState({ error })
      return
    }

    if (response.status === 204) {
      this.setState({ redirectToSubscription: true, loading: false })
      return
    }

    const errors = await response.json()
    const formErrors = formHelpers.handleValidationErrors(errors)
    this.setState({ formErrors, submitting: false, loading: false })
  }

  findProduct (productId) {
    const product = this.products.find(p => p.id === productId)

    return product
  }

  handleToday () {
    this.setState(prevState => ({
      form: {
        ...prevState.form,
        'subscription[ended_at]': getTodaysDate()
      }
    }))
    this.enableSubmit()
  }

  render () {
    const {
      error,
      loading,
      redirectToSubscription,
      formErrors,
      productOptions
    } = this.state
    const { subscription } = this.props

    if (error) throw error
    if (loading) return <Loading />

    if (redirectToSubscription) {
      const notice = { message: 'Successfully updated subscription.', type: 'success' }
      const location = {
        pathname: `/subscriptions/${subscription.id}`,
        state: { notice }
      }
      return <Redirect push to={location} />
    }

    const selectedProduct = this.findProduct(
      this.state.form[`subscription[product_id]`]
    )
    const vendorIdType = selectedProduct ? selectedProduct.vendor_id_type : undefined

    return (
      <React.Fragment>
        {this.state.submitting && <Loading />}
        <Form
          id="vp-SubscriptionEditForm"
          cssClass="vp-SubscriptionEditForm"
          onSubmit={this.handleSubmit}
        >
          <Select
            key="product_id"
            options={productOptions}
            labelText="Product"
            id="subscription_product_id"
            name="subscription[product_id]"
            value={this.state.form[`subscription[product_id]`]}
            onChange={this.handleSelectChange}
            formError={formErrors[`subscription.product_id`]}
          />

          <Input
            key="vendor_id"
            type="text"
            labelText={vendorIdType ? `Vendor ID (${vendorIdType})` : 'Vendor ID'}
            id="subscription_vendor_id"
            name="subscription[vendor_id]"
            value={this.state.form[`subscription[vendor_id]`] || ''}
            onChange={this.handleInputChange}
            formError={formErrors[`subscription.vendor_id`]}
          />

          <Input
            key="name"
            type="text"
            labelText="Name"
            id="subscription_name"
            name="subscription[name]"
            value={this.state.form[`subscription[name]`] || ''}
            onChange={this.handleInputChange}
            formError={formErrors[`subscription.name`]}
            tooltip="Name of business, web site, or other description of the subscription."
            maxLength="255"
          />

          <Input
            key="quantity"
            type="number"
            labelText="Quantity"
            id="subscription_quantity"
            name="subscription[quantity]"
            value={this.state.form[`subscription[quantity]`] || ''}
            onChange={this.handleInputChange}
            formError={formErrors[`subscription.quantity`]}
            min="1"
          />

          <div className="vp-CancelDateContainer">
            <Input
              key="ended_at"
              type="date"
              labelText="Cancel date"
              id="subscription_ended_at"
              name="subscription[ended_at]"
              value={this.state.form[`subscription[ended_at]`] || ''}
              onChange={this.handleInputChange}
              formError={formErrors[`subscription.ended_at`]}
              max={getTodaysDate()}
            />

            <Button btnStyle="hollow" onClick={this.handleToday}>
              Today
            </Button>
          </div>

          <Button type="submit" disabled={this.state.submitDisabled}>
            Save
          </Button>
        </Form>
      </React.Fragment>
    )
  }
}

SubscriptionEditForm.propTypes = { subscription: PROP_TYPES.USER.isRequired }

export default SubscriptionEditForm
