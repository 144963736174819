import clients from './api-response-mocks/client-search'
import { locations, location } from './api-response-mocks/locations'

const mockData = {
  '/clients?search=': clients,
  '/clients/1': { ...clients.data[0], locations },
  '/locations/372806': location
}

const mockFetch = (url, req) => {
  const mockedEndpointName = Object.keys(mockData).find(key => url.split('/MOCK')[1].includes(key))

  if (mockedEndpointName) {
    const data = mockData[mockedEndpointName]
    // debugger
    return Promise.resolve({
      status: 200,
      ok: true,
      json () {
        return Promise.resolve(data)
      }
    })
  }
  return Promise.resolve({
    status: 200,
    ok: true,
    json () {
      return Promise.resolve([])
    }
  })
}

export default mockFetch
