import React from 'react'
import propTypes from 'prop-types'

import withLabelAndFormError from '../shared/WithLabelAndFormError'

const CLASS_NAME = {
  default: 'vp-Form__input',
  error: 'vp-Form__input is-invalid-input'
}

const Input = ({ formError, ...rest }) => (
  <input {...rest} className={formError ? CLASS_NAME.error : CLASS_NAME.default} />
)

Input.defaultProps = { formError: undefined }
Input.propTypes = { formError: propTypes.string }

export default withLabelAndFormError(Input)
