/**
 * Search component that keeps track of search history in the location bar.
 *
 * Searches can be performed in two ways:
 *   * using the searchKey, for example "search" in the location bar:
 *     ?search=foobar
 *   * typing into the search input and submitting, ultimately changing
 *     the query params in the location bar.
 *
 * Tracking of whether the search input was used is done by the inputChanged state.
 * Tracking pagination button clicks is done by the pageNumberChanged state.
 */
import React from 'react'
import { withRouter } from 'react-router'
import qs from 'qs'
import PropTypes from 'prop-types'

import { Form, FormError } from '../Form'
import InputSearch from '../InputSearch'
import Loading from '../Loading'
import requestHelper from '../../../helpers/request-helpers'
import formHelpers from '../../../helpers/form-helpers'
import {
  GlobalContextConsumer,
  GLOBAL_PROP_TYPES,
  GLOBAL_PROP_DEFAULTS
} from '../../../helpers/global-context'
import Pagination from '../Pagination'
import Table from '../Table'

class Search extends React.Component {
  static getDerivedStateFromProps (nextProps, state) {
    if (nextProps.location.search.startsWith(`?${nextProps.searchKey}=`)) {
      return Search.getSearchFromProps(nextProps, state)
    }
    // we have previously searched, but gone "back" enough times to no search
    // in the location bar, meaning we need to completely reset state
    if (state.searched) {
      return {
        value: '',
        pageNumber: undefined,
        results: undefined,
        searched: false,
        searching: false
      }
    }
    return null
  }

  static getSearchFromProps (props, state) {
    const qstr = qs.parse(props.location.search.slice(1))
    const updateState = {
      inputChanged: false,
      pageNumberChanged: false
    }
    if (state && state.inputChanged) {
      updateState.value = state.value
      updateState.pageNumber = undefined // reset pagination state on new search input
    } else if (state && state.pageNumberChanged) {
      updateState.value = qstr[props.searchKey] || ''
      updateState.pageNumber = state.pageNumber
    } else {
      updateState.pageNumber = qstr.page_number || ''
      updateState.value = qstr[props.searchKey] || ''
    }
    updateState.extraQueryParams = qstr.extraQueryParams || props.extraQueryParams

    return updateState
  }

  constructor (props) {
    super(props)
    this.state = {
      value: '',
      inputChanged: false, // eslint-disable-line react/no-unused-state
      pageNumberChanged: false, // eslint-disable-line react/no-unused-state
      pageNumber: undefined,
      searched: false,
      searching: false,
      extraQueryParams: ''
    }
    this.tableContainer = null
    this.handleChange = this.handleChange.bind(this)
    this.handlePageChange = this.handlePageChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.doSearch = this.doSearch.bind(this)
    this.setStateFromSearchResults = this.setStateFromSearchResults.bind(this)
    this.renderResults = this.renderResults.bind(this)
    this.scrollToTop = this.scrollToTop.bind(this)
  }

  componentDidMount () {
    const { location, globalState, searchKey } = this.props
    this._mounted = true
    if (location.search.startsWith(`?${searchKey}=`)) {
      this.doSearch().then(
        this._mounted && this.setStateFromSearchResults,
        error => this._mounted && this.setState({ error })
      )
    } else if (globalState && location && location.pathname && location.pathname in globalState) {
      this.reconstructPreviousState()
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (!this.state.searched && this.props.location.search) {
      // first time searching
      // while disposing this function could be called, with unbinded function
      if (this.doSearch()) {
        this.doSearch().then(this.setStateFromSearchResults, error => this.setState({ error }))
      }
    } else if (
      this.state.searched &&
      prevProps.location.search !== this.props.location.search &&
      this.props.location.search !== ''
    ) {
      this.doSearch().then(this.setStateFromSearchResults, error => this.setState({ error }))
    }
  }

  componentWillUnmount () {
    this._mounted = false
  }

  setStateFromSearchResults (results) {
    this.setState({
      results,
      searching: false
    })
  }

  reconstructPreviousState () {
    const { globalState, location } = this.props
    const { search } = globalState[location.pathname]
    this.pushState(location.pathname, search)
  }

  pushState (pathname, params) {
    const { history, setGlobalState } = this.props
    history.push(pathname + params)
    setGlobalState({ [pathname]: { search: params } })
  }

  formatSearchParams () {
    let search = `?${this.props.searchKey}=${encodeURIComponent(this.state.value)}`
    if (this.state.pageNumber) {
      search += `&page_number=${encodeURIComponent(this.state.pageNumber)}`
    }

    let { extraQueryParams } = this.state
    if (extraQueryParams) {
      if (Array.isArray(extraQueryParams)) {
        extraQueryParams = extraQueryParams.join('&')
      }
      search += `&${extraQueryParams}`
    }

    return search
  }

  async handleSubmit (e) {
    e.preventDefault()
    const params = this.formatSearchParams()
    if (params !== this.props.location.search) {
      this.pushState(this.props.location.pathname, params)
    } else {
      this.doSearch().then(this.setStateFromSearchResults, error => this.setState({ error }))
    }
  }

  async doSearch () {
    if (this.state.searching) return null
    this.setState({ searching: true, searched: true, formError: undefined })
    const searchParams = this.formatSearchParams()
    let results
    try {
      results = await requestHelper.makeRequest({
        method: 'GET',
        url: this.props.apiUrl + searchParams
      })
    } catch (error) {
      this.setState({ error })
    }

    if (results.status === 400) {
      const formError = formHelpers.handleValidationErrors(results)
      this.setState({ formError })
      return []
    }
    return results
  }

  handleChange ({ target }) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ value: target.value, inputChanged: true })
  }

  scrollToTop () {
    if (
      this.tableContainer.parentElement.getBoundingClientRect().top < 0 &&
      this.tableContainer.parentElement.scrollIntoView
    ) {
      this.tableContainer.parentElement.scrollIntoView()
    }
  }

  handlePageChange (pageNumber) {
    this.scrollToTop()
    this.setState(
      { pageNumber, pageNumberChanged: true }, // eslint-disable-line react/no-unused-state
      () => {
        const params = this.formatSearchParams()
        this.pushState(this.props.location.pathname, params)
      }
    )
  }

  renderResults (results) {
    const displayPaging = results.next_page || results.previous_page
    return (
      <div
        ref={(node) => {
          this.tableContainer = node
        }}
      >
        {displayPaging && (
          <Pagination
            nextPage={results.next_page}
            previousPage={results.previous_page}
            onPageChange={this.handlePageChange}
            position="above"
          />
        )}
        <Table rowKey={this.props.rowKey} headers={this.props.headers} rows={results.data} />
        {displayPaging && (
          <Pagination
            nextPage={results.next_page}
            previousPage={results.previous_page}
            onPageChange={this.handlePageChange}
          />
        )}
      </div>
    )
  }

  render () {
    const {
      results, searched, searching, error, value, formError
    } = this.state
    if (error) throw error

    return (
      <div className="vp-Search">
        {searching && <Loading />}
        <Form onSubmit={this.handleSubmit}>
          <InputSearch
            name="search"
            htmlId={this.props.inputId}
            placeholder={this.props.placeholder}
            onChange={this.handleChange}
            value={value}
          />
        </Form>
        <FormError message={formError && formError.search} />
        {results &&
          results.data &&
          results.data.length > 0 &&
          this.renderResults(results, searching, searched)}
        {results && results.data && results.data.length === 0 && searched && !searching && (
          <p>No results</p>
        )}
      </div>
    )
  }
}

Search.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }).isRequired,
  location: PropTypes.shape({
    search: PropTypes.string,
    pathname: PropTypes.string,
    extraQueryParams: PropTypes.string
  }).isRequired,
  placeholder: PropTypes.string.isRequired,
  apiUrl: PropTypes.string.isRequired,
  searchKey: PropTypes.string.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      accessor: PropTypes.string,
      settings: PropTypes.object
    })
  ).isRequired,
  rowKey: PropTypes.string.isRequired,
  inputId: PropTypes.string.isRequired,
  // eslint-disable-next-line react/no-unused-prop-types
  extraQueryParams: PropTypes.arrayOf(PropTypes.string),
  ...GLOBAL_PROP_TYPES
}
Search.defaultProps = {
  extraQueryParams: undefined,
  ...GLOBAL_PROP_DEFAULTS
}

const SearchContext = props => (
  <GlobalContextConsumer>{context => <Search {...props} {...context} />}</GlobalContextConsumer>
)
export const routerSearch = withRouter(Search)
export default withRouter(SearchContext)
