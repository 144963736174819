import React from 'react'
import PropTypes from 'prop-types'
import PROP_TYPES from '../../../../prop-types'
import Table from '../../../common/Table'
import Card from '../../../common/Card'
import collapsible from '../../../common/shared/Collapsible'

const APP_CONFIG_HEADERS = [
  {
    name: 'Application',
    accessor: 'application_id'
  },
  {
    name: 'Modules',
    accessor: 'modules',
    settings: { type: 'arrayToSring' }
  }
]

const getTitleCollapseButton = (collapseHandlerAction, collapsed) => ({
  icon: collapsed ? 'chevron-down' : 'chevron-up',
  clickHandler: collapseHandlerAction,
  props: {
    btnStyle: 'clear'
  }
})

const CollapsibleCard = collapsible(Card)

const AppConfigCard = ({ appConfigs, loadMoreAppConfigs }) => (
  <CollapsibleCard
    title="Application Config"
    getCollapseButton={getTitleCollapseButton}
    initiateCollapsed
  >
    {appConfigs && appConfigs.length ? (
      <Table
        rowKey="id"
        headers={APP_CONFIG_HEADERS}
        rows={appConfigs}
        rowContent="config"
        loadMoreRows={loadMoreAppConfigs}
      />
    ) : (
      <div className="vp-ProductView__details-container">
        <div className="vp-ProductView__details-content">
          <p>No product application config</p>
        </div>
      </div>
    )}
  </CollapsibleCard>
)

AppConfigCard.defaultProps = {
  appConfigs: [],
  loadMoreAppConfigs: undefined
}

AppConfigCard.propTypes = {
  appConfigs: PropTypes.arrayOf(PROP_TYPES.APP_CONFIG),
  loadMoreAppConfigs: PropTypes.func
}

export default AppConfigCard
