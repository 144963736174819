import React from 'react'

import Page from '../../common/Page'
import Search from '../../common/Search'
import HttpError from '../../../helpers/errors'

import permissionHelper from '../../../helpers/permissions-helpers'

import './AccountSearch.scss'

const HEADERS = [
  {
    name: 'Account Name',
    accessor: 'name',
    settings: {
      type: 'link',
      basePath: '/accounts',
      accessor: 'id'
    }
  },
  { name: 'Phone', accessor: 'phone' },
  { name: 'Account Number', accessor: 'account_number' },
  { name: 'Enterprise Customer ID', accessor: 'enterprise_customer_id' }
]

const AccountSearch = () => {
  if (!permissionHelper.hasPerm({ policies: ['account:read'] })) {
    throw new HttpError(403)
  }

  return (
    <Page title="Accounts">
      <div className="vp-AccountSearch">
        <Search
          searchKey="search"
          apiUrl="/accounts"
          placeholder="Search by account name, enterprise customer ID, or account number"
          headers={HEADERS}
          rowKey="id"
          inputId="search"
        />
      </div>
    </Page>
  )
}

export default AccountSearch
