const clientSearch = {
  page_size: 10,
  page_number: 1,
  next_page: 2,
  data: [
    {
      id: 1,
      account_id: 2,
      name: 'Bobby Table',
      type: 'account',
      active: true,
      keywords: ['test', 'key', 'words'],
      website_url: 'https://vivial.net',
      newsletter_settings: { theme: 'dark' },
      created_at: '2019-03-28T18:26:56.837Z',
      updated_at: '2019-03-28T18:26:56.837Z',
      inactive_at: '',
      social_media: [
        {
          id: 1,
          client_id: 3,
          profile_name: 'Vivial',
          url: 'https://www.facebook.com/VIVIAL/',
          type: 'Facebook'
        }
      ]
    }
  ]
}

export default clientSearch
