import React from 'react'
import PropTypes from 'prop-types'

import hoverable from '../shared/Hoverable'
import { getHoverableButtonStyle } from '../shared/Hoverable/helpers'

import './Button.scss'

const BasicButton = ({ children, ...rest }) => (
  <button type="button" {...rest} className="vp-Button">
    {children}
  </button>
)
BasicButton.propTypes = { children: PropTypes.node.isRequired }
const HoverableBasicButton = hoverable(BasicButton)

const Button = ({
  children, color, bgColor, btnStyle, ...rest
}) => (
  <HoverableBasicButton
    {...rest}
    style={getHoverableButtonStyle({
      color,
      bgColor,
      btnStyle
    })}
  >
    {children}
  </HoverableBasicButton>
)

Button.defaultProps = {
  color: undefined,
  bgColor: undefined,
  btnStyle: 'default'
}
Button.propTypes = {
  children: PropTypes.node.isRequired,
  color: PropTypes.string,
  bgColor: PropTypes.string,
  btnStyle: PropTypes.oneOf(['default', 'hollow', 'clear'])
}

export default Button
