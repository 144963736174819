import React from 'react'
import PropTypes from 'prop-types'

import Page from 'components/common/Page'
import Loading from 'components/common/Loading'
import withLocationGet from 'components/location/shared/with-location-get'
import LocationDetailsCard from 'components/location/LocationView/LocationDetailsCard'
import LocationContactCard from 'components/location/LocationView/LocationContactCard'
import LocationAssetsCard from 'components/location/LocationView/LocationAssetsCard'
import permissionHelper from 'helpers/permissions-helpers'

import 'components/location/LocationView/LocationView.scss'

const {
  REACT_APP_CLIENT_ADMIN_URL,
  REACT_APP_DASHBOARD_URL
} = process.env

function cladminView (data) {
  return permissionHelper.canSeeCladminLink() ? {
    href: `${REACT_APP_CLIENT_ADMIN_URL}/${data.client_id}/locations/${data.id}`,
    text: 'Client Admin',
    id: 'vp-CladminView'
  } : null
}

function dashboardView (data) {
  return permissionHelper.canSeeDashboardLink() ? {
    href: `${REACT_APP_DASHBOARD_URL}/goto/${data.id}`,
    text: 'Marketing Platform',
    id: 'vp-DashboardView'
  } : null
}

function getHeaderLinks (data) {
  return [cladminView(data), dashboardView(data)].filter(link => link)
}

export const LocationView = ({ data }) => {
  const location = data

  if (!location.name) {
    return <Loading />
  }

  return (
    <Page title={`${location.address_street1}`} externalLinks={getHeaderLinks(location)}>
      <div id="vp-LocationView" className="vp-LocationView">
        <LocationDetailsCard object={location} title="Location Details" />

        <LocationContactCard object={location} title="Contact Information" />

        <LocationAssetsCard object={location} title="Location Assets" />
      </div>
    </Page>
  )
}

const propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.integer,
    account_id: PropTypes.integer,
    website_url: PropTypes.string,
    keywords: PropTypes.array
  }).isRequired
}

LocationView.propTypes = propTypes

export default withLocationGet(LocationView)
