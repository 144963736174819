import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Card from '../../../common/Card'
import './UserPlatformRoles.scss'

const UserPlatformRoles = ({ children, cardProps, id }) => (
  <div id={id} className="vp-UserPlatformRoles__details">
    <Card {...cardProps}>
      <div className="vp-UserPlatformRoles__details-container">
        <div
          className="vp-UserPlatformRoles__details-content
          vp-UserPlatformRoles__details-content--fullwidth"
        >
          <Fragment>{children}</Fragment>
        </div>
      </div>
    </Card>
  </div>
)

UserPlatformRoles.defaultProps = {
  cardProps: {
    title: 'Platform Roles'
  }
}

UserPlatformRoles.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  id: PropTypes.string.isRequired,
  cardProps: PropTypes.shape({
    title: PropTypes.string.isRequired,
    titleLink: PropTypes.shape({
      to: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          pathname: PropTypes.string,
          state: PropTypes.object
        })
      ]),
      href: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string
    }),
    collapseButton: PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.string,
      props: PropTypes.object,
      clickHandler: PropTypes.func
    }),
    collapsed: PropTypes.bool,
    externalLink: PropTypes.shape({
      href: PropTypes.string,
      text: PropTypes.string,
      icon: PropTypes.string
    })
  })
}

export default UserPlatformRoles
