const location1 = {
  id: '2',
  client_id: '1',
  is_primary: true,
  is_service_area_business: false,
  name: "Zach's Store",
  address_street1: '434 5th Ave',
  address_street2: 'Suite 3',
  address_city: 'Tulsa',
  address_state: 'MN',
  address_postal_code: '55404',
  address_country: 'USA',
  phone: '320-340-2324',
  latitude: 35.34234,
  longitude: 43.345243,
  service_area_business_street: '',
  service_area_business_city: '',
  service_area_business_state: '',
  service_area_business_zip: '',
  hours: [],
  hours_notes: '',
  website_url: 'https://vivial.net',
  email: 'test@example.com',
  description: 'Just put a star',
  tagline: 'This is a tagline.',
  logo_url: 'https://vp.vivialdev.net/img/Vivial_logo_rgb.png',
  photo_url: 'https://vp.vivialdev.net/img/Vivial_Favicon-1-180x180.png',
  deleted_at: '2019-04-09T20:32:13.075Z',
  created_at: '2019-04-02T20:32:13.075Z',
  updated_at: '2019-04-02T20:32:13.075Z'
}

const location2 = {
  id: 1,
  client_id: 2,
  is_primary: false,
  is_service_area_business: false,
  name: 'Other Store',
  address_street1: '33 7th St',
  address_street2: '',
  address_city: 'Gary',
  address_state: 'IN',
  address_postal_code: '55404',
  address_country: 'US',
  phone: '323-340-2354',
  latitude: 35.34234,
  longitude: 43.345243,
  service_area_business_street: '',
  service_area_business_city: '',
  service_area_business_state: '',
  service_area_business_zip: '',
  hours: [],
  hours_notes: '',
  website_url: 'https://vivial.net',
  email: 'test@example.com',
  description: 'Just put a star',
  tagline: 'this is a tagline',
  logo_url: 'https://vivial.net/logo',
  photo_url: 'https://vivial.net/photo',
  deleted_at: '',
  created_at: '2019-04-02T20:32:13.075Z',
  updated_at: '2019-04-02T20:32:13.075Z'
}

const locations = [location1, location2]
const location = location1

export { locations, location }
