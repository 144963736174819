import React from 'react'
import PROP_TYPES from '../../../prop-types'
import { getSubscription } from './subscription-helpers'
import { getAccount } from '../../account/shared/account-helpers'

function withSubscriptionGet (
  WrappedComponent
) {
  class WithSubscriptionGet extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        data: {},
        error: undefined
      }
    }

    async componentDidMount () {
      this._mounted = true
      await this.getAllData()
    }

    async componentDidUpdate (prevProps) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        await this.getAllData()
      }
    }

    componentWillUnmount () {
      // Note: necessary so setState() isn't called in
      // componentDidMount() fetch promise chain if
      // component becomes unmounted
      this._mounted = false
    }

    async getAllData () {
      const { id } = this.props.match.params

      try {
        const subscription = await getSubscription(id)
        const accountId = subscription.account_id

        const { account } = await getAccount(accountId, false, false)

        const data = {
          subscription,
          account_name: account.sf_account.name
        }

        if (this._mounted) {
          this.setState({
            data
          })
        }
      } catch (error) {
        this._mounted && this.setState({ error })
      }
    }

    render () {
      if (this.state.error) throw this.state.error
      return (
        <WrappedComponent
          data={this.state.data}
          {...this.props}
        />
      )
    }
  }

  WithSubscriptionGet.displayName =
    `WithSubscriptionGet(${getDisplayName(WrappedComponent)})`
  WithSubscriptionGet.propTypes = {
    match: PROP_TYPES.ID_IN_PATH.isRequired
  }

  return WithSubscriptionGet
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withSubscriptionGet
