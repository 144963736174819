import React from 'react'
import PropTypes from 'prop-types'

import gaHelpers from '../../../helpers/google-analytics-helpers'

function withPageTracker (WrappedComponent) {
  class WithPageTracker extends React.Component {
    componentDidMount () {
      const page = this.props.location.pathname
      gaHelpers.trackPage(page)
    }

    componentWillReceiveProps (nextProps) {
      const currentPage = this.props.location.pathname
      const nextPage = nextProps.location.pathname

      if (currentPage !== nextPage) {
        gaHelpers.trackPage(nextPage)
      }
    }

    render () {
      return <WrappedComponent {...this.props} />
    }
  }
  WithPageTracker.displayName = `WithPageTracker(${getDisplayName(WrappedComponent)})`
  WithPageTracker.propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired
    }).isRequired
  }

  return WithPageTracker
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withPageTracker
