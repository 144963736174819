import React from 'react'
import PROP_TYPES from '../../../prop-types'
import { getUser } from './user-helpers'

function withUserGet (WrappedComponent) {
  class WithUserGet extends React.Component {
    constructor (props) {
      super(props)
      this.state = { data: {}, error: undefined }
      this.refresh = this.refresh.bind(this)
    }

    componentDidMount () {
      this._mounted = true
      getUser(this.props.match.params.id).then(
        data => this._mounted && this.setState({ data }),
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        error => this._mounted && this.setState({ error })
      )
    }

    componentWillUnmount () {
      // Note: necessary so setState() isn't called in componentDidMount() fetch promise chain
      // if component becomes unmounted
      this._mounted = false
    }

    async refresh () {
      try {
        await getUser(this.props.match.params.id).then(
          data => this._mounted && this.setState({ data }),
          error => this._mounted && this.setState({ error })
        )
      } catch (error) {
        this.setState({ error })
      }
    }

    render () {
      if (this.state.error) throw this.state.error

      return <WrappedComponent data={this.state.data} refresh={this.refresh} {...this.props} />
    }
  }
  WithUserGet.displayName = `WithUserGet(${getDisplayName(WrappedComponent)})`
  WithUserGet.propTypes = {
    match: PROP_TYPES.ID_IN_PATH.isRequired
  }

  return WithUserGet
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withUserGet
