import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Loading from 'components/common/Loading'
import Page from 'components/common/Page'
import Button from 'components/common/Button'
import Card from 'components/common/Card'
import DataRow from 'components/common/DataRow'
import Table from 'components/common/Table'
import { formatPermissions } from 'helpers/utils'
import { defaultColors } from 'helpers/theme-helpers'
import PROP_TYPES from '../../../prop-types'
import userAccountPermissionsGet from '../shared/user-account-permissions-get'
import { NO_PERMISSIONS } from '../UserAccountPermissions'

import './UserEditPermissions.scss'

const noop = () => {}
const notice = {
  message: 'You must specify at least one permission to save changes',
  type: 'error'
}

const PERMISSIONS_HEADERS = [
  { name: 'Client', accessor: 'client' },
  { name: 'Roles', accessor: 'roles' },
  {
    name: '',
    accessor: 'id',
    settings: {
      type: 'actions',
      actions: [{
        text: 'X',
        clickHandler: noop,
        props: {
          btnStyle: 'clear',
          color: defaultColors.darkGray
        }
      }]
    }
  }
]

const setActionHandler = (action, handler) => {
  PERMISSIONS_HEADERS[2].settings.actions[action].clickHandler = handler
}

export const UserEditPermissions = ({
  account, user, permissions, updatePermissions
}) => {
  const [newPermissions, setNewPermissions] = useState(permissions)
  const [formattedPermissions, setFormattedPermissions] = useState(formatPermissions(permissions))

  useEffect(() => {
    setNewPermissions(permissions)
    setFormattedPermissions(formatPermissions(permissions))
  }, [permissions])

  if (!account || !user) {
    return <Loading />
  }

  const {
    sf_account: { name: accountName, account_number: accountNumber }
  } = account

  const { first_name: firstName = '', last_name: lastName = '', email } = user
  const userName = `${firstName} ${lastName}`.trim()

  const pathname = `/users/${user.id}/account-permissions/${account.id}/add`
  const addTitleLink = {
    to: { pathname, state: { permissions: newPermissions } },
    text: 'Add',
    icon: 'plus'
  }

  setActionHandler(0, (id) => {
    setFormattedPermissions(formattedPermissions.filter(p => p.id !== id))
    setNewPermissions(newPermissions.filter(p => p.id !== id))
  })

  const handleSave = () => {
    updatePermissions(newPermissions)
  }

  const havePermissions = formattedPermissions.length > 0
  const permissionsContent = havePermissions ? (
    <Table rowKey="id" headers={PERMISSIONS_HEADERS} rows={formattedPermissions} />
  ) : (
    <div className="vp-EditPermissions__details-container">
      <div className="vp-EditPermissions__details-content">
        <p id="permissions-message">{NO_PERMISSIONS}</p>
      </div>
    </div>
  )

  return (
    <Page title="Edit Permissions" showBack notice={!havePermissions ? notice : null}>
      <div className="vp-EditPermissionsView">
        <div id="vp-EditPermissions__accountSummary" className="vp-EditPermissions__details">
          <Card title="User / Account Summary">
            <div className="vp-EditPermissions__details-container">
              <div className="vp-EditPermissions__details-content">
                <DataRow property="user" title="User" value={userName} />
                <DataRow property="email" title="Email" value={email} />
              </div>
              <div className="vp-EditPermissions__details-content">
                <DataRow property="account" title="Account" value={accountName} />
                <DataRow property="number" title="Account Number" value={accountNumber} />
              </div>
            </div>
          </Card>
        </div>
        <div id="vp-EditPermissions__PermissionsTable" className="vp-EditPermissions__details">
          {permissions && (
            <Card title="Permissions" titleLink={addTitleLink}>
              {permissionsContent}
            </Card>
          )}
        </div>
      </div>
      <Button onClick={handleSave} disabled={!havePermissions}> Save </Button>
    </Page>
  )
}

const propTypes = {
  account: PROP_TYPES.ACCOUNT,
  user: PROP_TYPES.USER,
  permissions: PROP_TYPES.ACCOUNT_PERMISSIONS,
  updatePermissions: PropTypes.func.isRequired
}

UserEditPermissions.propTypes = propTypes
UserEditPermissions.defaultProps = {
  account: undefined,
  user: undefined,
  permissions: undefined
}

export default userAccountPermissionsGet(UserEditPermissions)
