import tinycolor from 'tinycolor2'

export const defaultColors = {
  white: '#fefefe',
  lightGray: '#e6e6e6',
  mediumGray: '#cacaca',
  darkGray: '#8a8a8a',
  black: '#0a0a0a',
  success: '#3adb76', // bright green
  warning: '#ffae00', // orange
  alert: '#cc4b37' // rusty red
}

export const smartScale = (color, amount = 10) => {
  const tiny = tinycolor(color)
  return tiny.isDark() ? tiny.lighten(amount) : tiny.darken(amount)
}

export const darken = (color, amount = 10) => tinycolor(color)
  .darken(amount)
  .toString()

export const lighten = (color, amount = 10) => tinycolor(color)
  .lighten(amount)
  .toString()

export const pickMostReadable =
  (base, colors =
  [defaultColors.black, defaultColors.white]) => tinycolor.mostReadable(base, colors).toString()

const themed = {
  primary: '#04867e', // dark teal
  // primary: '#3adb76',
  secondary: '#676767' // gray brown
}

const theme = {
  colors: { ...themed },
  components: {
    Header: { backgroundColor: '#676767', color: defaultColors.white },
    Avatar: { backgroundColor: themed.primary },
    SidebarMenu: { backgroundColor: themed.primary }
  }
}

export default theme
