import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import Raven from 'raven-js'

import AppLayout from 'components/common/AppLayout'
import InternalError from './InternalError'
import NotFoundError from './NotFoundError'
import ForbiddenError from './ForbiddenError'
import ROUTES_CONFIG from '../../../config/routes'

const menuItemsFromConfig = ROUTES_CONFIG.filter(cfg => cfg.menu).map(cfg => cfg.menu)

class ErrorBoundary extends React.Component {
  constructor (props) {
    super(props)
    const { history } = props

    this.state = { hasError: false }

    history.listen((location, action) => {
      if (this.state.hasError) {
        this.setState({ hasError: false })
      }
    })
  }

  componentWillUnmount () {
    this.setState({ hasError: false })
  }

  componentDidCatch (error, info) {
    if (error.status) {
      const { message, displayErrorMessage } = error
      const state = {
        hasError: true,
        status: error.status
      }
      if (displayErrorMessage && message) {
        state.message = message
      }
      this.setState({ ...state })
    } else {
      this.setState({ hasError: true })
    }
    if (Raven.isSetup()) {
      const userData = window.localStorage.getItem('current_user')
      Raven.captureException(error, { extra: { info, userData } })
    }
  }

  render () {
    if (this.state.hasError) {
      switch (this.state.status) {
        case 404:
          return (
            <AppLayout menuItems={menuItemsFromConfig}>
              <NotFoundError />
            </AppLayout>
          )
        case 403:
          return (
            <AppLayout menuItems={menuItemsFromConfig}>
              <ForbiddenError />
            </AppLayout>
          )
        default:
          return (
            <AppLayout menuItems={menuItemsFromConfig}>
              <InternalError message={this.state.message} />
            </AppLayout>
          )
      }
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  history: PropTypes.shape({ listen: PropTypes.func }).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.element]).isRequired
}

export default withRouter(ErrorBoundary)
