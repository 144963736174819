import React from 'react'
import PropTypes from 'prop-types'
import MenuItem from './MenuItem'

import './Menu.scss'

const Menu = ({ menuItems, handleMenuItemClick }) => (
  <ul className="vp-Menu">
    {menuItems.map(({ iconName, label, path }) => (
      <MenuItem
        key={label.toLowerCase()}
        iconName={iconName}
        label={label}
        path={path}
        handleMenuItemClick={handleMenuItemClick}
      />
    ))}
  </ul>
)

Menu.propTypes = {
  /** MenuItem props */
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      /** font awesome icon name */
      iconName: PropTypes.string,
      /** MenuItem label */
      label: PropTypes.string.isRequired
    })
  ).isRequired,
  handleMenuItemClick: PropTypes.func.isRequired
}

export default Menu
