import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import { Icon } from '@vivialplatform/ui-shared-lib'
import affiliatesHelper from '../../../helpers/affiliates-request-helper'

import {
  GlobalContextConsumer,
  GLOBAL_PROP_TYPES,
  GLOBAL_PROP_DEFAULTS
} from '../../../helpers/global-context'
import Page from '../../common/Page'
import requestHelpers from '../../../helpers/request-helpers'
import formHelpers from '../../../helpers/form-helpers'
import SearchFilter from './SearchFilter/index'
import SearchTable from './SearchTable/index'
import HttpError from '../../../helpers/errors'
import {
  getSearchStringFromObj,
  getInitialSearchObj,
  removeEmptiesFromObj,
  mapStateToRequest,
  applyBusinessLogic,
  debounce
} from './user-search-helpers'
import permissionHelper from '../../../helpers/permissions-helpers'

import './UserSearch.scss'

const headerLinks = [{
  to: '/users/new',
  href: '/users/new',
  text: 'New User',
  icon: 'plus',
  id: 'vp-NewUser'
}]

const getUsers = url => requestHelpers.makeRequest({ method: 'GET', url })

const CONST_SEARCH_OBJECT = {
  search: null,
  createdAfter: '',
  createdBefore: '',
  userActive: '',
  userType: '',
  userPartner: '',
  pageNumber: ''
}

class UserSearch extends React.Component {
  constructor () {
    super()

    this.doSearchDebounce = debounce(modifiedObj => this.doSearch(modifiedObj), 300)
  }

  state = {
    userResults: {},
    userPartnerList: [],

    searching: false,
    hasSearched: false,
    filterOpen: false,
    lastSearchRequestId: undefined,

    formError: undefined,
    error: undefined,

    searchObject: CONST_SEARCH_OBJECT
  }

  async componentDidMount () {
    this._mounted = true
    const initialState = getInitialSearchObj(this.props)

    this.mountedSetState({ filterOpen: Boolean(Object.keys(initialState).length) })

    affiliatesHelper.getAffiliates().then((userPartnerList) => {
      this.mountedSetState({
        userPartnerList
      })
    })

    const initialSearchObj = {
      ...this.state.searchObject,
      ...initialState
    }

    const modifiedObj = applyBusinessLogic(initialSearchObj)
    this.updateAllStates(modifiedObj)
    // Holds off on doing search until user initiates
    if (modifiedObj.search !== null) {
      await this.doSearch(modifiedObj)
    } else {
      this.setState({ searching: false })
    }
  }

  componentWillUnmount () {
    this._mounted = false
  }

  mountedSetState = (state) => {
    if (this._mounted) {
      this.setState(state)
    }
  }

  updateAllStates = (newSearchObject) => {
    // This is the only place these functions get called
    // It updates the url, localstorage, component state
    // All at once to keep everything consistent
    this.setLocalStorage(newSearchObject)
    this.setUrl(newSearchObject)
    this.setComponentState(newSearchObject)
  }

  setLocalStorage = (searchObject) => {
    const { setGlobalState, location } = this.props
    setGlobalState({ [location.pathname]: { searchObject } })
  }

  setUrl = (searchObject) => {
    const { history, location } = this.props
    const filteredSearchObject = removeEmptiesFromObj(searchObject)
    history.push(location.pathname + getSearchStringFromObj(filteredSearchObject))
  }

  setComponentState = (searchObject) => {
    this.mountedSetState({ searchObject })
  }

  inputChange = field => async ({ target: { value } }) => {
    const newSearchObject = { ...this.state.searchObject }
    newSearchObject[field] = value

    const modifiedObj = applyBusinessLogic(newSearchObject, field, value)
    this.updateAllStates(modifiedObj)

    if (field === 'search') {
      await this.doSearchDebounce(modifiedObj)
    } else {
      await this.doSearch(modifiedObj)
    }
  }

  toggleFilter = () => {
    this.mountedSetState(prevState => ({ filterOpen: !prevState.filterOpen }))
  }

  doSearch = async (searchObject = this.state.searchObject) => {
    const searchRequestId = Date.now()
    this.mountedSetState({
      searching: true,
      formError: undefined,
      hasSearched: true,
      lastSearchRequestId: searchRequestId
    })

    const filteredObj = removeEmptiesFromObj(searchObject)
    const snakeCaseObj = mapStateToRequest(filteredObj)
    const searchString = getSearchStringFromObj(snakeCaseObj)

    let results
    try {
      results = await getUsers(`/users${searchString}`)
      if (searchRequestId !== this.state.lastSearchRequestId) {
        // a latest search request is being processed
        return
      }
    } catch (error) {
      this.mountedSetState({ error })
    }

    if (results && results.status === 400) {
      const formError = formHelpers.handleValidationErrors(results)
      this.mountedSetState({ formError })
    } else {
      this.mountedSetState({
        userResults: results,
        searching: false
      })
    }
  }

  getNoResults = () => {
    const { hasSearched, searching, userResults } = this.state
    if (hasSearched && !searching && userResults.data && !userResults.data.length) {
      return <p id="vp-UserSearch__noResults" className="vp-UserSearch__noResults">No Results</p>
    }
    return null
  }

  clearSearch = async () => {
    this.updateAllStates(CONST_SEARCH_OBJECT)
    // Remove this next line when we getr a better date picker
    document.forms[0].reset()
    await this.doSearch(CONST_SEARCH_OBJECT)
  }

  render () {
    const {
      userResults,
      userPartnerList,
      searchObject,
      error,
      formError,
      searching,
      filterOpen
    } = this.state
    if (error) throw error

    if (!permissionHelper.hasPerm({ policies: ['user:read'] })) {
      throw new HttpError(403)
    }

    return (
      <Page title="Users" headerLinks={headerLinks}>
        <SearchFilter
          userPartnerList={userPartnerList}
          searchObject={searchObject}
          formError={formError}
          filterOpen={filterOpen}
          inputChange={this.inputChange}
          toggleFilter={this.toggleFilter}
          doSearch={this.doSearch}
        />
        <div
          id="vp-SearchFilter__clearFiltersContainer"
          className="vp-SearchFilter__clearFiltersContainer"
        >
          <button
            id="vp-SearchFilter__clearFiltersInput"
            type="button"
            className={`vp-SearchFilter__clearFiltersInput ${!filterOpen ? '--collapsed' : ''}`}
            onClick={this.clearSearch}
          >
            <span
              data-tooltip
              className="vp-UserSearch__icon"
              title="Clear Filters"
            >
              <Icon icon={['fal', 'eraser']} />
            </span>
          </button>
        </div>
        <SearchTable
          searching={searching}
          userResults={userResults}
          inputChange={this.inputChange}
        />

        {this.getNoResults()}
      </Page>
    )
  }
}

UserSearch.propTypes = {
  history: PropTypes.shape({ push: PropTypes.func }),
  ...GLOBAL_PROP_TYPES
}
UserSearch.defaultProps = {
  history: undefined,
  ...GLOBAL_PROP_DEFAULTS
}

const SearchContext = props => (
  <GlobalContextConsumer>{context => <UserSearch {...props} {...context} />}</GlobalContextConsumer>
)
export default withRouter(SearchContext)
