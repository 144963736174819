import React, { Component } from 'react'
import PropTypes from 'prop-types'
import {
  Input, Select, Form, FormError
} from '../../../common/Form'
import './SearchFilter.scss'
import InputSearch from '../../../common/InputSearch'

const filterFields = {
  search: {
    htmlId: 'vp-SearchFilter__searchQuery',
    filter: true,
    label: '',
    placeholder: 'Search by first name, last name, or email'
  },
  createdAfter: {
    id: 'vp-SearchFilter__createdAfter',
    type: 'date',
    labelText: 'Created After',
    placeholder: 'YYYY/MM/DD'
  },
  createdBefore: {
    id: 'vp-SearchFilter__createdBefore',
    type: 'date',
    labelText: 'Created Before',
    placeholder: 'YYYY/MM/DD'
  },
  userType: {
    id: 'vp-SearchFilter__userType',
    labelText: 'User Type',
    options: [
      { value: '', display: 'All' },
      { value: 'CLIENT', display: 'Client' },
      { value: 'INTERNAL', display: 'Internal' },
      { value: 'AFFILIATE', display: 'Partner' }
    ]
  },
  userActive: {
    id: 'vp-SearchFilter__userActive',
    labelText: 'User Status',
    options: [
      { value: 'all', display: 'All' },
      { value: '', display: 'Active' },
      { value: 'inactive', display: 'Inactive' }
    ]
  },
  userPartner: {
    id: 'vp-SearchFilter__userPartner',
    labelText: 'User Partner'
  }
}

class SearchFilter extends Component {
  handleSubmit = (e) => {
    e.preventDefault()
    this.props.doSearch()
  }

  render () {
    const {
      inputChange,
      toggleFilter,
      formError,
      filterOpen,
      searchObject: {
        search, createdAfter, createdBefore, userActive, userType, userPartner
      }
    } = this.props

    const userPartnerList = [{ value: '', display: 'All' }].concat(this.props.userPartnerList)

    return (
      <div id="vp-SearchFilter" className="vp-SearchFilter">
        <Form onSubmit={this.handleSubmit}>
          <FormError message={formError && formError.search} />

          <div id="vp-SearchFilter__topField">
            <InputSearch
              {...filterFields.search}
              filterFunc={toggleFilter}
              filterOpen={filterOpen}
              onChange={inputChange('search')}
              value={search || ''}
            />
          </div>

          <div
            className={`vp-SearchFilter__bottomField ${!filterOpen ? '--collapsed' : ''}`}
          >
            <Select
              {...filterFields.userActive}
              onChange={inputChange('userActive')}
              value={userActive}
            />

            <Input
              {...filterFields.createdAfter}
              onChange={inputChange('createdAfter')}
              value={createdAfter}
            />

            <Input
              {...filterFields.createdBefore}
              onChange={inputChange('createdBefore')}
              value={createdBefore}
            />

            <Select
              {...filterFields.userType}
              onChange={inputChange('userType')}
              value={userType}
            />

            {userType === 'AFFILIATE' && userPartnerList.length > 1 && (
              <Select
                {...filterFields.userPartner}
                onChange={inputChange('userPartner')}
                value={userPartner}
                options={userPartnerList}
              />
            )}
          </div>
        </Form>
      </div>
    )
  }
}

SearchFilter.propTypes = {
  inputChange: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  doSearch: PropTypes.func.isRequired,
  userPartnerList: PropTypes.arrayOf(PropTypes.object).isRequired,
  formError: PropTypes.shape({
    message: PropTypes.string,
    search: PropTypes.string
  }),
  filterOpen: PropTypes.bool.isRequired,
  searchObject: PropTypes.shape({
    search: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    createdAfter: PropTypes.string.isRequired,
    createdBefore: PropTypes.string.isRequired,
    userActive: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired,
    userPartner: PropTypes.string.isRequired
  }).isRequired
}

SearchFilter.defaultProps = {
  formError: undefined
}

export default SearchFilter
