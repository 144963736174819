import React from 'react'
import PropTypes from 'prop-types'

import { Icon } from '@vivialplatform/ui-shared-lib'

import Button from '../Button'
import Link from '../Link'
import ExternalLink from '../ExternalLink'
import collapsible from '../shared/Collapsible'

import './Table.scss'

const datetimeFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit'
}

const dateFormat = {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit'
}

function getActions (actions, id) {
  return actions.map((action, i) => {
    const { href } = action
    const link = href ? getLinkFromParams({ id }, href) : ''
    return (
      <React.Fragment key={`${action.text}-${id}`}>
        <Button onClick={() => action.clickHandler(link || id)} {...action.props}>
          {action.text}
        </Button>
        {i + 1 < actions.length && <span> | </span>}
      </React.Fragment>
    )
  })
}

function getLinkFromParams (params, basePath = `{basePath}/{param}`) {
  const entries = Object.entries(params)
  return entries.reduce((url, [key, value]) => url.replace(`{${key}}`, value), basePath)
}

function renderRow (header, row) {
  const settings = header.settings || {}
  switch (settings.type) {
    case 'link': {
      const linkPath = getLinkFromParams({
        basePath: settings.basePath,
        param: row[settings.accessor || header.accessor]
      })
      return (
        <Link to={linkPath} href={linkPath}>
          {row[header.accessor]}
        </Link>
      )
    }
    case 'url': {
      const linkPath = row[header.accessor]
      return <ExternalLink href={linkPath}>{linkPath}</ExternalLink>
    }
    case 'linkAddAccount': {
      return (
        <Link
          key={row[settings.accessor]}
          to="?"
          data="addAccount"
          id={row[settings.accessor]}
          onClick={() => settings.function(row[settings.accessor])}
        >
          {row[header.accessor]}
        </Link>
      )
    }
    case 'linkIcon': {
      const iconName = settings.icon
      const linkPath = getLinkFromParams({
        basePath: settings.basePath,
        param: row[settings.accessor]
      })
      return (
        <ExternalLink href={linkPath}>
          <span data-tooltip title={`${settings.tooltip}`} className="vp-Table__icon">
            <Icon icon={['fal', `${iconName}`]} />
          </span>
        </ExternalLink>
      )
    }
    case 'linkIconLocation': {
      const iconName = settings.icon
      const url = `{basePath}/{client}/{locationsBasePath}/{param}`
      const linkPath = getLinkFromParams(
        {
          basePath: settings.basePath,
          client: row[settings.clientAccessor],
          locationsBasePath: settings.locationsBasePath,
          param: row[settings.accessor]
        },
        url
      )
      return (
        <ExternalLink href={linkPath}>
          <span data-tooltip title={`${settings.tooltip}`} className="vp-Table__icon">
            <Icon icon={['fal', `${iconName}`]} />
          </span>
        </ExternalLink>
      )
    }
    case 'actions':
      return getActions(settings.actions, row[header.accessor])
    case 'name':
      return `${row[settings.firstName] || ''} ${row[settings.lastName] || ''}`.trim()
    case 'boolean': {
      const statusClass = row[header.accessor] ? 'active' : 'inactive'
      const status = row[header.accessor] ? 'Active' : 'Inactive'
      return <span className={statusClass}>{status}</span>
    }
    case 'affiliate':
      return row[header.accessor] === 'AFFILIATE' ? 'PARTNER' : row[header.accessor]
    case 'datetime':
      if (row[header.accessor]) {
        const date = new Date(row[header.accessor])
          .toLocaleString('en-US', datetimeFormat)
          .replace(',', '')
        return date
      }
      return row[header.accessor]
    case 'date':
      if (row[header.accessor]) {
        const date = new Date(row[header.accessor])
          .toLocaleString('en-US', dateFormat)
        return date
      }
      return row[header.accessor]
    case 'json':
      return JSON.stringify(row[header.accessor])
    case 'yes/no':
      return row[header.accessor] ? 'Yes' : 'No'
    case 'callback':
      return header.settings.callback(row)
    case 'arrayToSring':
      return row[header.accessor].join(', ')
    default:
      return row[header.accessor]
  }
}

const getCollapseRowButton = (collapseHandlerAction, collapsed) => ({
  icon: collapsed ? 'chevron-down' : 'chevron-up',
  clickHandler: collapseHandlerAction,
  props: {
    btnStyle: 'clear'
  }
})

export const Row = ({
  columns, content, collapseButton, collapsed, offset
}) => (
  <>
    <tr>
      {collapseButton && (
        <td>
          <Button
            key={collapseButton.text}
            onClick={() => collapseButton.clickHandler()}
            {...collapseButton.props}
          >
            <span className="vp-Table__icon">
              <Icon icon={['fal', `${collapseButton.icon}`]} />
            </span>
          </Button>
        </td>
      )}
      {offset && <td />}
      {columns.map(({ name, data }) => (
        <td key={name}>
          <span className="vp-Table__mobile-label">
            {name}
            <br />
          </span>
          {data}
        </td>
      ))}
    </tr>
    {content && (
      <tr style={collapsed ? { display: 'none', padding: 0 } : undefined}>
        <td colSpan={columns.length + (collapseButton ? 1 : 0)}>
          <pre>{content}</pre>
        </td>
      </tr>
    )}
  </>
)

Row.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  content: PropTypes.string,
  collapseButton: PropTypes.shape({
    text: PropTypes.string,
    props: PropTypes.object,
    clickHandler: PropTypes.func,
    icon: PropTypes.string
  }),
  collapsed: PropTypes.bool,
  offset: PropTypes.bool
}

Row.defaultProps = {
  content: undefined,
  collapseButton: undefined,
  collapsed: false,
  offset: false
}

const Table = ({
  headers, rowKey, rows, rowContent, loadMoreRows
}) => {
  const CollapsibleRow = collapsible(Row)
  return (
    <table id="vp-Table" className="vp-Table">
      <thead>
        <tr>
          {rowContent && <th />}
          {headers.map(header => (
            <th key={header.name}>{header.name}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {rows.map(row => (row[rowContent] ? (
          <CollapsibleRow
            key={row[rowKey]}
            columns={headers.map(header => ({ name: header.name, data: renderRow(header, row) }))}
            content={JSON.stringify(row[rowContent], undefined, 2)}
            getCollapseButton={getCollapseRowButton}
            initiateCollapsed
          />
        ) : (
          <Row
            key={row[rowKey]}
            columns={headers.map(header => ({ name: header.name, data: renderRow(header, row) }))}
            offset={!!rowContent}
          />
        ))
        )}
      </tbody>
      {loadMoreRows && (
        <tfoot>
          <tr>
            <td colSpan={headers.length + (rowContent ? 1 : 0)}>
              <Button onClick={() => loadMoreRows()} btnStyle="clear">
                Load More...
              </Button>
            </td>
          </tr>
        </tfoot>
      )}
    </table>
  )
}

Table.propTypes = {
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      accessor: PropTypes.string, // only for default, link, and boolean
      settings: PropTypes.shape({
        type: PropTypes.oneOf([
          'link',
          'actions',
          'name',
          'boolean',
          'affiliate',
          'linkAddAccount',
          'linkIconLocation',
          'linkIcon',
          'datetime',
          'date',
          'json',
          'url',
          'yes/no',
          'callback',
          'arrayToSring'
        ]),
        basePath: PropTypes.string, // Only for link type
        actions: PropTypes.arrayOf(
          PropTypes.shape({
            // Only for 'actions' type
            text: PropTypes.string.isRequired,
            props: PropTypes.object
          })
        )
      })
    })
  ).isRequired,
  rowKey: PropTypes.string.isRequired,
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  rowContent: PropTypes.string,
  loadMoreRows: PropTypes.func
}

Table.defaultProps = {
  rowContent: undefined,
  loadMoreRows: undefined
}

export default Table
