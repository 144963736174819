import React, { Component } from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'

import Page from '../../common/Page'
import Button from '../../common/Button'
import InputSearch from '../../common/InputSearch'
import requestHelper from '../../../helpers/request-helpers'
import Modal from '../../common/Modal'
import Loading from '../../common/Loading'
import { Form, FormError } from '../../common/Form'
import PROP_TYPES from '../../../prop-types'
import formHelpers from '../../../helpers/form-helpers'

const INPUT_ID = 'search-users'
export class AccountAddUser extends Component {
  static userIsAttachedToAccount (user, accountId) {
    if (user.accounts && user.accounts.length) {
      return user.accounts.some(account => account.account_id === accountId)
    }

    return false
  }

  constructor (props) {
    super(props)
    this.state = {}
    this.handleChange = this.handleChange.bind(this)
    this.handleSearch = this.handleSearch.bind(this)
    this.handleModalYes = this.handleModalYes.bind(this)
    this.handleModalNo = this.handleModalNo.bind(this)
  }

  handleChange ({ target }) {
    this.setState({ value: target.value })
  }

  async handleSearchResults ({ data: [user] }) {
    if (!user) {
      this.setState(prevState => ({
        location: {
          pathname: '/users/new',
          state: {
            accountId: this.props.match.params.id,
            email: prevState.value,
            accountName: this.props.location.state.accountName
          }
        }
      }))
      return
    }

    const { id: accountId } = this.props.match.params

    if (AccountAddUser.userIsAttachedToAccount(user, accountId)) {
      this.setState({
        notice: {
          message: 'User already attached to account',
          type: 'error'
        },
        submitting: false,
        showModal: false
      })
    } else if (user.type !== 'CLIENT') {
      this.setState({
        notice: {
          message: 'User is not type CLIENT',
          type: 'error'
        },
        submitting: false,
        showModal: false
      })
    } else {
      this.setState({ showModal: true, user })
    }
  }

  async handleSearch (e) {
    e.preventDefault()
    this.setState({ notice: undefined, formError: undefined })

    const search = encodeURIComponent(this.state.value)
    let results
    try {
      results = await requestHelper.makeRequest({
        method: 'GET',
        url: `/users?email=${search}`
      })
    } catch (error) {
      this.setState({ error })
    }

    if (results.status === 400) {
      const formError = formHelpers.handleValidationErrors(results)
      this.setState({ formError })
      return
    }
    try {
      await this.handleSearchResults(results)
    } catch (error) {
      this.setState({ error })
    }
  }

  async handleModalYes () {
    const { id } = this.props.match.params
    const { user } = this.state
    let response

    this.setState({ submitting: true })

    try {
      response = await requestHelper.makeRequest({
        method: 'POST',
        url: `/accounts/${id}/users`,
        body: {
          user_id: user.id
        },
        toJson: false
      })
    } catch (error) {
      if (error.status === 409) {
        this.setState({
          formError: {
            email: 'User already attached to account'
          },
          submitting: false,
          showModal: false
        })
        return
      }
      this.setState({ error })
    }

    if (response.ok) {
      const notice = { notice: { message: 'Successfully added user to account.', type: 'success' } }
      const location = {
        pathname: `/accounts/${id}`,
        state: notice
      }
      this.setState({ location, submitting: false })
      return
    }

    let json
    try {
      json = await response.json()
    } catch (error) {
      this.setState({ error })
    }
    if (json.status === 400) {
      const formError = formHelpers.handleValidationErrors(json)
      // should always be user is not type CLIENT error
      this.setState({
        notice: {
          message: formError.email,
          type: 'error'
        },
        submitting: false,
        showModal: false
      })
    }
  }

  handleModalNo () {
    const { id } = this.props.match.params
    this.setState({ location: { pathname: `/accounts/${id}` } })
  }

  render () {
    if (this.state.error) throw this.state.error
    const {
      location, user, submitting, formError
    } = this.state

    if (location) {
      return <Redirect push to={location} />
    }

    const { state: locationState } = this.props.location
    const title = `Attach User ${locationState ? `- ${locationState.accountName}` : ''}`

    return (
      <Page title={title} notice={this.state.notice} showBack>
        <Modal isOpen={this.state.showModal} title="Confirm" onRequestClose={this.handleModalClose}>
          <div id="vp-AccountAddUser" className="vp-AccountAddUser">
            {user && (
              <div className="vp-AddUser__details">
                <p>
                  Attach this user to
                  {locationState.clientName}
?
                </p>
                <div className="vp-AddUser__details-container">
                  <div className="vp-AddUser__details-content">
                    <p className="vp-AddUser__details-content-label">Name</p>
                    <p>
                      {user.first_name}
                      {' '}
                      {user.last_name}
                    </p>
                  </div>
                  <div className="vp-AddUser__details-content">
                    <p className="vp-AddUser__details-content-label">Email</p>
                    <p>{user.email}</p>
                  </div>
                </div>
                <div className="vp-AddUser__actions">
                  <Button onClick={this.handleModalYes}>Yes</Button>
                  <Button btnStyle="clear" onClick={this.handleModalNo}>
                    No
                  </Button>
                </div>
              </div>
            )}
          </div>
        </Modal>
        {submitting && <Loading />}
        <Form onSubmit={this.handleSearch}>
          <InputSearch
            name="search"
            htmlId={INPUT_ID}
            placeholder="Find by email"
            onChange={this.handleChange}
            required
          />
        </Form>
        <FormError message={formError && formError.email} />
      </Page>
    )
  }
}

AccountAddUser.propTypes = {
  match: PROP_TYPES.ID_IN_PATH.isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({
      accountName: PropTypes.string.isRequired
    })
  }).isRequired
}

export default AccountAddUser
