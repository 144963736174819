import React from 'react'
import propTypes from 'prop-types'
import PROP_TYPES from '../../../../prop-types'

import withLabelAndFormError from '../shared/WithLabelAndFormError'

const CLASS_NAME = {
  default: 'vp-Form__select',
  error: 'vp-Form__select is-invalid-input'
}

const Select = ({ options, formError, ...rest }) => (
  <select {...rest} className={formError ? CLASS_NAME.error : CLASS_NAME.default}>
    {options.map(option => (
      <option key={option.value} value={option.value} disabled={option.disabled}>
        {option.display}
      </option>
    ))}
  </select>
)

Select.defaultProps = { formError: undefined }
Select.propTypes = {
  options: propTypes.arrayOf(PROP_TYPES.SELECT_OPTIONS).isRequired,
  formError: propTypes.string
}

export default withLabelAndFormError(Select)
