/* eslint-disable import/no-unresolved */
import { library } from '@vivialplatform/ui-shared-lib'
import {
  faFilter,
  faUser,
  faUsers,
  faBuilding,
  faFile,
  faBars,
  faSearch
} from '@fortawesome/pro-solid-svg-icons'
import {
  faPencil,
  faSquare,
  faCheckSquare,
  faInfoCircle,
  faPlus,
  faEraser,
  faEdit,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
  faExternalLink,
  faBan,
  faWrench
} from '@fortawesome/pro-light-svg-icons'

library.add(
  faFilter,
  faUser,
  faUsers,
  faBuilding,
  faFile,
  faBars,
  faSearch,
  faPencil,
  faSquare,
  faCheckSquare,
  faInfoCircle,
  faPlus,
  faEraser,
  faEdit,
  faChevronUp,
  faChevronDown,
  faArrowLeft,
  faExternalLink,
  faBan,
  faWrench
)
