import React from 'react'
import PropTypes from 'prop-types'
import { Link as ReactRouterLink } from 'react-router-dom'

import theme, { darken } from '../../../helpers/theme-helpers'
import hoverable from '../shared/Hoverable'

const HoverableLink = hoverable(ReactRouterLink)

const transition = 'color 0.25s ease-out'
export const defaultStyle = {
  base: {
    color: theme.colors.primary,
    transition
  },
  hover: {
    color: darken(theme.colors.primary),
    transition
  }
}

const getStyle = (color) => {
  if (!color) return defaultStyle
  return {
    base: { color, transition },
    hover: { color: darken(color), transition }
  }
}

const Link = ({ children, color, ...rest }) => (
  <HoverableLink {...rest} style={getStyle(color)} className="vp-Link">
    {children}
  </HoverableLink>
)

Link.defaultProps = { color: undefined, children: undefined }
Link.propTypes = {
  children: PropTypes.node,
  color: PropTypes.string
}

export default Link
