import React from 'react'
import PropTypes from 'prop-types'

import { defaultColors } from '../../../helpers/theme-helpers'
import Button from '../Button'

import './Pagination.scss'

const Pagination = ({
  nextPage, previousPage, onPageChange, position
}) => (
  <div className={`vp-Pagination ${position}`}>
    <Button
      btnStyle="clear"
      onClick={() => onPageChange(previousPage)}
      color={defaultColors.darkGray}
      disabled={!previousPage}
    >
      &laquo; Previous
    </Button>
    <Button
      btnStyle="clear"
      onClick={() => onPageChange(nextPage)}
      color={defaultColors.darkGray}
      disabled={!nextPage}
    >
      Next &raquo;
    </Button>
  </div>
)

Pagination.propTypes = {
  nextPage: PropTypes.number,
  previousPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  position: PropTypes.string
}

Pagination.defaultProps = {
  nextPage: undefined,
  previousPage: undefined,
  position: undefined
}

export default Pagination
