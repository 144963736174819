import React from 'react'
import onClickOutside from 'react-onclickoutside'

import Button from '../../Button'
import Link from '../../Link'
import { defaultColors } from '../../../../helpers/theme-helpers'
import getAuthInstance from '../../../../config/auth'
import './HeaderMenu.scss'
import { Avatar } from '../../Avatar'

const MENU_STYLE = {
  background: defaultColors.white,
  color: defaultColors.black
}

const auth = getAuthInstance()

class BaseHeaderMenu extends React.Component {
  static logout () {
    auth.logout()
  }

  constructor (props) {
    super(props)
    this.state = {
      visible: false
    }
    this.handleClick = this.handleClick.bind(this)
    this.handleLinkClick = this.handleLinkClick.bind(this)
  }

  handleClick () {
    this.setState(prevState => ({ visible: !prevState.visible }))
  }

  handleLinkClick () {
    this.setState({ visible: false })
  }

  handleClickOutside (evt) {
    this.setState({ visible: false })
  }

  render () {
    const hidden = !this.state.visible ? '--hidden' : ''
    return (
      <div id="vp-HeaderMenu">
        <Button
          id="vp-HeaderMenu__menu-btn"
          color={defaultColors.lightGray}
          btnStyle="clear"
          onClick={this.handleClick}
        >
          <Avatar />
        </Button>
        <div id={`vp-HeaderMenu__arrow${hidden}`} />
        <div id={`vp-HeaderMenu__menu${hidden}`} style={MENU_STYLE}>
          <div id="vp-HeaderMenu__change-password">
            <Link
              to="/profile/change-password"
              href="/profile/change-password"
              onClick={this.handleLinkClick}
            >
              Change Password
            </Link>
          </div>
          <div id="vp-HeaderMenu__logout">
            <Button onClick={BaseHeaderMenu.logout} btnStyle="clear">
              Logout
            </Button>
          </div>
        </div>
      </div>
    )
  }
}

const HeaderMenu = onClickOutside(BaseHeaderMenu)

export { BaseHeaderMenu, HeaderMenu }
