/* eslint no-alert: "off" */
import React from 'react'
import Raven from 'raven-js'
import PropTypes from 'prop-types'

import Button from '../../Button'

import './InternalError.scss'

function showReportDialog () {
  if (Raven.isSetup()) {
    Raven.showReportDialog()
  } else {
    window.alert('Raven is not configured in this environment.')
  }
}

const InternalError = ({ message }) => (
  <div id="vp-InternalError" className="vp-InternalError">
    <div id="vp-InternalError__container" className="vp-InternalError__container">
      <div id="vp-InternalError__content" className="vp-InternalError__content">
        <div className="vp-InternalError__heading">
          <span>S</span>
          <span>O</span>
          <span>R</span>
          <span>R</span>
          <span>Y</span>
        </div>
        <p>{message || 'Something went wrong on our end!'}</p>
        <Button btnStyle="hollow" onClick={showReportDialog}>
          Please Provide Feedback
        </Button>
      </div>
    </div>
  </div>
)

InternalError.propTypes = { message: PropTypes.string }
InternalError.defaultProps = { message: undefined }

export default InternalError
