// these are all basically functions that shuffle around objects

const getSearchObjFromString = search => search
  .replace('?', '')
  .split('&')
  .reduce((accum, current) => {
    const newAccum = { ...accum }
    if (current) {
      const [key, value] = current.split('=')
      newAccum[key] = value
      return newAccum
    }
    return newAccum
  }, {})

const getSearchStringFromObj = (search) => {
  const searchStr = Object.entries(search)
    .reduce((accum, [key, params]) => {
      const newAccum = accum
      newAccum.push(`${key}=${encodeURIComponent(params)}`)
      return newAccum
    }, [])
    .join('&')
  return `?${searchStr}`
}

const getInitialSearchObj = (props) => {
  let {
    location: { search }
  } = props
  if (search) {
    search = getSearchObjFromString(decodeURIComponent(search))
  } else {
    search = reconstructPreviousState(props)
  }
  return search || {}
}

const reconstructPreviousState = (props) => {
  const { globalState, location } = props
  const state =
    globalState && globalState[location.pathname] ? globalState[location.pathname] : null
  return (state && state.searchObject) || null
}

const stateMap = {
  search: 'search',
  createdAfter: 'created_after',
  createdBefore: 'created_before',
  userActive: 'active',
  userType: 'type',
  userPartner: 'partner',
  pageNumber: 'page_number'
}

const removeEmptiesFromObj = searchObject => Object.keys(searchObject).reduce((accum, key) => {
  const newAccum = { ...accum }
  if (searchObject[key]) {
    newAccum[key] = searchObject[key]
  }
  return newAccum
}, {})

const mapStateToRequest = (searchObject) => {
  const newObj = {}
  Object.keys(searchObject).forEach((key) => {
    newObj[stateMap[key]] = searchObject[key]
  })
  return newObj
}

const applyBusinessLogic = (searchObject, field = '', value = '') => {
  const newObj = { ...searchObject }

  // Scroll to top on pagination change
  if (field === 'pageNumber') {
    window.scrollTo({
      top: 0
    })
  }

  // Dont allow created after date to be after created before date
  const beforeDate = Date.parse(newObj.createdBefore)
  const afterDate = Date.parse(newObj.createdAfter)
  if (beforeDate > 0 && afterDate > 0) {
    if (beforeDate < afterDate) {
      newObj.createdAfter = ''
    }
  }

  // Reset the page number to 0 if a search param changes or if the new page number
  // is one, I doing this because the removeEmptiesFromObj will remove the key
  // and the server will default to one, it just makes the url a little cleaner
  if (field !== 'pageNumber' || (field === 'pageNumber' && value === 1)) {
    newObj.pageNumber = 0
  }

  // Dont allow there to be a partner if youre not searching for type affiliate
  if (newObj.userType !== 'AFFILIATE') {
    newObj.userPartner = ''
  }

  // Remove any keys from search state that arent in the params list
  const currentKeys = Object.keys(newObj)
  const allowedKeys = Object.keys(stateMap)
  currentKeys.forEach((key) => {
    if (!allowedKeys.includes(key)) {
      delete newObj[key]
    }
  })
  return newObj
}

const debounce = (func, wait) => {
  let timeout
  return (...rest) => {
    const later = () => {
      timeout = null
      func(...rest)
    }
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
  }
}

export {
  getSearchObjFromString,
  getSearchStringFromObj,
  getInitialSearchObj,
  reconstructPreviousState,
  removeEmptiesFromObj,
  mapStateToRequest,
  applyBusinessLogic,
  debounce
}
