import PropTypes from 'prop-types'

const ERROR = PropTypes.shape({
  status: PropTypes.number,
  message: PropTypes.string,
  errors: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      code: PropTypes.string,
      message: PropTypes.string
    })
  )
})

const USER = PropTypes.shape({
  id: PropTypes.string,
  first_name: PropTypes.string,
  last_name: PropTypes.string,
  email: PropTypes.string,
  type: PropTypes.string,
  version: PropTypes.number,
  auth0_id: PropTypes.string,
  created_at: PropTypes.string,
  updated_at: PropTypes.string
})

const ACCOUNT = PropTypes.shape({
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  display_name: PropTypes.string,
  id: PropTypes.string,
  sf_account: PropTypes.shape({
    account_id: PropTypes.string,
    parent_id: PropTypes.string,
    parent_account_number: PropTypes.string,
    is_child_account: PropTypes.boolean,
    owner_id: PropTypes.string,
    primary_contact_id: PropTypes.string,
    account_manager_id: PropTypes.string,
    ptimary_canvass: PropTypes.string,
    website: PropTypes.string,
    talus_account_id: PropTypes.string,
    created_by_id: PropTypes.string,
    last_modified_by_id: PropTypes.string,
    system_modstamp: PropTypes.string,
    sf_id: PropTypes.string,
    name: PropTypes.string,
    phone: PropTypes.string,
    account_number: PropTypes.string,
    enterprise_customer_id: PropTypes.string,
    address_street: PropTypes.string,
    address_city: PropTypes.string,
    address_state: PropTypes.string,
    address_postal_code: PropTypes.string,
    address_country: PropTypes.string
  })
})

const AUDIT_LOG = PropTypes.shape({
  id: PropTypes.string,
  event_name: PropTypes.string,
  ip: PropTypes.string,
  details: PropTypes.object,
  created_at: PropTypes.string
})

const AFFILIATE = PropTypes.shape({
  affiliate_id: PropTypes.string,
  name: PropTypes.string,
  support_email: PropTypes.string,
  support_phone: PropTypes.string,
  sf_canvass_id: PropTypes.string,
  sf_canvass_code: PropTypes.string,
  sf_canvass_name: PropTypes.string,
  sf_canvass_last_modified_date: PropTypes.string,
  is_active: PropTypes.boolean
})

const CONTACT = PropTypes.shape({
  address_city: PropTypes.string,
  address_country: PropTypes.string,
  address_postal_code: PropTypes.string,
  address_state: PropTypes.string,
  address_street: PropTypes.string,
  email: PropTypes.string,
  is_primary_contact: PropTypes.boolean,
  lastmodified_date: PropTypes.string,
  name: PropTypes.string,
  phone: PropTypes.string,
  sf_account_id: PropTypes.string,
  sf_id: PropTypes.string
})

const ID_IN_PATH = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.string
  })
})

const NOTICE = PropTypes.shape({
  message: PropTypes.string,
  type: PropTypes.oneOf(['error', 'info', 'success'])
})
const STATE_WITH_NOTICE = PropTypes.shape({
  state: PropTypes.shape({
    NOTICE
  })
})

const ACCOUNT_PERMISSIONS = PropTypes.arrayOf(
  PropTypes.shape({
    client_id: PropTypes.string,
    location_id: PropTypes.string,
    roles: PropTypes.arrayOf(PropTypes.string),
    policies: PropTypes.arrayOf(PropTypes.string)
  })
)

const USER_ACCOUNT_PERMISSIONS = PropTypes.shape({
  params: PropTypes.shape({
    id: PropTypes.string,
    account_id: PropTypes.string
  })
})

const SELECT_OPTIONS = PropTypes.shape({
  display: PropTypes.string,
  value: PropTypes.string,
  disabled: PropTypes.boolean
})

const SELECT_OPTIONS_ARRAY = PropTypes.arrayOf(SELECT_OPTIONS)

const STATE_WITH_PERMISSIONS = PropTypes.shape({
  state: PropTypes.shape({
    permissions: ACCOUNT_PERMISSIONS
  })
})

const STATE_WITH_PERMISSIONS_AND_UPDATE = PropTypes.shape({
  state: PropTypes.shape({
    permissions: ACCOUNT_PERMISSIONS,
    updated: PropTypes.bool
  })
})

const SUBSCRIPTION = PropTypes.shape({
  id: PropTypes.string,
  product_id: PropTypes.string,
  account_id: PropTypes.string,
  oli_id: PropTypes.string,
  parent_oli_id: PropTypes.string,
  oli_number: PropTypes.string,
  name: PropTypes.string,
  vendor_id: PropTypes.string,
  details: PropTypes.shape({
    youtube_id: PropTypes.string
  }),
  quantity: PropTypes.number,
  created_at: PropTypes.string,
  updated_at: PropTypes.string,
  ended_at: PropTypes.string
})

const APP_CONFIG = PropTypes.shape({
  id: PropTypes.string,
  application_id: PropTypes.string,
  modules: PropTypes.arrayOf(PropTypes.string),
  config: PropTypes.object
})

const PROP_TYPES = {
  ERROR,
  USER,
  ACCOUNT,
  AUDIT_LOG,
  AFFILIATE,
  CONTACT,
  ID_IN_PATH,
  STATE_WITH_NOTICE,
  SELECT_OPTIONS,
  ACCOUNT_PERMISSIONS,
  USER_ACCOUNT_PERMISSIONS,
  STATE_WITH_PERMISSIONS,
  STATE_WITH_PERMISSIONS_AND_UPDATE,
  SELECT_OPTIONS_ARRAY,
  NOTICE,
  SUBSCRIPTION,
  APP_CONFIG
}

export default PROP_TYPES
