import React from 'react'
import propTypes from 'prop-types'
import Button from '../Button'

import './Collapsible.scss'

class Collapsible extends React.Component {
  constructor (props) {
    super(props)
    this.state = { showHideText: 'Show' }
    this.onClick = this.onClick.bind(this)
  }

  onClick (event) {
    event.preventDefault()

    this.state.showHideText === 'Show'
      ? this.setState({ showHideText: 'Hide' })
      : this.setState({ showHideText: 'Show' })
  }

  render () {
    const { children, buttonText } = this.props
    const { showHideText } = this.state
    return (
      <div className="vp-Collapsible">
        <Button type="button" btnStyle="clear" onClick={this.onClick}>
          {showHideText}
          {' '}
          {buttonText}
        </Button>
        <div
          id="vp-Collapsible__body"
          className="vp-Collapsible__body"
          style={{ display: showHideText === 'Show' ? 'none' : 'block' }}
        >
          {children}
        </div>
      </div>
    )
  }
}

Collapsible.propTypes = {
  children: propTypes.oneOfType([propTypes.element, propTypes.node]).isRequired,
  buttonText: propTypes.string.isRequired
}

export default Collapsible
