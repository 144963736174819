import React from 'react'
import propTypes from 'prop-types'

const FormError = ({ message }) => (
  <span className={message ? 'form-error is-visible' : 'form-error'}>{message}</span>
)

FormError.defaultProps = {
  message: undefined
}
FormError.propTypes = {
  message: propTypes.string
}

export default FormError
