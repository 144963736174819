import React from 'react'
import PropTypes from 'prop-types'
import { getAddress } from 'helpers/utils'
import PROP_TYPES from '../../../prop-types'
import { getAccount } from '../../account/shared/account-helpers'
import getClient from '../../client/shared/client-helpers'
import getLocation from '../../location/shared/location-helpers'
import { getUserAccountPermissions, getUser, updateUserAccountPermissions } from './user-helpers'

const getPermissionDisplay = async (perm, id, getData, formatData) => {
  switch (perm[id]) {
    case '0':
      return 'None'
    case '-1':
      return 'All'
    default: {
      try {
        const data = await getData(perm[id])
        if (formatData) {
          return formatData(data)
        }
        const { name } = data
        return name
      } catch (e) {
        return '—'
      }
    }
  }
}

const mapPermissions = async (p) => {
  const permission = { ...p }
  permission.client = await getPermissionDisplay(permission, 'client_id', getClient)
  permission.location = await getPermissionDisplay(
    permission,
    'location_id',
    getLocation,
    getAddress
  )
  permission.id = `${p.client_id}+${p.location_id}`
  return permission
}

export const getWithClientLocationInfo = async permissions => (
  Promise.all(permissions.map(mapPermissions))
)

const userAccountPermissionsGet = (WrappedComponent) => {
  class UserAccountPermissionsGet extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        account: null,
        user: null,
        permissions: [],
        error: null
      }
    }

    async componentDidMount () {
      this._mounted = true
      await this.getAllData()
    }

    componentWillUnmount () {
      this._mounted = false
    }

    async getAllData () {
      const { account_id: accountId, id } = this.props.match.params
      const { location } = this.props
      let permissions
      let updated = false
      if (location.state) {
        const { permissions: newPermissions, updated: isUpdated } = location.state
        permissions = newPermissions
        updated = isUpdated
      }
      try {
        const { account } = await getAccount(accountId, false, false)
        const { user } = await getUser(id)
        let permissionsWithKey = []
        if (updated) {
          permissionsWithKey = await getWithClientLocationInfo(permissions)
        } else {
          const { permissions: basePermissions } = await getUserAccountPermissions(id, accountId)
          permissionsWithKey = await getWithClientLocationInfo(basePermissions)
        }
        this._mounted && this.setState({ account, user, permissions: permissionsWithKey })
      } catch (error) {
        if (this._mounted) {
          this.setState({ error })
        }
      }
    }

    updatePermissions = async (permissions) => {
      const { history } = this.props
      const { account: { id: accountId }, user: { id: userId } } = this.state
      try {
        const response = await updateUserAccountPermissions(userId, accountId, permissions)
        if (response.ok) {
          history.goBack()
        }
      } catch (error) {
        this.setState({ error })
      }
    }

    render () {
      const { account, user, permissions } = this.state
      if (this.state.error) throw this.state.error
      return (
        <WrappedComponent
          account={account}
          user={user}
          permissions={permissions}
          updatePermissions={this.updatePermissions}
          {...this.props}
        />
      )
    }
  }

  UserAccountPermissionsGet.propTypes = {
    match: PROP_TYPES.USER_ACCOUNT_PERMISSIONS.isRequired,
    location: PROP_TYPES.STATE_WITH_PERMISSIONS_AND_UPDATE,
    history: PropTypes.shape({
      goBack: PropTypes.func
    }).isRequired
  }

  UserAccountPermissionsGet.defaultProps = {
    location: { state: { permissions: [], updated: false } }
  }

  return UserAccountPermissionsGet
}

export default userAccountPermissionsGet
