import React, { memo } from 'react'
import PropTypes from 'prop-types'
import { switchRenderHelper, wrapWithParagraph } from './data-row-helpers'
import { defaultColors } from '../../../helpers/theme-helpers'
import './DataRow.scss'

const LABEL_COLOR = {
  color: defaultColors.darkGray
}

const DataRow = ({
  property, title, value, accessor
}) => {
  const rowValue = wrapWithParagraph(switchRenderHelper(property, value, accessor))

  return (
    <div className="vp-DataRow">
      <div className="vp-DataRow__title" style={LABEL_COLOR}>{title}</div>
      <div className="vp-DataRow__value">{rowValue}</div>
    </div>
  )
}
DataRow.defaultProps = { value: undefined, accessor: undefined }

DataRow.propTypes = {
  property: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  accessor: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
    PropTypes.bool,
    PropTypes.object
  ])
}

export default memo(DataRow)
