import React from 'react'
import {
  Route, Switch, withRouter, Redirect
} from 'react-router-dom'
import propTypes from 'prop-types'

import AppLayout from '../../common/AppLayout'
import Loading from '../../common/Loading'
import getAuthInstance from '../../../config/auth'
import ROUTES_CONFIG from '../../../config/routes'
import HttpError from '../../../helpers/errors'
import { GlobalContextProvider } from '../../../helpers/global-context'
import LoggedOut from '../../profile/LoggedOut'
import WithPageTracker from '../shared/with-page-tracker'
import initSessionCheck from '../shared/check-session'

const auth = getAuthInstance()

const routes = ROUTES_CONFIG.map(cfg => cfg.routes.map((route) => {
  const newRoute = { ...route, component: WithPageTracker(route.component) }
  return <Route exact key={route.path} {...newRoute} />
}))

const menuItemsFromConfig = ROUTES_CONFIG.filter(cfg => cfg.menu).map(cfg => cfg.menu)

class App extends React.Component {
  static isLoggedIn () {
    try {
      return auth.isAuthenticated() && window.localStorage.getItem('access_token')
    } catch (err) {
      return false
    }
  }

  constructor (props) {
    super(props)
    this.state = {
      error: undefined,
      globalState: {
        '/account': {
          search: ''
        },
        '/user': {
          search: ''
        }
      }
    }
    this.login = this.login.bind(this)
    this.setGlobalState = this.setGlobalState.bind(this)
  }

  componentWillMount () {
    this.reconstructGlobalState()
  }

  async componentDidMount () {
    if (App.isLoggedIn()) {
      await initSessionCheck(auth, this)
    }
  }

  setGlobalState (newState) {
    this.setState(
      prevState => ({
        globalState: {
          ...prevState.globalState,
          ...newState
        }
      }),
      () => {
        window.localStorage.setItem('global_state', JSON.stringify(this.state.globalState))
      }
    )
  }

  reconstructGlobalState () {
    const {
      history: {
        location: { search }
      }
    } = this.props
    let globalState

    if (!auth.isAuthenticated() || search !== '') {
      return
    }
    try {
      globalState = JSON.parse(window.localStorage.getItem('global_state'))
    } catch (error) {
      this.setState({ error })
    }
    if (!globalState || !Object.keys(globalState).length) {
      return
    }

    this.setState({ globalState })
  }

  async login () {
    try {
      await auth.checkSession()
      this.props.history.replace(window.location.pathname)
    } catch (err) {
      if (err.response && err.response.data && err.response.data.status === 403) {
        this.setState({ error: new HttpError(403) })
      } else {
        auth.login()
      }
    }
  }

  render () {
    let isLoggedOut
    if (this.props.location) {
      isLoggedOut = this.props.location.pathname.includes('/logged-out')
    }
    if (this.state.error) throw this.state.error
    if (App.isLoggedIn()) {
      return (
        <GlobalContextProvider
          globalState={this.state.globalState}
          setGlobalState={this.setGlobalState}
        >
          <AppLayout menuItems={menuItemsFromConfig}>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/accounts" />} />
              {routes}
              <Route
                render={() => {
                  throw new HttpError(404)
                }}
              />
            </Switch>
          </AppLayout>
        </GlobalContextProvider>
      )
    }
    if (isLoggedOut) {
      return <LoggedOut />
    }
    this.login()
    return <Loading full />
  }
}
App.defaultProps = {
  location: undefined
}

App.propTypes = {
  location: propTypes.shape({
    pathname: propTypes.string
  }),
  history: propTypes.shape({
    replace: propTypes.func,
    location: propTypes.shape({
      search: propTypes.string
    })
  }).isRequired
}

export default withRouter(App)
