import { stringify } from 'query-string'
import requestHelpers from '../../../helpers/request-helpers'

const subscriptionsPageSize = 100

function getAccount (id, includeUsers = true, includeContacts = true) {
  const request = {
    method: 'GET',
    url: `/accounts/${id}?include_users=${includeUsers}&include_contacts=${includeContacts}`
  }
  return requestHelpers.makeRequest(request)
}
/**
 * Search subscriptions by account_id or client_id
 * @param {Object} params { account_id: '1' }
 * @param {String} pageSize sige of page to be returned
 */
function getSubscriptions (params, pageSize = subscriptionsPageSize) {
  const searchQuery = stringify(params)

  return requestHelpers.makeRequest({
    method: 'get',
    url: `/subscriptions?${searchQuery}&page_size=${pageSize}`
  })
}

export default getAccount
export { getAccount, getSubscriptions }
