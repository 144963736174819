import React from 'react'
import PropTypes from 'prop-types'

const FormattedDate = ({ dateString, locale, format }) => (
  <p>
    {dateString
      ? new Date(dateString).toLocaleString(locale, format).replace(',', ' ')
      : dateString}
  </p>
)

FormattedDate.propTypes = {
  dateString: PropTypes.string.isRequired,
  locale: PropTypes.string,
  format: PropTypes.shape({
    year: PropTypes.string,
    month: PropTypes.string,
    day: PropTypes.string
  })
}

FormattedDate.defaultProps = {
  locale: 'en-US',
  format: {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
}

export default FormattedDate
