import React from 'react'
import { Redirect } from 'react-router'
import PropTypes from 'prop-types'

import formHelpers from '../../../../helpers/form-helpers'
import requestHelpers from '../../../../helpers/request-helpers'
import PROP_TYPES from '../../../../prop-types'
import { Form, Select } from '../../../common/Form'
import Button from '../../../common/Button'

class AccountEditForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      redirectToAccount: false,
      submitting: false,
      submitDisabled: true,
      formErrors: {}
    }

    this.form = {
      'account[id]': this.props.account.id,
      'account[sf_account]': this.props.account.sf_account,
      'account[affiliate_id]': this.props.account.affiliate_id
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange ({ target }) {
    const obj = formHelpers.formatInputChange(target)
    this.form = { ...this.form, ...obj }
    if (!this.state.submitting && this.state.submitDisabled) {
      this.setState({ submitDisabled: false })
    }
  }

  async handleSubmit (event) {
    event.preventDefault()
    const body = formHelpers.formatFormParams(this.form)
    this.setState({ submitting: true, submitDisabled: true })
    let response
    try {
      response = await requestHelpers.makeRequest({
        method: 'PATCH',
        url: `/accounts/${this.props.account.id}`,
        body,
        toJson: false
      })
    } catch (error) {
      this.setState({ error })
      return
    }

    if (response.status === 204) {
      this.setState({ redirectToAccount: true })
      return
    }

    let json
    try {
      json = await response.json()
    } catch (error) {
      this.setState({ error })
      return
    }
    const formErrors = formHelpers.handleValidationErrors(json)
    this.setState({ formErrors, submitting: false })
  }

  render () {
    if (this.state.error) throw this.state.error

    const { account, affiliates } = this.props

    if (this.state.redirectToAccount) {
      const notice = { message: 'Successfully updated account.', type: 'success' }
      const location = {
        pathname: `/accounts/${account.id}`,
        state: { notice }
      }
      return <Redirect push to={location} />
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        <Select
          id="account_affiliate_id"
          options={affiliates}
          name="account[affiliate_id]"
          labelText="Partner ID"
          formError={this.state.formErrors['account.affiliate_id']}
          defaultValue={account.affiliate_id}
          onChange={this.handleInputChange}
          required
        />

        <Button type="submit" disabled={this.state.submitDisabled}>
          Save
        </Button>
      </Form>
    )
  }
}

AccountEditForm.propTypes = {
  account: PROP_TYPES.ACCOUNT.isRequired,
  affiliates: PropTypes.arrayOf(PROP_TYPES.SELECT_OPTIONS).isRequired
}

export default AccountEditForm
