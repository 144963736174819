import React from 'react'
import PropTypes from 'prop-types'
import Card from 'components/common/Card'
import DataRow from 'components/common/DataRow'
import 'components/location/LocationView/LocationAssetsCard/LocationAssetsCard.scss'

const LocationAssetsCard = ({ object, title }) => (
  <div id="vp-LocationAssetsCard">
    <Card title={title}>
      <div className="vp-LocationAssetsCard__details">
        <div className="vp-LocationAssetsCard__details-container">
          <div className="vp-LocationAssetsCard__details-content">
            <DataRow property="logo_url" title="Logo" value={object.logo_url} />

            <DataRow property="photo_url" title="Main Photo" value={object.photo_url} />
          </div>

          <div className="vp-LocationAssetsCard__details-content">
            <DataRow property="tagline" title="Tagline" value={object.tagline} />

            <DataRow property="description" title="Description" value={object.description} />
          </div>
        </div>
      </div>
    </Card>
  </div>
)

LocationAssetsCard.defaultProps = {
  object: {
    photo_url: undefined,
    logo_url: undefined
  }
}
LocationAssetsCard.propTypes = {
  object: PropTypes.shape({
    description: PropTypes.string.isRequired,
    tagline: PropTypes.string.isRequired,
    photo_url: PropTypes.string,
    logo_url: PropTypes.string
  }),
  title: PropTypes.string.isRequired
}

export default LocationAssetsCard
