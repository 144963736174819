import '@babel/polyfill'
import 'react-app-polyfill/ie11'

import React from 'react'
import 'what-input'
import { render } from 'react-dom'
import { Route, Switch, BrowserRouter } from 'react-router-dom'
import Raven from 'raven-js'
import './config/icons'

import App from './components/app/App'
import Callback from './components/app/Callback'
import ErrorBoundary from './components/common/ErrorBoundary'

import './css/app.scss'
import gaHelpers from './helpers/google-analytics-helpers'

const {
  REACT_APP_RAVEN_DSN,
  REACT_APP_ENV,
  REACT_APP_VERSION,
  REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID
} = process.env

if (REACT_APP_RAVEN_DSN) {
  Raven.config(REACT_APP_RAVEN_DSN, {
    environment: REACT_APP_ENV,
    release: REACT_APP_VERSION
  }).install()
}

if (REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID) {
  gaHelpers.init(REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID)
}

render(
  <BrowserRouter>
    <ErrorBoundary>
      <Switch>
        <Route exact path="/callback" component={Callback} />
        <Route path="/" component={App} />
      </Switch>
    </ErrorBoundary>
  </BrowserRouter>,
  document.getElementById('root')
)
