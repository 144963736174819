import React from 'react'
import PropTypes from 'prop-types'
import { Redirect } from 'react-router'

import Page from '../../common/Page'
import Loading from '../../common/Loading'
import UserCreateForm from './UserCreateForm'
import requestHelpers from '../../../helpers/request-helpers'
import rolesHelper from '../../../helpers/roles-helper'
import permissionHelper from '../../../helpers/permissions-helpers'
import HttpError from '../../../helpers/errors'

class UserCreate extends React.Component {
  constructor (props) {
    super(props)

    let error
    if (!permissionHelper.hasPerm({ policies: ['user:create'] })) {
      error = new HttpError(404)
    }

    this.state = {
      loading: false,
      roles: [],
      affiliates: [],
      error
    }

    this.baseData =
      this.props.location && this.props.location.state ? this.props.location.state : undefined
    this.handleNewUser = this.handleNewUser.bind(this)
    this.redirectToNextScreen = this.redirectToNextScreen.bind(this)
  }

  componentDidMount () {
    this._mounted = true
    rolesHelper.getAllRoles().then((roles) => {
      this._mounted &&
        this.setState({
          roles
        })
    })
  }

  componentWillUnmount () {
    this._mounted = false
  }

  redirectToNextScreen () {
    let message
    let pathname

    if (this.baseData) {
      pathname = `/accounts/${this.baseData.accountId}`
      message = 'Successfully created and attached user.'
    } else {
      pathname = `/users/${this.state.newUserId}`
      message = 'Successfully created user.'
    }

    this.setState({
      location: {
        pathname,
        state: {
          notice: {
            message,
            type: 'success'
          }
        }
      },
      loading: false
    })
  }

  async handleNewUser (id, sendEmail) {
    this.setState({ newUserId: id, loading: true })

    if (sendEmail) {
      try {
        await requestHelpers.makeRequest({
          method: 'POST',
          url: `/users/${id}/reset-password?send_account_welcome_email=true`,
          toJson: false
        })
      } catch (error) {
        this.setState({ error })
        return
      }
    }

    this.redirectToNextScreen()
  }

  render () {
    if (this.state.error) throw this.state.error

    if (this.state.location) {
      return <Redirect push to={this.state.location} />
    }

    const accountName = this.baseData ? ` - ${this.baseData.accountName}` : ''
    return (
      <Page title={`New User${accountName}`}>
        {this.state.loading && <Loading />}
        <UserCreateForm
          roles={this.state.roles}
          affiliates={this.state.affiliates}
          baseData={this.baseData}
          handleNewUser={this.handleNewUser}
        />
      </Page>
    )
  }
}

UserCreate.defaultProps = {
  location: {}
}
UserCreate.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      baseData: PropTypes.shape({
        email: PropTypes.string,
        accountId: PropTypes.string
      })
    })
  })
}

export default UserCreate
