import React from 'react'

import PROP_TYPES from '../../../prop-types'
import { getUserAuditLogs, getUser } from './user-helpers'

const { REACT_APP_AUDIT_LOG_PAGE_SIZE: auditLogPageSize = 50 } = process.env

function withUserAndAuditsGet (WrappedComponent) {
  class WithUserAndAuditsGet extends React.Component {
    constructor (props) {
      super(props)
      this.state = { data: {}, moreAuditLogs: true, error: undefined }
      this.refresh = this.refresh.bind(this)
      this.loadMoreAuditLogs = this.loadMoreAuditLogs.bind(this)
    }

    async componentDidMount () {
      this._mounted = true
      return this.refresh()
    }

    componentWillUnmount () {
      // Note: necessary so setState() isn't called in componentDidMount() fetch promise chain
      // if component becomes unmounted
      this._mounted = false
    }

    async refresh () {
      const { id } = this.props.match.params
      try {
        const [userInfo, auditLogs = []] = await Promise.all([getUser(id), getUserAuditLogs(id)])
        const data = { ...userInfo, auditLogs }
        if (this._mounted) {
          this.setState({ data })
          if (Number(auditLogPageSize) !== auditLogs.length) {
            this.setState({ moreAuditLogs: false })
          }
        }
      } catch (error) {
        if (this._mounted) {
          this.setState({ error })
        }
      }
    }

    async loadMoreAuditLogs () {
      const { id } = this.props.match.params
      const newPageSize = this.state.data.auditLogs.length + Number(auditLogPageSize)
      try {
        const auditLogs = await getUserAuditLogs(id, newPageSize)
        const data = {
          ...this.state.data,
          auditLogs
        }
        if (this._mounted) {
          this.setState({ data, moreAuditLogs: auditLogs.length === newPageSize })
        }
      } catch (error) {
        if (this._mounted) {
          this.setState({ error })
        }
      }
    }

    render () {
      if (this.state.error) throw this.state.error

      return (
        <WrappedComponent
          data={this.state.data}
          refresh={this.refresh}
          loadMoreAuditLogs={this.state.moreAuditLogs ? this.loadMoreAuditLogs : undefined}
          {...this.props}
        />
      )
    }
  }
  WithUserAndAuditsGet.displayName = `WithUserAndAuditsGet(${getDisplayName(WrappedComponent)})`
  WithUserAndAuditsGet.propTypes = {
    match: PROP_TYPES.ID_IN_PATH.isRequired
  }

  return WithUserAndAuditsGet
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withUserAndAuditsGet
