/**
 * TODO switch to class definition that extends Error once we have
 * the proper support.
 */
function HttpError (status = 500, message, fileName, lineNumber) {
  const instance = new Error(message, fileName, lineNumber)
  instance.status = status
  Object.setPrototypeOf(instance, Object.getPrototypeOf(this))
  // V8 only
  if (Error.captureStackTrace) {
    Error.captureStackTrace(instance, HttpError)
  }
  return instance
}

HttpError.prototype = Object.create(Error.prototype, {
  constructor: {
    value: Error,
    enumerable: false,
    writable: true,
    configurable: true
  }
})

Object.setPrototypeOf(HttpError, Error)

export default HttpError
