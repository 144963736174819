import React from 'react'
import Button from '../../Button'
import getAuthInstance from '../../../../config/auth'
import './ForbiddenError.scss'

const auth = getAuthInstance()
const logout = () => {
  auth.logout()
}

const ForbiddenError = () => (
  <div id="vp-ForbiddenError" className="vp-ForbiddenError">
    <div id="vp-ForbiddenError__container" className="vp-ForbiddenError__container">
      <div id="vp-ForbiddenError__content" className="vp-ForbiddenError__content">
        <div className="vp-ForbiddenError__heading">
          <span>4</span>
          <span>0</span>
          <span>3</span>
        </div>
        <p>
          Access Denied/Forbidden: You do not have the required permissions to access this page.
        </p>
        <Button onClick={logout} btnStyle="hollow">
          Logout
        </Button>
      </div>
    </div>
  </div>
)

export default ForbiddenError
