import React from 'react'
import PropTypes from 'prop-types'
import Card from 'components/common/Card'
import DataRow from 'components/common/DataRow'
import 'components/client/ClientView/ClientDetailsCard/ClientDetailsCard.scss'

const ClientDetailsCard = ({ object, title }) => (
  <div className="vp-ClientDetailsCard">
    <Card title={title}>
      <div className="vp-ClientDetailsCard__details">
        <div className="vp-ClientDetailsCard__details-container">
          <div className="vp-ClientDetailsCard__details-content">
            <DataRow property="id" title="Client ID" value={object.id} />

            <DataRow property="name" title="Name" value={object.name} />

            <DataRow
              property="account_name"
              title="Account Name"
              value={object.account_name}
              accessor={object.account_id}
            />
          </div>

          <div className="vp-ClientDetailsCard__details-content">

            <DataRow property="active" title="Status" value={object.is_active} />

            <DataRow property="created_at" title="Created On" value={object.created_at} />

            <DataRow property="updated_at" title="Last Modified" value={object.updated_at} />
          </div>
        </div>
      </div>
    </Card>
  </div>
)
ClientDetailsCard.propTypes = {
  object: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    account_name: PropTypes.string,
    active: PropTypes.string,
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    account_id: PropTypes.string,
    is_active: PropTypes.bool

  }).isRequired,
  title: PropTypes.string.isRequired
}

export default ClientDetailsCard
