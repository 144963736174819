import React from 'react'
import PropTypes from 'prop-types'

import Button from '../../../common/Button'
import { Form, Input } from '../../../common/Form'
import formHelpers from '../../../../helpers/form-helpers'
import requestHelpers from '../../../../helpers/request-helpers'

class ProfileChangePasswordForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      submitting: false,
      formErrors: {},
      form: {
        'data[current_password]': '',
        'data[password]': '',
        'data[password_confirmation]': ''
      }
    }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange ({ target }) {
    const obj2 = formHelpers.formatInputChange(target)
    const prevState = this.state
    const obj = prevState.form
    obj[target.name] = target.value

    this.setState({ form: obj, ...obj2 })
  }

  async handleSubmit (event) {
    event.preventDefault()
    const { data: body } = formHelpers.formatFormParams(this.state.form)
    this.setState({ submitting: true })

    let response
    try {
      response = await requestHelpers.makeRequest({
        method: 'POST',
        url: '/change-my-password',
        body,
        toJson: false
      })
    } catch (error) {
      this.setState({ error })
      return
    }

    if (response.status === 204) {
      this.setState({
        submitting: false,
        form: {
          'data[current_password]': '',
          'data[password]': '',
          'data[password_confirmation]': ''
        },
        formErrors: {}
      })
      this.props.handleSuccess()
      return
    }

    let json
    try {
      json = await response.json()
    } catch (error) {
      this.setState({ error })
      return
    }

    const formErrors = formHelpers.handleValidationErrors(json)
    this.setState({ formErrors, submitting: false })
  }

  render () {
    const {
      submitting, formErrors, error, form
    } = this.state

    if (error) throw error

    return (
      <Form onSubmit={this.handleSubmit}>
        <Input
          type="password"
          labelText="Current password*"
          id="data_current_password"
          onChange={this.handleInputChange}
          name="data[current_password]"
          formError={formErrors['data.current_password']}
          value={form['data[current_password]']}
          required
        />
        <Input
          type="password"
          labelText="New password*"
          id="data_password"
          onChange={this.handleInputChange}
          name="data[password]"
          formError={formErrors['data.password']}
          value={form['data[password]']}
          required
        />
        <Input
          type="password"
          labelText="Password confirmation*"
          id="data_password_confirmation"
          onChange={this.handleInputChange}
          name="data[password_confirmation]"
          formError={formErrors['data.password_confirmation']}
          value={form['data[password_confirmation]']}
          required
        />
        <Button type="submit" disabled={submitting}>
          Save
        </Button>
      </Form>
    )
  }
}

ProfileChangePasswordForm.propTypes = {
  handleSuccess: PropTypes.func.isRequired
}

export default ProfileChangePasswordForm
