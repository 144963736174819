import React from 'react'

import Page from '../../common/Page'
import PasswordRequirements from '../../common/PasswordRequirements'
import ProfileChangePasswordForm from './ProfileChangePasswordForm'

class ProfileChangePassword extends React.Component {
  constructor (props) {
    super(props)
    this.state = { success: false }
    this.handleSuccess = this.handleSuccess.bind(this)
  }

  handleSuccess () {
    this.setState({ success: true })
  }

  render () {
    const { success } = this.state
    let notice
    if (success) {
      notice = { message: 'Successfully updated password.', type: 'success' }
    }

    return (
      <Page id="vp-ProfileChangePassword" title="Change Password" notice={notice}>
        <PasswordRequirements />
        <ProfileChangePasswordForm handleSuccess={this.handleSuccess} />
      </Page>
    )
  }
}

export default ProfileChangePassword
