import React from 'react'
import PropTypes from 'prop-types'
import onClickOutside from 'react-onclickoutside'

import theme from '../../../helpers/theme-helpers'

import './SidebarMenu.scss'

const style = theme.components.SidebarMenu

class SidebarMenu extends React.Component {
  handleClickOutside (e) {
    if (!this.props.collapsed) {
      this.props.toggleSidebar(e)
    }
  }

  render () {
    const { children } = this.props
    const collapsedClass = this.props.collapsed
      ? 'vp-SidebarMenu--collapsed'
      : 'vp-SidebarMenu--expanded'
    return (
      <aside id="vp-SidebarMenu" className={`vp-SidebarMenu ${collapsedClass}`} style={style}>
        <nav>{children}</nav>
      </aside>
    )
  }
}

SidebarMenu.propTypes = {
  collapsed: PropTypes.bool,
  children: PropTypes.element.isRequired,
  toggleSidebar: PropTypes.func.isRequired
}

SidebarMenu.defaultProps = {
  collapsed: true
}

export default onClickOutside(SidebarMenu)
