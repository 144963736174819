import React from 'react'
import PROP_TYPES from '../../../prop-types'
import {
  getSubscription,
  isParentSubscription,
  getChildSubscriptions
} from './subscription-helpers'
import { getAccount } from '../../account/shared/account-helpers'

const subscriptionsPageSize = 100

function withSubscriptionAndChildrenGet (
  WrappedComponent
) {
  class WithSubscriptionAndChildrenGet extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        data: {},
        error: undefined,
        moreSubscriptions: false
      }

      this.loadMoreSubscriptions = this.loadMoreSubscriptions.bind(this)
      this.refresh = this.refresh.bind(this)
    }

    async componentDidMount () {
      this._mounted = true
      await this.getAllData()
    }

    async componentDidUpdate (prevProps) {
      if (prevProps.match.params.id !== this.props.match.params.id) {
        await this.getAllData()
      }
    }

    componentWillUnmount () {
      // Note: necessary so setState() isn't called in
      // componentDidMount() fetch promise chain if
      // component becomes unmounted
      this._mounted = false
    }

    async getAllData () {
      const { id } = this.props.match.params

      try {
        const subscription = await getSubscription(id)
        const accountId = subscription.account_id

        const { account } = await getAccount(accountId, false, false)

        let childSubscriptions
        let moreSubscriptions
        if (isParentSubscription(subscription)) {
          const childSubscriptionsData = await
          getChildSubscriptions(subscription, subscriptionsPageSize)

          childSubscriptions = childSubscriptionsData.data
          moreSubscriptions = childSubscriptionsData.next_page && childSubscriptionsData.page_size +
          Number(subscriptionsPageSize) <= 1000
        }

        const data = {
          subscription,
          account_name: account.sf_account.name,
          childSubscriptions
        }

        if (this._mounted) {
          this.setState({
            data,
            moreSubscriptions
          })
        }
      } catch (error) {
        this._mounted && this.setState({ error })
      }
    }

    loadMoreSubscriptions = async () => {
      const { subscription } = this.state.data

      if (!isParentSubscription(subscription)) {
        return
      }

      const newPageSize = this.state.data.childSubscriptions.length +
      Number(subscriptionsPageSize)

      if (newPageSize < 1000) {
        try {
          const newChildSubscriptions = await getChildSubscriptions(subscription, newPageSize)

          const data = {
            ...this.state.data,
            childSubscriptions: newChildSubscriptions.data
          }
          if (this._mounted) {
            this.setState({ data, moreSubscriptions: !!newChildSubscriptions.next_page })
          }
        } catch (error) {
          if (this._mounted) {
            this.setState({ error })
          }
        }
      }
    }

    async refresh () {
      await this.getAllData()
    }

    render () {
      if (this.state.error) throw this.state.error
      return (
        <WrappedComponent
          data={this.state.data}
          refresh={this.refresh}
          loadMoreSubscriptions={
            this.state.moreSubscriptions ? this.loadMoreSubscriptions : undefined
          }
          {...this.props}
        />
      )
    }
  }

  WithSubscriptionAndChildrenGet.displayName =
    `WithSubscriptionAndChildrenGet(${getDisplayName(WrappedComponent)})`
  WithSubscriptionAndChildrenGet.propTypes = {
    match: PROP_TYPES.ID_IN_PATH.isRequired
  }

  return WithSubscriptionAndChildrenGet
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withSubscriptionAndChildrenGet
