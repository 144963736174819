import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { Icon } from '@vivialplatform/ui-shared-lib'

import Button from '../Button'
import LinkButton from '../LinkButton'
import ExternalLink from '../ExternalLink'
import Notice from '../Notice'
import theme from '../../../helpers/theme-helpers'

import './Page.scss'

const hasLinks = (headerLinks, externalLinks) => headerLinks.length > 0 || externalLinks.length > 0

const Page = ({
  title, notice, children, headerLinks, externalLinks, history, showBack
}) => (
  <div className="vp-Page">
    <Helmet>
      <title>
        {title}
        {' '}
| Vivial Admin App
      </title>
    </Helmet>
    <div className="vp-Page__container">
      <div className="vp-Page__content">
        {notice && <Notice {...notice} />}
        <section className="vp-Page__title">
          <header>
            <h1>{title}</h1>
            {hasLinks(headerLinks, externalLinks) && (
              <div className="vp-Page__title-link-container">
                {headerLinks.map(headerLink => (
                  <span key={headerLink.id}>
                    <LinkButton
                      id={headerLink.id}
                      to={headerLink.to}
                      href={headerLink.href}
                      btnStyle="hollow"
                    >
                      {headerLink.icon && (
                        <span className="vp-Page__icon">
                          <Icon icon={['fal', `${headerLink.icon}`]} />
                        </span>
                      )}
                      <span>{headerLink.text}</span>
                    </LinkButton>
                  </span>
                ))}
                {externalLinks.map(link => (
                  <ExternalLink
                    id={link.id}
                    key={link.id}
                    href={link.href}
                  >
                    <Button
                      title={link.text}
                      btnStyle="hollow"
                    >
                      {link.icon && (
                        <span className="vp-Page__icon">
                          <Icon icon={['fal', `${link.icon}`]} />
                        </span>
                      )}
                      <span>
                        {link.text}
                      </span>
                    </Button>
                  </ExternalLink>
                ))}
              </div>
            )}
          </header>
          {showBack && (
            <div className="vp-Page__back">
              <Button onClick={history.goBack} color={theme.colors.secondary} btnStyle="clear">
                <span className="vp-Page__icon">
                  <Icon icon={['fal', 'arrow-left']} />
                </span>
                <span>Back</span>
              </Button>
            </div>
          )}
        </section>
        <section className="vp-Page__body">{children}</section>
      </div>
    </div>
  </div>
)

Page.defaultProps = {
  notice: undefined,
  headerLinks: [],
  externalLinks: [],
  showBack: false
}

Page.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.element]).isRequired,
  headerLinks: PropTypes.arrayOf(PropTypes.shape({
    to: PropTypes.string,
    href: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string
  })),
  externalLinks: PropTypes.arrayOf(PropTypes.shape({
    href: PropTypes.string,
    text: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string
  })),
  notice: PropTypes.shape({
    message: PropTypes.string,
    type: PropTypes.oneOf(['error', 'info', 'success'])
  }),
  history: PropTypes.shape({ goBack: PropTypes.func }).isRequired,
  showBack: PropTypes.bool
}

export default withRouter(Page)
