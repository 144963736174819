import React from 'react'
import PropTypes from 'prop-types'

function hoverable (WrappedComponent) {
  class Hoverable extends React.Component {
    constructor (props) {
      super(props)
      this.state = {
        hovered: false,
        focused: false
      }
      this.getStyle = this.getStyle.bind(this)
    }

    getStyle (style) {
      return this.state.hovered || this.state.focused ? style.hover : style.base
    }

    render () {
      const { style, children, ...rest } = this.props
      return (
        <WrappedComponent
          {...rest}
          onMouseOver={() => {
            this.setState({ hovered: true })
          }}
          onMouseOut={() => {
            this.setState({ hovered: false })
          }}
          onFocus={() => {
            this.setState({ focused: true })
          }}
          onBlur={() => {
            this.setState({ focused: false })
          }}
          style={this.getStyle(style)}
        >
          {children}
        </WrappedComponent>
      )
    }
  }
  Hoverable.defaultProps = { children: undefined }
  Hoverable.displayName = `Hoverable(${WrappedComponent.displayName || WrappedComponent.name})`
  Hoverable.WrappedComponent = WrappedComponent
  Hoverable.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
    style: PropTypes.shape({
      base: PropTypes.object,
      hover: PropTypes.object
    }).isRequired
  }

  return Hoverable
}

export default hoverable
