/* eslint-disable jsx-a11y/label-has-for */
import React from 'react'
import propTypes from 'prop-types'
import { Icon } from '@vivialplatform/ui-shared-lib'

import './Label.scss'

const CLASS_NAME = {
  default: 'vp-Form__label',
  error: 'vp-Form__label is-invalid-label'
}

const Label = ({
  htmlFor, text, formError, children, tooltip, ...rest
}) => (
  <label
    {...rest}
    htmlFor={htmlFor}
    className={formError ? CLASS_NAME.error : `${CLASS_NAME.default} ${htmlFor}`}
  >
    {text}
    {tooltip && (
      <span title={tooltip} className="vp-Label__icon">
        <Icon icon={['fal', 'info-circle']} />
      </span>
    )}
    {children}
  </label>
)

Label.defaultProps = { formError: undefined, tooltip: undefined }
Label.propTypes = {
  htmlFor: propTypes.string.isRequired,
  text: propTypes.string.isRequired,
  formError: propTypes.string,
  tooltip: propTypes.string,
  children: propTypes.oneOfType([propTypes.element, propTypes.node]).isRequired
}

export default Label
