import React from 'react'
import { Redirect } from 'react-router'

import PROP_TYPES from '../../../../prop-types'
import { Form, Input } from '../../../common/Form'
import Button from '../../../common/Button'
import formHelpers from '../../../../helpers/form-helpers'
import requestHelpers from '../../../../helpers/request-helpers'
import Loading from '../../../common/Loading'
import Select from '../../../common/Form/Select'
import HttpError from '../../../../helpers/errors'
import permissionHelper from '../../../../helpers/permissions-helpers'
import affiliatesHelper from '../../../../helpers/affiliates-request-helper'
import { CLIENT_AFFILIATE_TOOLTIP } from '../../UserCreate/UserCreateForm'

class UserEditForm extends React.Component {
  constructor (props) {
    super(props)

    let error
    if (!permissionHelper.hasPerm({ policies: ['user:update'] })) {
      error = new HttpError(404)
    }

    this.state = {
      redirectToUser: false,
      submitting: false,
      submitDisabled: true,
      loading: props.user.type === 'AFFILIATE',
      affiliates: [],
      affiliateId: props.user.affiliate_id || '',
      requiredAffiliate: props.user.type === 'AFFILIATE',
      formErrors: {},
      error
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.getInitialFormData = this.getInitialFormData.bind(this)
    this.getDefaultSelectedAffiliate = this.getDefaultSelectedAffiliate.bind(this)
  }

  componentDidMount () {
    this._mounted = true
    const { user: { type } } = this.props
    if (type !== 'INTERNAL') {
      affiliatesHelper.getAffiliates()
        .then((affiliates) => {
          affiliates.unshift({
            value: '',
            display: '-- None --',
            disabled: type === 'AFFILIATE'
          })

          this._mounted &&
            this.setState({
              affiliates
            })
        })
        .then(() => this._mounted && this.setState({ loading: false }))
    }

    this.form = this.getInitialFormData()
  }

  componentWillUnmount () {
    this._mounted = false
  }

  getInitialFormData () {
    const { user } = this.props

    const form = {
      'user[version]': user.version,
      'user[type]': user.type
    }

    if (user.type === 'AFFILIATE') {
      form['user[affiliate_id]'] = user.affiliate_id
    }

    return form
  }

  getDefaultSelectedAffiliate () {
    const { user } = this.props
    const { affiliates } = this.state

    const found = affiliates.find(affiliate => affiliate.value === user.affiliate_id)

    return found && found.value
  }

  handleInputChange ({ target }) {
    const obj = formHelpers.formatInputChange(target)
    this.form = { ...this.form, ...obj }
    if (!this.state.submitting && this.state.submitDisabled) {
      this.setState({ submitDisabled: false })
    }

    if (target.name === 'user[affiliate_id]') {
      this.setState({ affiliateId: target.value })
    }
  }

  async handleSubmit (event) {
    event.preventDefault()
    const { user } = formHelpers.formatFormParams(this.form)
    this.setState({ submitting: true, submitDisabled: true })
    let response
    try {
      response = await requestHelpers.makeRequest({
        method: 'PATCH',
        url: `/users/${this.props.user.id}`,
        body: user,
        toJson: false
      })
    } catch (error) {
      this.setState({ error })
      return
    }

    if (response.status === 204) {
      this.setState({ redirectToUser: true })
      return
    }

    let json
    try {
      json = await response.json()
    } catch (error) {
      this.setState({ error })
      return
    }
    const formErrors = formHelpers.handleValidationErrors(json)
    this.setState({ formErrors, submitting: false })
  }

  render () {
    const {
      error,
      loading,
      redirectToUser,
      submitting,
      formErrors,
      affiliates,
      submitDisabled,
      requiredAffiliate,
      affiliateId
    } = this.state
    const { user } = this.props

    if (error) throw error
    if (loading) return <Loading />

    if (redirectToUser) {
      const notice = { message: 'Successfully updated user.', type: 'success' }
      const location = {
        pathname: `/users/${user.id}`,
        state: { notice }
      }
      return <Redirect push to={location} />
    }

    return (
      <Form onSubmit={this.handleSubmit}>
        {submitting && <Loading />}
        <Input
          id="user_first_name"
          type="text"
          onChange={this.handleInputChange}
          name="user[first_name]"
          defaultValue={user.first_name}
          labelText="First Name"
          formError={formErrors['user.first_name']}
          required
        />
        <Input
          id="user_last_name"
          type="text"
          onChange={this.handleInputChange}
          name="user[last_name]"
          defaultValue={user.last_name}
          labelText="Last Name"
          formError={formErrors['user.last_name']}
          required
        />
        <Input
          id="user_email"
          type="text"
          onChange={this.handleInputChange}
          name="user[email]"
          defaultValue={user.email}
          labelText="Email"
          formError={formErrors['user.email']}
          required
        />

        {user.type !== 'INTERNAL' && affiliates.length > 0 && (
          <Select
            id="user_affiliate_id"
            name="user[affiliate_id]"
            labelText={requiredAffiliate ? 'Partner*' : 'Preferred Partner'}
            options={affiliates}
            value={affiliateId}
            tooltip={!requiredAffiliate ? CLIENT_AFFILIATE_TOOLTIP : null}
            onChange={this.handleInputChange}
            formError={formErrors['user.affiliate_id']}
            required={requiredAffiliate}
          />
        )}

        <Button type="submit" disabled={submitDisabled}>
          Save
        </Button>
      </Form>
    )
  }
}

UserEditForm.propTypes = { user: PROP_TYPES.USER.isRequired }

export default UserEditForm
