import React from 'react'
import { stringify } from 'query-string'
import { Icon } from '@vivialplatform/ui-shared-lib'
import PropTypes from 'prop-types'
import permissionHelper from 'helpers/permissions-helpers'
import PROP_TYPES from '../../../../prop-types'
import Table from '../../../common/Table'
import Card from '../../../common/Card'
import Link from '../../../common/Link'
import { defaultColors } from '../../../../helpers/theme-helpers'

import './SubscriptionCard.scss'

const ICON_COLOR = {
  color: defaultColors.darkGray
}

const separater = '+'

const CREATE_SUBSCRIPTIONS_PERMS = {
  policies: ['subscription:create-manual']
}

function getProductName (subscription, shouldIndent = true) {
  if (shouldIndent && subscription.parent_oli_id) {
    return `${separater} ${subscription.product_name}`
  }

  return subscription.product_name
}

function _buildCreateSubscriptionLink (params) {
  const state = { ...params }
  Object.keys(params).forEach(key => !params[key] && delete state[key])
  const queryParams = stringify(state)
  return {
    to: {
      pathname: createSubscriptionPath,
      search: `?${queryParams}`,
      state
    },
    href: createSubscriptionPath,
    text: 'Create',
    icon: 'plus',
    id: 'vp-AddSubscription'
  }
}

function getSubscriptionsHeaders (containsChildSubscriptionsOnly) {
  return [
    {
      name: 'OLI Number',
      settings: {
        type: 'callback',
        callback (subscription) {
          const linkPath = `/subscriptions/${subscription.id}`
          return (
            <>
              <Link to={linkPath} href={linkPath}>{subscription.oli_number}</Link>
              {subscription.is_ignored && (
                <span
                  title="This subscription is ignored."
                  className="vp-Subscriptions__icon"
                  style={ICON_COLOR}
                >
                  <Icon icon={['fal', 'ban']} />
                </span>
              )}
            </>
          )
        }
      }
    },
    {
      name: 'Product Name',
      settings: {
        type: 'callback',
        callback (subscription) {
          let productName = getProductName(subscription, !containsChildSubscriptionsOnly)
          if (subscription.ended_at !== null) {
            productName += ' - [Canceled]'
          }
          return (
            <>
              <span>{productName}</span>
              {subscription.is_manual && (
                <span
                  className="vp-SubscriptionCard__icon"
                  title="This subscription is manual."
                >
                  <Icon icon={['fal', 'wrench']} />
                </span>
              )}
            </>
          )
        }
      }
    },
    { name: 'Product UDAC', accessor: 'product_udac' },
    { name: 'Vendor ID', accessor: 'vendor_id' },
    {
      name: 'Cancel Date',
      accessor: 'ended_at',
      settings: {
        type: 'date'
      }
    },
    {
      name: 'Add-On',
      accessor: 'is_addon',
      settings: {
        type: 'yes/no'
      }
    }
  ]
}

const createSubscriptionPath = `/subscriptions/create`

function SubscriptionCard ({
  subscriptions,
  loadMoreSubscriptions,
  title,
  containsChildSubscriptionsOnly,
  createSubscriptionLinkParams,
  from,
  accountName
}) {
  let createSubscriptionLink

  if (permissionHelper.hasPerm(CREATE_SUBSCRIPTIONS_PERMS)) {
    createSubscriptionLink = _buildCreateSubscriptionLink(createSubscriptionLinkParams)
    createSubscriptionLink.to.state.from = from
    createSubscriptionLink.to.state.accountName = accountName
  }

  return (
    <Card title={title} titleLink={createSubscriptionLink}>
      {subscriptions && subscriptions.length > 0 ? (
        <Table
          rowKey="id"
          headers={getSubscriptionsHeaders(containsChildSubscriptionsOnly)}
          rows={subscriptions}
          loadMoreRows={loadMoreSubscriptions}
        />
      ) : (
        <div className="vp-AccountView__details-container">
          <div className="vp-AccountView__details-content">
            <p>No subscriptions</p>
          </div>
        </div>
      )}
    </Card>
  )
}

SubscriptionCard.propTypes = {
  subscriptions: PropTypes.arrayOf(PROP_TYPES.SUBSCRIPTION),
  loadMoreSubscriptions: PropTypes.func,
  title: PropTypes.string,
  containsChildSubscriptionsOnly: PropTypes.bool,
  createSubscriptionLinkParams: PropTypes.shape({
    account_id: PropTypes.string,
    client_id: PropTypes.string,
    parent_oli_id: PropTypes.string
  }),
  from: PropTypes.string,
  accountName: PropTypes.string
}

SubscriptionCard.defaultProps = {
  subscriptions: undefined,
  loadMoreSubscriptions: undefined,
  title: 'Subscriptions',
  containsChildSubscriptionsOnly: false,
  createSubscriptionLinkParams: {},
  from: undefined,
  accountName: undefined
}

export default SubscriptionCard
export {
  SubscriptionCard,
  getProductName
}
