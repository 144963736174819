import React from 'react'
import PropTypes from 'prop-types'

import { defaultColors, lighten, pickMostReadable } from '../../../helpers/theme-helpers'

import './Notice.scss'

const infoBgColor = lighten(defaultColors.mediumGray, 13)
const successBgColor = lighten(defaultColors.success, 40)
const errorBgColor = lighten(defaultColors.alert, 40)

const styles = {
  info: {
    color: pickMostReadable(infoBgColor),
    backgroundColor: infoBgColor
  },
  success: {
    color: pickMostReadable(infoBgColor),
    backgroundColor: successBgColor
  },
  error: {
    color: pickMostReadable(errorBgColor),
    backgroundColor: errorBgColor
  }
}

const Notice = ({ type, message }) => (
  <div className="vp-Notice">
    <div className="vp-Notice__body" style={styles[type]}>
      <p>{message}</p>
    </div>
  </div>
)

Notice.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['success', 'error', 'info'])
}

Notice.defaultProps = {
  type: 'info'
}

export default Notice
