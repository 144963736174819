import React, { useState, useEffect } from 'react'
import Loading from 'components/common/Loading'
import Page from 'components/common/Page'
import Card from 'components/common/Card'
import Table from 'components/common/Table'
import DataRow from 'components/common/DataRow'
import { formatPermissions } from 'helpers/utils'
import permissionHelper from '../../../helpers/permissions-helpers'
import PROP_TYPES from '../../../prop-types'
import userAccountPermissionsGet from '../shared/user-account-permissions-get'

import './UserAcccountPermissions.scss'

export const DEFAULT_ACCESS_MESSAGE = `This user doesn't have access to any clients or locations.`
export const NO_PERMISSIONS = 'No Permissions'

const PERMISSIONS_HEADERS = [
  { name: 'Client', accessor: 'client' },
  { name: 'Roles', accessor: 'roles' }
]

/* Is considered default access when a user has only one permission
 * entry with client id equals minus-one, and location id equals
 * minus-one with no roles.
 * [{ client_id: '-1', location_id: '-1' }]
 */
export const userHaveDefaultAccess = (permissions) => {
  if (permissions) {
    const [first, ...rest] = permissions
    return first && first.client_id === '-1' &&
    first.location_id === '-1' && rest.length === 0 &&
    (!first.roles || first.roles.length === 0)
  }
  return false
}

export const UserAccountPermissions = ({ account, user, permissions }) => {
  const [formattedPermissions, setFormattedPermissions] = useState(formatPermissions(permissions))

  useEffect(() => {
    setFormattedPermissions(formatPermissions(permissions))
  }, [permissions])

  if (!account || !user) {
    return <Loading />
  }

  const {
    sf_account: { name: accountName, account_number: accountNumber }
  } = account

  const { first_name: firstName, last_name: lastName } = user
  const userName = `${firstName} ${lastName}`.trim()
  const fullAccess = userHaveDefaultAccess(permissions)
  const warningMessage = fullAccess ? DEFAULT_ACCESS_MESSAGE : NO_PERMISSIONS
  const shouldRenderTable = permissions && permissions.length > 0 && !fullAccess

  const permissionsContent = shouldRenderTable ? (
    <Table rowKey="id" headers={PERMISSIONS_HEADERS} rows={formattedPermissions} />
  ) : (
    <div className="vp-Permissions__details-container">
      <div className="vp-Permissions__details-content">
        <p id="permissions-message">{warningMessage}</p>
      </div>
    </div>
  )

  let editTitleLink
  if (permissionHelper.canUpdateUserAccountPermissions(user)) {
    editTitleLink = {
      to: `/users/${user.id}/account-permissions/${account.id}/edit`,
      text: 'Edit',
      icon: 'edit'
    }
  }

  return (
    <Page title={`${userName}'s Permissions`} showBack>
      <div className="vp-PermissionsView">
        <div id="vp-Permissions__accountSummary" className="vp-Permissions__details">
          <Card title="Account Summary">
            <div className="vp-Permissions__details-container">
              <div className="vp-Permissions__details-content">
                <DataRow property="name" title="Name" value={accountName} />
              </div>
              <div className="vp-Permissions__details-content">
                <DataRow property="number" title="Number" value={accountNumber} />
              </div>
            </div>
          </Card>
        </div>
        <div id="vp-Permissions__PermissionsTable" className="vp-Permissions__details">
          {permissions && (
            <Card title="Permissions" titleLink={editTitleLink}>
              { permissionsContent }
            </Card>
          )}
        </div>
      </div>
    </Page>
  )
}

const propTypes = {
  account: PROP_TYPES.ACCOUNT,
  permissions: PROP_TYPES.ACCOUNT_PERMISSIONS,
  user: PROP_TYPES.USER
}

UserAccountPermissions.propTypes = propTypes
UserAccountPermissions.defaultProps = {
  account: undefined,
  user: undefined,
  permissions: undefined
}

export default userAccountPermissionsGet(UserAccountPermissions)
