import React from 'react'
import PropTypes from 'prop-types'
import Card from 'components/common/Card'
import DataRow from 'components/common/DataRow'
import 'components/location/LocationView/LocationDetailsCard/LocationDetailsCard.scss'

const LocationDetailsCard = ({ object, title }) => (
  <div id="vp-LocationDetailsCard">
    <Card title={title}>
      <div className="vp-LocationDetailsCard__details">
        <div className="vp-LocationDetailsCard__details-container">
          <div className="vp-LocationDetailsCard__details-content">
            <DataRow property="id" title="ID" value={object.id} />

            <DataRow property="name" title="Name" value={object.name} />

            <DataRow property="client_id" title="Parent Client ID" value={object.client_id} />
          </div>

          <div className="vp-LocationDetailsCard__details-content">
            <DataRow property="deleted_at" title="Status" value={object.deleted_at} />

            <DataRow property="is_primary" title="Primary Location" value={object.is_primary} />

            <DataRow property="created_at" title="Created On" value={object.created_at} />
          </div>
        </div>
      </div>
    </Card>
  </div>
)

LocationDetailsCard.defaultProps = {
  object: {
    deleted_at: undefined
  }
}
LocationDetailsCard.propTypes = {
  object: PropTypes.shape({
    created_at: PropTypes.string.isRequired,
    is_primary: PropTypes.bool.isRequired,
    deleted_at: PropTypes.string,
    client_id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired
  }),
  title: PropTypes.string.isRequired
}

export default LocationDetailsCard
