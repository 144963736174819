import React from 'react'
import PROP_TYPES from '../../../prop-types'
import productHelpers from './product-helpers'

const { getProduct, getProductAppConfig } = productHelpers

const productAppConfigPageSize = 50

function withProductAndAppConfigGet (WrappedComponent) {
  class WithProductAndAppConfigGet extends React.Component {
    constructor (props) {
      super(props)
      this.state = { data: {}, moreAppConfigs: true, error: undefined }
      this.getAllData = this.getAllData.bind(this)
      this.loadMoreAppConfigs = this.loadMoreAppConfigs.bind(this)
    }

    async componentDidMount () {
      this._mounted = true
      await this.getAllData()
    }

    componentWillUnmount () {
      // Note: necessary so setState() isn't called in
      // componentDidMount() fetch promise chain if
      // component becomes unmounted
      this._mounted = false
    }

    async getAllData () {
      const { id } = this.props.match.params
      const requests = [
        getProduct(id),
        getProductAppConfig(id, productAppConfigPageSize)
      ]
      try {
        const [productJson, appConfigJson] = await Promise.all(requests)
        const data = {
          product: productJson,
          appConfig: appConfigJson.data
        }
        if (this._mounted) {
          this.setState({ data })
          if (Number(productAppConfigPageSize) !== appConfigJson.data.length) {
            this.setState({ moreAppConfigs: false })
          }
        }
      } catch (error) {
        if (this._mounted) {
          this.setState({ error })
        }
      }
    }

    async loadMoreAppConfigs () {
      const { id } = this.props.match.params
      const newPageSize = this.state.data.appConfig.length + Number(productAppConfigPageSize)

      try {
        const appConfig = await getProductAppConfig(id, newPageSize)
        const data = {
          ...this.state.data,
          appConfig: appConfig.data
        }
        if (this._mounted) {
          this.setState({ data, moreAppConfigs: appConfig.data.length === newPageSize })
        }
      } catch (error) {
        if (this._mounted) {
          this.setState({ error })
        }
      }
    }

    render () {
      if (this.state.error) throw this.state.error
      return (
        <WrappedComponent
          data={this.state.data}
          {...this.props}
          loadMoreAppConfigs={this.state.moreAppConfigs ? this.loadMoreAppConfigs : undefined}
        />
      )
    }
  }

  WithProductAndAppConfigGet
    .displayName = `WithProductAndAppConfigGet(${getDisplayName(WrappedComponent)})`
  WithProductAndAppConfigGet.propTypes = {
    match: PROP_TYPES.ID_IN_PATH.isRequired
  }

  return WithProductAndAppConfigGet
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default withProductAndAppConfigGet
